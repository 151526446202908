import './user_module.scss'
import PageHeader from "../../components/PageHeader";
import PageFooter from "../../components/PageFooter";
import {useTranslation} from "react-i18next";
import {useEffect, useState} from "react";
import {Link, Outlet, useLocation} from "react-router-dom";
import {setUserInfo} from "../../features/user/userSlice";
import { useDispatch } from 'react-redux'
import userApi from '@/api/user'
import message from "../../utils/message";
import {playerContract, web3} from "../../utils/web3Utils";

function User() {
    const dispatch = useDispatch()
    const {t} = useTranslation()
    const location = useLocation().pathname
    const [audit,setAudit] = useState(false)

    useEffect(()=>{
        if(window.ethereum === void 0) {
            message.fail(t("installMetaMask"))
        }else {
            web3.eth.getAccounts().then((res)=>{
                if (!res[0]) {
                    res = window.ethereum.request({method: 'eth_requestAccounts'})
                }
                getUserInfo()
                playerContract.methods.getUserInfo(res[0]).call().then((res)=>{
                    setAudit(res.isAuditor)
                })
            })
        }
    },[])

    function getUserInfo() {
        userApi.queryUserInfo().then(res=>{
            dispatch(setUserInfo(res.data))
        })
    }
    return (
        <div className={'main_back'}>
            <PageHeader other={true}></PageHeader>
            <div className={'user_main_block'}>
                <div className={'user_main_block_left'}>
                    <Link to={'/user/profile'}>
                        <div className={`user_main_block_left_view ${location === '/user/profile' ? 'user_main_block_btn' : ''}`}>
                            <div className={'user_main_block_btn_view'}>{t('Profile')}</div>
                        </div>
                    </Link>
                    <Link to={'/user/account'}>
                        <div className={`user_main_block_left_view ${location === '/user/account'? 'user_main_block_btn' : ''}`}>
                            <div className={'user_main_block_btn_view'}>{t('AccountManagement')}</div>
                        </div>
                    </Link>
                    <Link to={'/user/wiki'}>
                        <div
                            className={`user_main_block_left_view ${location === '/user/wiki' ? 'user_main_block_btn' : ''}`}>
                            <div className={'user_main_block_btn_view'}>WIKI</div>
                        </div>
                    </Link>
                    <Link to={'/user/collection'}>
                        <div
                            className={`user_main_block_left_view ${location === '/user/collection' ? 'user_main_block_btn' : ''}`}>
                            <div className={'user_main_block_btn_view'}>{t('MyCollection')}</div>
                        </div>
                    </Link>
                    <Link to={'/user/like'}>
                        <div
                            className={`user_main_block_left_view ${location === '/user/like' ? 'user_main_block_btn' : ''}`}>
                            <div className={'user_main_block_btn_view'}>{t('MyLikes')}</div>
                        </div>
                    </Link>
                    <Link to={'/user/draft'}>
                        <div
                            className={`user_main_block_left_view ${location === '/user/draft' ? 'user_main_block_btn' : ''}`}>
                            <div className={'user_main_block_btn_view'}>{t('drafts')}</div>
                        </div>
                    </Link>
                    <Link to={'/user/submissions'}>
                        <div
                            className={`user_main_block_left_view ${location === '/user/submissions' ? 'user_main_block_btn' : ''}`}>
                            <div className={'user_main_block_btn_view'}>{t('MySubmissions')}</div>
                        </div>
                    </Link>
                    <Link to={'/user/friend'}>
                        <div
                            className={`user_main_block_left_view ${location === '/user/friend' ? 'user_main_block_btn' : ''}`}>
                            <div className={'user_main_block_btn_view'}>{t('FriendInvitation')}</div>
                        </div>
                    </Link>
                    <Link to={'/user/password'}>
                        <div
                            className={`user_main_block_left_view ${location === '/user/password' ? 'user_main_block_btn' : ''}`}>
                            <div className={'user_main_block_btn_view'}>{t('PasswordManagement')}</div>
                        </div>
                    </Link>
                    {
                        audit && (
                            <Link to={'/user/review'}>
                                <div
                                    className={`user_main_block_left_view ${location === '/user/review' ? 'user_main_block_btn' : ''}`}>
                                    <div className={'user_main_block_btn_view'}>{t('CollectionReview')}</div>
                                </div>
                            </Link>
                        )
                    }
                </div>
                <div className={'user_main_block_right'}>
                    <Outlet/>
                </div>
            </div>
            <PageFooter></PageFooter>
        </div>
    )
}

export default User;
