import {useTranslation} from "react-i18next";
import {Link} from "react-router-dom";
import Pagination from "../../../components/Pagination";
import {useEffect, useState} from "react";
import useSyncCallback from "../../../utils/useSyncCallback";
import moreApi from "../../../api/more";
import {useSelector} from "react-redux";
import dayjs from "dayjs";

function Hackathon() {
    const baseUrl = process.env.REACT_APP_BASE_URL
    const [tableData,setTableData] = useState({
        total: 0,
        list: []
    })
    const [requestType,setRequestType] = useState()
    const [requestSort,setRequestSort] = useState(0)
    const [pageNum, setPageNum] = useState(1)
    const {t} = useTranslation()
    const lang = useSelector(state => state.language.value)
    function changePage(e) {
        setPageNum(e)
        getList()
    }

    const getList = useSyncCallback(()=>{
        moreApi.queryHackList({
            pageNum,
            pageSize: 20,
            lang: lang.value,
            sort: requestSort === void 0?0:requestSort,
            type: requestType === void 0?0:requestType,
        }).then(res=>{
            console.log(res)
            setTableData(()=>{
                return {
                    total: res.total,
                    list: res.rows
                }
            })
        })
    })

    const checkHottest = useSyncCallback((type)=>{
        setRequestSort(()=>void 0)
        setPageNum(1)
        setRequestType(type || 0)
        if(requestSort !== void 0) {
            if (requestSort ===  1){
                setRequestSort(0)
            }else {
                setRequestSort(1)
            }
        }else {
            setRequestSort(0)
        }
        getList()
    })

    const checkRecently = useSyncCallback((type)=>{
        setRequestSort(()=>void 0)
        setPageNum(1)
        setRequestType(type || 1)
        if(requestSort !== void 0) {
            if (requestSort ===  1){
                setRequestSort(0)
            }else {
                setRequestSort(1)
            }
        }else {
            setRequestSort(0)
        }
        getList()
    })

    useEffect(() => {
        getList()
    }, []);
    return (
        <div className={'more_child_block'}>
            <div>
                <div className={'more_child_sort_btn more_child_sort_hack'}>
                    <div className={'project_list_left_menu_top_grid_item'} onClick={() => checkHottest(0)}>
                        {requestType !== 0 && (
                            <img src={require('@/assets/image/project/sort_icon.png')} alt='sort'/>)}
                        {(requestType === 0 && requestSort === 1) && (
                            <img src={require('@/assets/image/project/sort_icon_up.png')} alt='sort'/>)}
                        {(requestType === 0 && requestSort === 0) && (
                            <img src={require('@/assets/image/project/sort_icon_down.png')} alt='sort'/>)}
                        {t('Hottest')}
                    </div>
                    <div className={'project_list_left_menu_top_grid_item'}
                         onClick={() => checkRecently(1)}>
                        {requestType !== 1 && (
                            <img src={require('@/assets/image/project/sort_icon.png')} alt='sort'/>)}
                        {(requestType === 1 && requestSort === 1) && (
                            <img src={require('@/assets/image/project/sort_icon_up.png')} alt='sort'/>)}
                        {(requestType === 1 && requestSort === 0) && (
                            <img src={require('@/assets/image/project/sort_icon_down.png')} alt='sort'/>)}
                        {t('Recently')}
                    </div>
                </div>
            </div>
            <div className={'more_child_right_table_block'}>
                {
                    tableData.list.map((item, index) => {
                        return (
                            <Link to={`/more/hackathon/detail/${item.id}`}>
                                <div className={'project_table_item'}>
                                    <div className={'project_table_item_top'}>
                                        <img src={baseUrl + item.logoUrl} alt='logo'/>
                                        <div className={'project_table_item_text'}>
                                            <div className={'project_table_item_title'}>{item.name}</div>
                                            <div className={'project_table_item_content'}>DATE:{item.time?dayjs(item.time).format('YYYY-MM-DD'):'--'}</div>
                                        </div>
                                    </div>
                                    <div className={'more_text_item_bottom'} style={{height: 40}}>{item.content}</div>
                                </div>
                            </Link>
                        )
                    })
                }
            </div>
            <div className={'more_page_block'}>
                <Pagination total={tableData.total} size={20} page={pageNum} onChange={changePage}></Pagination>
            </div>
        </div>
    )
}

export default Hackathon;
