export function formatWithSeparator(number) {
    let num = 0
    for (const item of number.split(',')) {
        num+=item
    }
    const formatter = new Intl.NumberFormat('en-US');
    const result = formatter.format(num)
    if (result === 'NaN') {
        return number
    }
    return result;
}

export function formatMillion(value) {
    const valueArr = value.split(',')
    let number = ''
    for (const item of valueArr) {
        number += item
    }
    const typeNumber = Number(number)
    if (typeNumber >= 1000000000) {
        let formatNumber = (typeNumber/1000000000).toFixed(0)
        return formatNumber+'B'
    }else if (typeNumber >= 1000000){
        let formatNumber = (typeNumber/1000000).toFixed(0)
        return formatNumber+'M'
    }else if (typeNumber >= 1000){
        let formatNumber = (typeNumber/1000).toFixed(0)
        return formatNumber+'K'
    }else {
        return typeNumber
    }
}
