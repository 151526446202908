import './login_module.scss'
import {useTranslation} from 'react-i18next';
import {useEffect, useState} from "react";
import close from '@/assets/image/login/close.png'
import closeDark from '@/assets/image/login/close_dark.png'
import {useSelector} from "react-redux";
import message from "../../utils/message";
import Web3 from 'web3'
import loginApi from '@/api/login'
import { useDispatch } from 'react-redux'
import {Login} from '@/features/auth/authSlice'
import {useNavigate, useSearchParams} from "react-router-dom";

function Wallet({onButtonClick}) {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const {t} = useTranslation()
    const [connect,setConnect] = useState(false)
    const color = useSelector(state => state.color.value)
    const [inviteId, setInviteId] = useState()
    const [searchParams, setSearchParams] = useSearchParams()
    function handleClick() {
        onButtonClick(0)
    }

    useEffect(() => {
        if (searchParams.get("invite")){
            setInviteId(searchParams.get("inviteId"))
        }
    }, []);

    function connectWallet() {
        if(window.ethereum === void 0) {
            message.fail(t("installMetaMask"))
        }else {
            setConnect(true)
            const web3 = new Web3(window.ethereum)

            window.ethereum.request({ method: 'eth_requestAccounts' }).then(res=>{
                const address = res[0]
                const timeStampString = new Date().getTime().toString()
                window.ethereum.request({
                    method: "personal_sign",
                    params: [`login&time=${timeStampString}`, address],
                }).then(_r=>{
                    const data = {
                        timestamp: timeStampString,
                        signature: _r,
                        address,
                        invitationId: inviteId
                    }
                    console.log(data)
                    loginApi.walletLogin(data).then(res=>{
                        console.log(res)
                        dispatch(Login(res.data.tokenValue))
                        navigate('/index')
                    })
                }).catch(_e=>{
                    message.fail(_e.message)
                    setConnect(false)
                })
            }).catch(err=>{
                message.fail(err.message)
                setConnect(false)
            })
        }
    }

    return (
        <div className={'login_wallet_component'}>
            <div className={'login_wallet_block'}>
                <img onClick={handleClick} src={color === 'white'?close:closeDark} className={'login_wallet_close_icon'} alt='close'/>
                <div className={'login_wallet_top'}>
                    <img src={require("@/assets/image/login/login_logo.png")} alt='logo'/>
                    <div className={'login_wallet_top_text'}>
                        <div className={'login_top_title'}>{t("connectWallet")}</div>
                        <div className={'login_top_text'}>{t("connectText")}</div>
                    </div>
                </div>
                {
                    connect &&  (
                        <div className={'login_connect_wallet'}>
                            <div>
                                <div className={'login_connect_title'}>{t("Connecting")}MetaMask...</div>
                                <div className={'login_connect_text'}>{t("connectingText")}</div>
                            </div>
                            <div className={'login_connect_logo'}>
                                <img src={require("@/assets/image/login/connect_binest.png")} alt='logo'/>
                                <img src={require("@/assets/image/login/connect_icon.png")} alt='logo'/>
                                <img src={require("@/assets/image/login/connect_metamark.png")} alt='logo'/>
                            </div>
                        </div>
                    ) || (
                        <div className={'login_wallet_bottom'}>
                            <div className={'login_wallet_item'} onClick={connectWallet}>
                                <img src={require("@/assets/image/login/wallet_icon.png")} alt='wallet'
                                     className={'login_wallet_item_logo'}/>
                                MetaMask
                            </div>
                    </div>)
                }
            </div>
            {!connect || (
                <div onClick={() => {
                    setConnect(false)
                }} className={'login_return_wallet_button'}>{t("returnWallet")}</div>
            )}
        </div>
    )
}

export default Wallet
