import '../user_module.scss'
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";
import Modal from "../../../components/Modal";
import {useEffect, useState} from "react";
import Pagination from "../../../components/Pagination";
import userApi from '@/api/user'
import useSyncCallback from "../../../utils/useSyncCallback";
import { projectTypeIndex} from "../../../enum/projectType";
import { auditStatusObj} from "../../../enum/auditStatus";
import {useNavigate} from "react-router-dom";
import projectsApi from "../../../api/projects";
import message from "../../../utils/message";
import includedApi from '@/api/included'
import {Spin} from "antd";
import commonApi from "../../../api/common";
import investorApi from "../../../api/investor";
import characterApi from "../../../api/character";
import meetingApi from "../../../api/meeting";
import kolApi from '../../../api/kol'
import moreApi from '../../../api/more'
import {
    hackathonContract,
    investorContract, kolContract,
    meetingContract,
    peopleContract,
    playerContract,
    projectContract, toolContract,
    web3
} from "../../../utils/web3Utils";
import dayjs from "dayjs";

function Draft() {
    const {t} = useTranslation()
    const navigate = useNavigate()
    const color = useSelector(state => state.color.value)
    const [isModalOpen,setIsModalOpen] = useState(false)
    const [pageNum, setPageNum] = useState(1)
    const pageSize = 8
    const [pageData, setPageData] = useState()
    const [isModalOpen2,setIsModalOpen2] = useState(false)
    const [detailInfo, setDetailInfo] = useState({})
    const [address,setAddress] = useState('')
    const [staked,setStaked] = useState('')
    const [spinning,setSpinning] = useState(false)
    const [delId, setDelId] = useState('')
    const [contract, setContract] = useState({})
    const [remarkModel, setRemarkModel] = useState(false)
    const [checkData, setCheckData] = useState({})

    function changePage(e) {
        setPageNum(e)
        queryDraftList()
    }

    useEffect(()=>{
        if(window.ethereum === void 0) {
            message.fail(t("installMetaMask"))
        }else {
            web3.eth.getAccounts().then((res)=>{
                if (!res[0]) {
                    res = window.ethereum.request({method: 'eth_requestAccounts'})
                }
                setAddress(res[0])
                queryDraftList()
                playerContract.methods.getUserInfo(res[0]).call().then((res)=>{
                    setStaked(web3.utils.fromWei(res["_stakedAmount"],'ether'))
                })
            })
        }
    },[])

    const queryDraftList = useSyncCallback(()=>{
        const data = {pageNum,pageSize}
        userApi.getDraftList(data).then(res=>{
            const list = res.rows.map(item=>{
                console.log(item)
                item.typeLabel = projectTypeIndex[item.type]
                return item
            })
            const data = {
                total: res.total,
                tableList: list
            }
            setPageData({...data})
        })
    })

    function toUpdatePage(type,id,draftId) {
        switch (type) {
            case "0":
                navigate(`/project/update/${id}?type=draft&draftId=${draftId}`)
                break;
            case "1":
                navigate(`/investors/update/${id}?type=draft&draftId=${draftId}`)
                break;
            case "2":
                navigate(`/people/update/${id}?type=draft&draftId=${draftId}`)
                break;
            case "3":
                navigate(`/meeting/update/${id}?type=draft&draftId=${draftId}`)
                break;
            case "4":
                navigate(`/kol/update/${id}?type=draft&draftId=${draftId}`)
                break;
            case "6":
                navigate(`/more/tool/update/${id}?type=draft&draftId=${draftId}`)
                break;
            case "7":
                navigate(`/more/hackathon/update/${id}?type=draft&draftId=${draftId}`)
                break;
        }
    }

    const toUpload = useSyncCallback((id,type,draftId)=>{
        switch (type) {
            case "0":
                projectsApi.queryProjectsDetailFromDB({id}).then(res=>{
                    setDetailInfo({...res.data,projectType: type})
                    const {data} = res
                    if (!data.logoUrl || !data.name || !data.officialWebsite || !data.introduction || !data.content || !data.label || !data.establishTime || !data.location || (data.coinIssuanceSatus === null || data.coinIssuanceSatus === void 0) || (data.operateStatus === null || data.operateStatus === void 0) || (data.auditReportStatus === null || data.auditReportStatus === void 0) || data.smcList.length === 0) {
                        message.fail(t('completeInfo'))
                        toUpdatePage(type,id,draftId)
                    }else {
                        setContract(projectContract)
                        projectsApi.queryCheckName(data.name).then(res=>{
                            if(res.data === 0){
                                setIsModalOpen2(true)
                            }else {
                                setRemarkModel(true)
                            }
                        })
                    }
                })
                break;
            case "1":
                investorApi.queryInvestmentDetailFromDB({id}).then(res=>{
                    setDetailInfo({...res.data,projectType: type})
                    const {data} = res
                    if (!data.logoUrl || !data.name || !data.officialWebsite || !data.label || !data.content || !data.establishTime || !data.location || data.smcList.length === 0) {
                        message.fail(t('completeInfo'))
                        toUpdatePage(type,id,draftId)
                    }else {
                        setContract(investorContract)
                        investorApi.queryCheckName(data.name).then(res=>{
                            if(res.data === 0){
                                setIsModalOpen2(true)
                            }else {
                                setRemarkModel(true)
                            }
                        })
                    }
                })
                break;
            case "2":
                characterApi.queryCharacterDetailFromDB({id}).then(res=>{
                    setDetailInfo({...res.data,projectType: type})
                    const {data} = res
                    if (!data.headImgUrl || !data.name || !data.content || !data.content || data.smcList.length === 0) {
                        message.fail(t('completeInfo'))
                        toUpdatePage(type,id,draftId)
                    }else {
                        setContract(peopleContract)
                        characterApi.queryCheckName(data.name).then(res=>{
                            if(res.data === 0){
                                setIsModalOpen2(true)
                            }else {
                                setRemarkModel(true)
                            }
                        })
                    }
                })
                break;
            case "3":
                meetingApi.queryMeetingDetailFromDB({id}).then(res=>{
                    setDetailInfo({...res.data,projectType: type})
                    const {data} = res
                    if (!data.meetingUrl || !data.theme || !data.website || !data.content || (!data.startTime && !data.endTime) || !data.source) {
                        message.fail(t('completeInfo'))
                        toUpdatePage(type,id,draftId)
                    }else {
                        setContract(meetingContract)
                        meetingApi.queryCheckName(data.name).then(res=>{
                            if(res.data === 0){
                                setIsModalOpen2(true)
                            }else {
                                setRemarkModel(true)
                            }
                        })
                    }
                })
                break;
            case "4":
                kolApi.queryKolDetailFromDB({id}).then(res=>{
                    setDetailInfo({...res.data,projectType: type})
                    const {data} = res
                    if (!data.kolUrl || !data.nickname || !data.country || !data.content || data.smcList.length === 0) {
                        message.fail(t('completeInfo'))
                        toUpdatePage(type,id,draftId)
                    }else {
                        setContract(kolContract)
                        kolApi.queryCheckName(data.name).then(res=>{
                            if(res.data === 0){
                                setIsModalOpen2(true)
                            }else {
                                setRemarkModel(true)
                            }
                        })
                    }
                })
                break;
            case "6":
                moreApi.queryToolsDetailFromDB({id}).then(res=>{
                    setDetailInfo({...res.data,projectType: type})
                    const {data} = res
                    console.log(data.logoUrl,data.name,data.content,data.time,data.link)
                    if(!data.logoUrl || !data.name || !data.label || !data.synopsis || !data.content || (data.walletStatus === null || data.walletStatus === void 0)) {
                        message.fail(t('completeInfo'))
                        toUpdatePage(type,id,draftId)
                    }else {
                        setContract(toolContract)
                        moreApi.queryCheckNameTools(data.name).then(res=>{
                            if(res.data === 0){
                                setIsModalOpen2(true)
                            }else {
                                setRemarkModel(true)
                            }
                        })
                    }
                })
                break;
            case "7":
                moreApi.queryHackDetailFromDB({id}).then(res=>{
                    setDetailInfo({...res.data,projectType: type})
                    const {data} = res
                    if (!data.logoUrl || !data.name || !data.content || !data.time || !data.link) {
                        message.fail(t('completeInfo'))
                        toUpdatePage(type,id,draftId)
                    }else {
                        setContract(hackathonContract)
                        moreApi.queryCheckNameHack(data.name).then(res=>{
                            if(res.data === 0){
                                setIsModalOpen2(true)
                            }else {
                                setRemarkModel(true)
                            }
                        })
                    }
                })
                break;
        }

    })

    const includedFun = async (data)=>{
        let apiFun;
        switch (data.projectType) {
            case "0":
                apiFun = await includedApi.submitProjectForm({...data,createTimeString: dayjs(Date.now()).format("YYYY-MM-DD HH:mm:ss")})
                break;
            case "1":
                apiFun = await includedApi.submitInvestorForm({...data,createTimeString: dayjs(Date.now()).format("YYYY-MM-DD HH:mm:ss")})
                break;
            case "2":
                apiFun = await includedApi.submitPeopleForm({...data,createTimeString: dayjs(Date.now()).format("YYYY-MM-DD HH:mm:ss")})
                break;
            case "3":
                apiFun = await includedApi.submitMeeingForm({...data,createTimeString: dayjs(Date.now()).format("YYYY-MM-DD HH:mm:ss")})
                break;
            case "4":
                apiFun = await includedApi.submitKolForm({...data,createTimeString: dayjs(Date.now()).format("YYYY-MM-DD HH:mm:ss")})
                break;
            case "6":
                apiFun = await includedApi.submitToolForm({...data,createTimeString: dayjs(Date.now()).format("YYYY-MM-DD HH:mm:ss")})
                break;
            case "7":
                apiFun = await includedApi.submitHackForm({...data,createTimeString: dayjs(Date.now()).format("YYYY-MM-DD HH:mm:ss")})
                break;
        }
        return apiFun
    }

    const toUploadDraft = useSyncCallback(()=>{
        setSpinning(true)

        includedFun({...detailInfo,submitType: 1}).then(res=>{
            const obj = res.data
            contract.methods.createWiki(obj.mId,obj.cId,obj.lang,obj.qmHash).send({from: address}).then((res)=>{
                console.log(res)
                if (res.status === 1n) {
                    toDelData(obj.cId)
                    commonApi.updateTransactionHash({
                        qmHash: obj.qmHash,
                        transactionHash: res.transactionHash
                    }).then(_=>{
                        userApi.updateAuditStatus({
                            projectId: obj.cId,
                            status: auditStatusObj["unaudited"].type
                        }).then(_=>{
                            message.success(t('saveSuccess'))
                        })
                    })
                }else {
                    message.fail(t('DenialAuthorization'))
                }
                setSpinning(false)
                setIsModalOpen2(false)
            }).catch((err)=>{
                console.log(err)
                switch (detailInfo.projectType) {
                    case "0":
                        projectsApi.delProject(obj.cId)
                        break;
                    case "1":
                        investorApi.delInvestor(obj.cId)
                        break;
                    case "2":
                        characterApi.delCharacter(obj.cId)
                        break;
                    case "3":
                        meetingApi.delMeeting(obj.cId)
                        break;
                    case "4":
                        kolApi.delKol(obj.cId)
                        break;
                    case "6":
                        moreApi.delTools(obj.cId)
                        break;
                    case "7":
                        moreApi.delHack(obj.cId)
                        break;
                }
                commonApi.delExamineDataByQmHash(obj.qmHash)
                setSpinning(false)
            })
        })
    })

    const toDelData = useSyncCallback(()=>{
        setSpinning(true)
        userApi.delUserDraft(delId).then(res=>{
            setIsModalOpen(false)
            setSpinning(false)
            queryDraftList()
        })
    })

    return (
        <div className={'profile_main_block'}>
            <div className={'profile_main_block_title'}>{t('drafts')}</div>
            <div className={'wiki_table_block'}>
                <div className={'wiki_table_title'}>
                    <div>
                        <img src={require('../../../assets/image/user/icon_name.png')}/>
                        {t('name')}
                    </div>
                    <div>
                        <img src={require('../../../assets/image/user/icon_CollectionType.png')}/>
                        {t('CollectionType')}
                    </div>
                    <div>
                        <img src={require('../../../assets/image/user/icon_LastChangeTime.png')}/>
                        {t('LastChangeTime')}
                    </div>
                    <div>
                        <img src={require('../../../assets/image/user/icon_Operate.png')}/>
                        {t('Operate')}
                    </div>
                </div>
                {
                    pageData?.tableList.map(item => {
                        return (
                            <div className={'wiki_table_cell'}>
                                <div>{item.name}</div>
                                <div>{t(item.typeLabel)}</div>
                                <div>{dayjs(item.updateTimestamp).format("YYYY-MM-DD HH:mm:ss")}</div>
                                {
                                    color === 'white' && (
                                        <div className={'draft_operate_icon'}>
                                            <img onClick={()=>toUpdatePage(item.type,item.projectId,item.id)} src={require('@/assets/image/user/iconly_edit.png')} alt='edit'/>
                                            <img onClick={()=>{
                                                setDelId(item.id)
                                                toUpload(item.projectId,item.type,item.id)
                                            }} src={require('@/assets/image/user/iconly_upload.png')} alt='edit'/>
                                            <img onClick={() => {
                                                setDelId(item.id)
                                                setIsModalOpen(true)
                                            }}
                                                 src={require('@/assets/image/user/iconly_del.png')} alt='edit'/>
                                        </div>
                                    )
                                }
                                {
                                    color === 'dark' && (
                                        <div className={'draft_operate_icon'}>
                                            <img onClick={()=>toUpdatePage(item.type,item.projectId,item.id)} src={require('@/assets/image/user/iconly_edit_dark.png')} alt='edit'/>
                                            <img onClick={()=>{
                                                setDelId(item.id)
                                                toUpload(item.projectId)
                                            }} src={require('@/assets/image/user/iconly_upload_dark.png')} alt='edit'/>
                                            <img onClick={() => {
                                                setDelId(item.id)
                                                setIsModalOpen(true)
                                            }}
                                                 src={require('@/assets/image/user/iconly_del_dark.png')} alt='edit'/>
                                        </div>
                                    )
                                }
                            </div>
                        )
                    })
                }
            </div>
            <div className={'wiki_page_block'}>
                <Pagination total={pageData?.total} size={pageSize} page={pageNum} onChange={changePage}></Pagination>
            </div>
            <Modal open={isModalOpen2}>
                <div className={'draft_del_dialog'}>
                    <img onClick={() => setIsModalOpen2(false)} className={'project_comment_dialog_close'}
                         src={require('../../../assets/image/home/dialog_close.png')} alt='close'/>
                    <div className={'project_comment_dialog_title'}>{t('UploadDraft')}</div>
                    <div className={'project_handle_btn'}>
                        <div onClick={() => setIsModalOpen2(false)}>{t('cancle')}</div>
                        <div onClick={toUploadDraft} className={'project_handle_btn_confirm'}>{t('confirm')}</div>
                    </div>
                </div>
            </Modal>
            <Modal open={isModalOpen}>
                <div className={'draft_del_dialog'}>
                    <img onClick={() => setIsModalOpen(false)} className={'project_comment_dialog_close'}
                         src={require('../../../assets/image/home/dialog_close.png')} alt='close'/>
                    <div className={'project_comment_dialog_title'}>{t('draftDelModal')}</div>
                    <div className={'project_handle_btn'}>
                        <div onClick={() => setIsModalOpen(false)}>{t('cancle')}</div>
                        <div onClick={toDelData} className={'project_handle_btn_confirm'}>{t('confirm')}</div>
                    </div>
                </div>
            </Modal>
            <Modal open={remarkModel}>
                <div className='project_comment_dialog'>
                    <img onClick={() => setRemarkModel(false)} className={'project_comment_dialog_close'}
                         src={require('@/assets/image/home/dialog_close.png')} alt='close'/>
                    <div className='project_comment_dialog_title'>{t('remind')}</div>
                    <div className='project_comment_dialog_text' style={{color: '#FF2929'}}>{t('remind_text')}</div>
                    <div className='project_handle_btn'>
                        <div onClick={() => setRemarkModel(false)}>{t('close')}</div>
                        <div onClick={()=> {
                            setRemarkModel(false)
                            toUploadDraft()
                        }} className='project_handle_btn_confirm'>{t('SubmitInclusion')}</div>
                    </div>
                </div>
            </Modal>
            <Spin tip="Loading" fullscreen spinning={spinning} size="large"/>
        </div>

    )
}

export default Draft;
