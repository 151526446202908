import '../user_module.scss'
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";
import {useEffect, useState} from "react";
import useSyncCallback from "../../../utils/useSyncCallback";
import Modal from "../../../components/Modal";
import {Input, Spin} from "antd";
import message from "../../../utils/message";
import {playerAddress, playerContract, web3, wikiContract} from "../../../utils/web3Utils";
function WiKi() {
    const {t} = useTranslation()
    const color = useSelector(state => state.color.value)
    const [balance, setBalance] = useState('')
    const [freeze,setFreeze] = useState(0)
    const [staked,setStaked] = useState(0)
    const [modalStatus,setModalStatus] = useState(false)
    const [stakeCount, setStakeCount] = useState(0)
    const [address,setAddress] = useState('')
    const [unStakeModalStatus, setUnStakeModalStatus] = useState(false)
    const [unStakeCount, setUnStakeCount] = useState(0)
    const [spinning, setSpinning] = useState(false)

    const collectWallet = useSyncCallback(()=>{
        playerContract.methods.getUserInfo(address).call().then((res)=>{
            const freezeAmount = web3.utils.fromWei(res["_freezeAmount"],'ether')
            const stakedAmount = web3.utils.fromWei(res["_stakedAmount"],'ether')
            setFreeze(freezeAmount)
            setStaked(stakedAmount)
        })
    })

    const toStake = useSyncCallback(()=>{
        wikiContract.methods.balanceOf(address).call().then((res)=>{
            const balanceAmount = web3.utils.fromWei(res,'ether')
            setBalance(balanceAmount)
            setModalStatus(true)
        })
    })

    const confirmToStake = useSyncCallback(()=>{
        setSpinning(true)
        let userBalance = balance === '0.'?0:balance
        if (Number(stakeCount) > Number(userBalance)) return message.fail(t('contractErrorToast'));
        wikiContract.methods.allowance(address,playerAddress).call().then((res)=>{
            const approveBalance = web3.utils.fromWei(res,'ether') === '0.'?0:web3.utils.fromWei(res,'ether')
            if (approveBalance < Number(stakeCount)) {
                wikiContract.methods.approve(playerAddress,web3.utils.toWei(stakeCount,'ether')).send({from: address}).then((result)=>{
                    if (result.status === 1n) {
                        stakeFun(web3.utils.toWei(stakeCount,'ether'))
                    }else {
                        setSpinning(false)
                        message.fail(t('DenialAuthorization'))
                    }
                }).catch((err)=>{
                    console.warn(err)
                    setSpinning(false)
                    message.fail(t('DenialAuthorization'))
                })
            }else {
                stakeFun(web3.utils.toWei(stakeCount,'ether'))
            }
        }).catch((err)=>{
            setSpinning(false)
            message.fail(t('DenialAuthorization'))
        })
    })

    const stakeFun = useSyncCallback((amount)=>{
        playerContract.methods.stake(amount).send({from: address}).then((res)=>{
            if (res.status === 1n) {
                setModalStatus(false)
                collectWallet()
                window.location.reload()
            }else {
                message.fail(t('DenialAuthorization'))
            }
            setSpinning(false)
        }).catch((err)=>{
            setSpinning(false)
            message.fail(t('DenialAuthorization'))
        })
    })

    const toUnStakeFun = useSyncCallback(()=>{
        if (Number(unStakeCount) > Number(staked)) return message.fail(t('StakeNoMore'))
        playerContract.methods.unStake(web3.utils.toWei(unStakeCount,'ether')).send({from: address}).then(res=>{
            console.log(res)
            if (res.status === 1n) {
                setUnStakeModalStatus(false)
                collectWallet()
            }else {
                message.fail(t('DenialAuthorization'))
            }
        })
    })

    useEffect(()=>{
        web3.eth.getAccounts().then((res)=>{
            if (!res[0]) {
                res = window.ethereum.request({method: 'eth_requestAccounts'})
            }
            setAddress(res[0])
            collectWallet()
        })
    },[])

    return (
        <>
            <div className={'profile_main_block'}>
                <div className={'profile_main_block_title'}>
                    <span>WIKI</span>
                    <div className={'wiki_info_rules_btn'}>
                        {t('WikiRules')}
                        <img
                            src={color === 'white' ? require('../../../assets/image/user/rules_more.png') : require('../../../assets/image/user/rules_more_dark.png')}
                            alt='more'/>
                    </div>
                </div>
                <div className='wiki_main_block'>
                    <div className='wiki_main_title'>
                        <img src={require('../../../assets/image/user/wiki_icon.png')} alt='icon'/>
                        <span>{t('balance')}</span>
                    </div>
                    <div className='wiki_balance'>{staked}</div>
                    <div className='wiki_balance_freeze'><span>{t('freeze')}</span>：{freeze}</div>
                    <div className='wiki_handle_btn'>
                        <div onClick={toStake}>{t('Stake')}</div>
                        <div onClick={()=>{
                            if (staked === '0.') return message.fail(t('StakeIsZero'))
                            setUnStakeModalStatus(true)
                        }}>{t('unStake')}</div>
                    </div>
                </div>
            </div>
            <Modal open={modalStatus}>
                <div className='project_comment_dialog' style={{width: 500}}>
                    <img onClick={() => setModalStatus(false)} className='project_comment_dialog_close'
                         src={require('@/assets/image/home/dialog_close.png')} alt='close'/>
                    <div className='project_comment_dialog_title'>{t('stakeNumber')}</div>
                    <div className='wiki_user_balance'>{t('balance')}: {balance}</div>
                    <Input type="Number" value={stakeCount} onChange={e => setStakeCount(e.target.value)} className='wiki_stake_input'></Input>
                    <div className='project_handle_btn'>
                        <div onClick={() => setModalStatus(false)}>{t('cancle')}</div>
                        <div onClick={confirmToStake}
                             className='project_handle_btn_confirm'>{t('confirm')}</div>
                    </div>
                </div>
            </Modal>
            <Modal open={unStakeModalStatus}>
                <div className='project_comment_dialog' style={{width: 500}}>
                    <img onClick={() => setUnStakeModalStatus(false)} className='project_comment_dialog_close'
                         src={require('@/assets/image/home/dialog_close.png')} alt='close'/>
                    <div className='project_comment_dialog_title'>{t('stakeNumber')}</div>
                    <Input type="Number" value={unStakeCount} onChange={e => setUnStakeCount(e.target.value)} className='wiki_stake_input'></Input>
                    <div className='project_handle_btn'>
                        <div onClick={() => setUnStakeModalStatus(false)}>{t('cancle')}</div>
                        <div onClick={toUnStakeFun}
                             className='project_handle_btn_confirm'>{t('confirm')}</div>
                    </div>
                </div>
            </Modal>
            <Spin tip="Loading" fullscreen spinning={spinning} size="large"/>
        </>
    )
}

export default WiKi;
