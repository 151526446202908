import request from '@/utils/request'

class api {
    querySearchList(data) {
        return request.get('/search/esList',data)
    }

    addSearchHistory(data) {
        return request.post('/browsingHistory/add',data)
    }

    queryHistoryList(data) {
        return request.get('/browsingHistory/list',data)
    }
}

export default new api();
