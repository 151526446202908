import './routerLine_components.scss'
import {useTranslation} from "react-i18next";
import {Link, useLocation, useNavigate} from "react-router-dom";

function RouterLine(props) {
    const { t } = useTranslation()
    const location = useLocation()
    const route = location.pathname.split('/')[1]
    return (
        <div className={'router_line_block'}>
            <Link to={'/'}>
                <span className={'router_line_block_color'}>{t('Home')}</span>
            </Link>
            <img src={require('../../assets/image/project/router_arrow.png')} alt='arrow'/>
            <Link to={-1}>
                <span className={'router_line_block_color'}>{t(route)}</span>
            </Link>
            <img src={require('../../assets/image/project/router_arrow.png')} alt='arrow'/>
            <span>{props.name}</span>
        </div>
    )
}

export default RouterLine;
