import {Upload as UploadFile} from "antd";
import {useState} from "react";
import ImgCrop from "antd-img-crop";
import {useTranslation} from "react-i18next";

function Upload(props) {
    const uploadUrl = '/api/api/v1/common/upload'
    const {t} = useTranslation()

    function beforeUpload(e) {
        console.log(e)

    }

    function uploadState(e) {
        if (e.fileList[0].status === 'done') {
            props.onSuccess(e.fileList[0].response)
        }
    }
    return (
        <>
            {
                !props.type || props.type === 'image' ? (
                    <ImgCrop modalTitle={t("editImage")} resetText={t("reset")} quality={1} aspect={props.aspect || 1} showReset rotationSlider modalOk={t("apply")} modalCancel={t("cancle")}>
                        <UploadFile maxCount={1} accept={props.accept} action={uploadUrl} beforeUpload={beforeUpload} onChange={uploadState}>
                            {props.children}
                        </UploadFile>
                    </ImgCrop>
                ): (
                    <UploadFile maxCount={1} accept={props.accept} action={uploadUrl} beforeUpload={beforeUpload} onChange={uploadState}>
                        {props.children}
                    </UploadFile>
                )
            }
        </>
    )
}

export default Upload;
