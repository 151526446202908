import './people_list_module.scss'
import PageHeader from "@/components/PageHeader";
import PageFooter from "@/components/PageFooter";
import Pagination from "@/components/Pagination";
import {useTranslation} from "react-i18next";
import {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import {Link} from "react-router-dom";
import characterApi from "../../../api/character";
import useSyncCallback from "../../../utils/useSyncCallback";
import {Dropdown} from "antd";
import projectsApi from "../../../api/projects";
import {getMediaCircleIcon, getMediaIcon} from "../../../enum/mediaType";
import investorApi from "../../../api/investor";
import commonApi from '../../../api/common'

function List() {
    const { t } = useTranslation();
    const color = useSelector(state => state.color.value)
    const lang = useSelector(state=>state.language.value)
    const baseUrl = process.env.REACT_APP_BASE_URL
    const [hoverKey,setHoverKey] = useState(null)
    const [peopleListLocation,setPeopleListLocation] = useState(1)
    const [width,setWidth] = useState(1920)
    const [pageNum, setPageNum] = useState(1)
    const [requestType,setRequestType] = useState()
    const [requestSort,setRequestSort] = useState(0)
    const [tableData,setTableData] = useState({
        total: 0,
        list: [],
    })
    const [recommend, setRecommend] = useState([])
    const [projectList, setProjectList] = useState([])
    const [investorsList, setInvestorsList] = useState([])
    const [schoolList, setSchoolList] = useState([])
    const [projectCheck, setProjectCheck] = useState({})
    const [investorsCheck, setInvestorsCheck] = useState({})
    const [schoolCheck, setSchoolCheck] = useState({})
    const [companyIds, setCompanyIds] = useState({
        project: '',
        investor: ''
    })
    console.log(width)
    useEffect(() => {
        setWidth(document.documentElement.clientWidth)
        getList()
        getRecommendList()
        getProjectList()
        getInvestorsList()
        getUniversity()
    }, []);

    function changePage(e) {
        console.log(e)

    }

    function clickLocaiton(flag) {
        if (flag) {
            setPeopleListLocation((e)=>{
                return e+1
            })
        }else {
            setPeopleListLocation((e)=>{
                return e-1
            })
        }
    }

    const getList = useSyncCallback(()=>{
        characterApi.queryCharacterList({
            pageNum,
            pageSize: 24,
            lang: lang.value,
            sort: requestSort === void 0?0:requestSort,
            type: requestType === void 0?0:requestType,
            company: !companyIds.project && !companyIds.investor?void 0:companyIds.project && !companyIds.investor?companyIds.project:!companyIds.project && companyIds.investor? companyIds.investor:companyIds.project+','+companyIds.investor,
            school: schoolCheck.key,
            post: void 0,
            name: void 0,
            recommend: void 0
        }).then(res=>{
            setTableData(e=>{
                let obj = {...e}
                obj.list = res.rows
                obj.total = res.total
                return {...obj}
            })
        })
    })
    const getRecommendList = useSyncCallback(()=>{
        characterApi.queryCharacterList({
            pageNum,
            pageSize: 24,
            lang: lang.value,
            type: 0,
            sort: 0,
            recommend: 1
        }).then(res=>{
            setRecommend(res.rows)
            if (res.rows.length === 1){
                setPeopleListLocation(-1)
            }else if(res.rows.length === 2){
                setPeopleListLocation(0)
            }else {
                setPeopleListLocation(1)
            }
        })
    })

    const checkHottest = useSyncCallback((type)=>{
        setRequestSort(()=>void 0)
        setPageNum(1)
        setRequestType(type || 0)
        if(requestSort !== void 0) {
            if (requestSort ===  1){
                setRequestSort(0)
            }else {
                setRequestSort(1)
            }
        }else {
            setRequestSort(0)
        }
        getList()
    })

    const checkRecently = useSyncCallback((type)=>{
        setRequestSort(()=>void 0)
        setPageNum(1)
        setRequestType(type || 1)
        if(requestSort !== void 0) {
            if (requestSort ===  1){
                setRequestSort(0)
            }else {
                setRequestSort(1)
            }
        }else {
            setRequestSort(0)
        }
        getList()
    })

    const getProjectList = useSyncCallback(()=>{
        projectsApi.queryProjectsList({
            pageNum: 1,
            pageSize: 100,
            lang: lang.value,
            sort: 0,
            type: 0
        }).then(res=>{
            setProjectList(()=>{
                return res.rows.map(item=>{
                    return {
                        label: item.name,
                        key: item.id
                    }
                })
            })
        })
    })

    const getInvestorsList = useSyncCallback(()=>{
        investorApi.queryInvestorList({
            pageNum,
            pageSize: 100,
            lang: lang.value,
            sort: 0,
            type: 0
        }).then(res=>{
            setInvestorsList(()=>{
                return res.rows.map(item=>{
                    return {
                        label: item.name,
                        key: item.id
                    }
                })
            })
        })
    })

    const getUniversity = useSyncCallback(()=>{
        commonApi.queryUniversityList({
            pageNum: 1,
            pageSize: 100
        }).then(res=>{
            setSchoolList(()=>{
                return res.rows.map(item=>{
                    return {
                        label: lang.value == 0? item.enName:item.name,
                        key: item.id
                    }
                })
            })
        })
    })

    const changeProject = useSyncCallback((e)=>{
        console.log('log,.....',e)
        for (const item of projectList) {
            if (item.key === e.key){
                setProjectCheck(item)
                break;
            }
        }
        setCompanyIds(item=>{
            return {
                ...item,
                project: e.key
            }
        })
        getList()
    })

    const changeInvestors = useSyncCallback((e)=>{
        for (const item of investorsList) {
            if (item.key === e.key){
                setInvestorsCheck(item)
                break;
            }
        }
        setCompanyIds(item=>{
            return {
                ...item,
                investor: e.key
            }
        })
        getList()
    })

    const changeSchool = useSyncCallback((e)=>{
        for (const item of schoolList) {
            if (item.key === e.key){
                setSchoolCheck(item)
                break;
            }
        }
        getList()
    })
    return (
        <div className={'main_back'}>
            <PageHeader other={true}></PageHeader>
            <div className={'people_list_block'}>
                <div className={'people_list_title'}>{t('PeopleListTitle')}</div>
                <div className={'people_list_text'}>{t('PeopleListText')}</div>
                <div className={'people_list_popular_figures_block'}>
                    <span className={'people_list_popular_figures_title'}>{t('PopularFigures')}</span>
                    <div className={'people_list_popular_figures'} style={{width: width}}>
                        <img onClick={() => clickLocaiton(true)} className={'people_list_arrow'}
                             src={require('@/assets/image/people/people_arrow_l.png')} alt='arrow'/>
                        <img onClick={() => clickLocaiton(false)} className={'people_list_arrow'}
                             src={require('@/assets/image/people/people_arrow_r.png')} alt='arrow'/>
                        <div className={'people_list_left_modal'}></div>
                        <div className={'people_list_right_modal'}></div>
                        {
                            recommend.map((item, index) => {
                                return (
                                    <Link to={`/people/detail/${item.id}`}>
                                        <div onMouseOver={() => setHoverKey(index)}
                                             onMouseLeave={() => setHoverKey(null)}
                                             className={`people_list_popular_figures_item ${color === 'dark' && hoverKey === index ? 'people_list_popular_figures_dark_back' : ''}`}
                                             style={{left: -266 * peopleListLocation}}>
                                            {index !== hoverKey && (
                                                color === 'white' ? (<img className={'people_hot_icon'}
                                                                          src={require('@/assets/image/people/hot_yellow.png')}
                                                                          alt='hot'/>) :
                                                    <div className={'people_hot_icon people_hot_border'}>
                                                        <img className={'people_hot_icon'}
                                                             src={require('@/assets/image/people/hot_yellow_dark.png')}
                                                             alt='hot'/>
                                                    </div>
                                            )}
                                            {index === hoverKey && (
                                                <img className={'people_hot_icon'}
                                                     src={require('@/assets/image/people/hot_color.png')}
                                                     alt='hot'/>
                                            )}
                                            <img className={'people_list_popular_figures_item_img'} style={{borderRadius: '50%'}}
                                                 src={baseUrl + item.headImgUrl}
                                                 alt='logo'/>
                                            <div className={'people_list_popular_figures_item_name'}>{item.name}</div>
                                            <div className={'people_list_popular_figures_item_tag'}>Coin Center</div>
                                            <div className={'people_list_popular_figures_item_tag2'}>Co-Founder</div>
                                        </div>
                                    </Link>
                                )
                            })
                        }
                    </div>
                </div>
                <div className={'people_list_popular_figures_block people_list_character_block'}
                     style={{paddingBottom: 45}}>
                    <span className={'people_list_popular_figures_title'}
                          style={{marginLeft: 0}}>{t('CharacterList')}</span>
                    <div className={'people_list_character_main'}>
                        <div className={'people_list_character_sort'}>
                            <div className={'people_list_character_sort_block'}>
                                <Dropdown menu={{
                                    items: projectList,
                                    onClick: changeProject
                                }}>
                                    <div className={'people_list_character_sort_left_block'}>
                                        {projectCheck.label || t('projects')}
                                        <img
                                            src={color === 'white' ? require('../../../assets/image/investors/nocheck_icon.png') : require('../../../assets/image/investors/nocheck_icon_dark.png')}
                                            alt='noCheck'/>
                                    </div>
                                </Dropdown>
                                <Dropdown menu={{
                                    items: investorsList,
                                    onClick: changeInvestors
                                }}>
                                    <div className={'people_list_character_sort_left_block'}>
                                        <div>{ investorsCheck.label || t('investors')}</div>
                                        <img
                                            src={color === 'white' ? require('../../../assets/image/investors/nocheck_icon.png') : require('../../../assets/image/investors/nocheck_icon_dark.png')}
                                            alt='noCheck'/>
                                    </div>
                                </Dropdown>
                                <Dropdown menu={{
                                    items: schoolList,
                                    onClick: changeSchool
                                }}>
                                    <div className={'people_list_character_sort_left_block'}>
                                        { schoolCheck.label || t('University')}
                                        <img
                                            src={color === 'white' ? require('../../../assets/image/investors/nocheck_icon.png') : require('../../../assets/image/investors/nocheck_icon_dark.png')}
                                            alt='noCheck'/>
                                    </div>
                                </Dropdown>
                            </div>
                            <div className={'people_list_character_sort_block'}>
                                <div className={'project_list_left_menu_top_grid_item'} onClick={() => checkHottest(0)}>
                                    {requestType !== 0 && (
                                        <img src={require('@/assets/image/project/sort_icon.png')} alt='sort'/>)}
                                    {(requestType === 0 && requestSort === 1) && (
                                        <img src={require('@/assets/image/project/sort_icon_up.png')} alt='sort'/>)}
                                    {(requestType === 0 && requestSort === 0) && (
                                        <img src={require('@/assets/image/project/sort_icon_down.png')} alt='sort'/>)}
                                    {t('Hottest')}
                                </div>
                                <div className={'project_list_left_menu_top_grid_item'}
                                     onClick={() => checkRecently(1)}>
                                    {requestType !== 1 && (
                                        <img src={require('@/assets/image/project/sort_icon.png')} alt='sort'/>)}
                                    {(requestType === 1 && requestSort === 1) && (
                                        <img src={require('@/assets/image/project/sort_icon_up.png')} alt='sort'/>)}
                                    {(requestType === 1 && requestSort === 0) && (
                                        <img src={require('@/assets/image/project/sort_icon_down.png')} alt='sort'/>)}
                                    {t('Recently')}
                                </div>
                            </div>
                        </div>
                        <div className={'people_list_character_list'}>
                            {
                                tableData.list.map((item, index) => {
                                    return (
                                        <Link to={`/people/detail/${item.id}`}>
                                            <div className={'people_list_character_list_item'}>
                                                <img className={'people_list_popular_figures_item_img'}
                                                     src={baseUrl + item.headImgUrl} alt='logo'/>
                                                <div
                                                    className={'people_list_popular_figures_item_name'}>{item.name}</div>
                                                <div className={'people_list_popular_figures_item_video_img'}>
                                                    {
                                                        item.smcList.map((params)=>{
                                                            return (
                                                                <img src={getMediaCircleIcon(params.webType,color)}
                                                                     alt="params"/>
                                                            )
                                                        })
                                                    }
                                                </div>
                                                <div className={'people_list_popular_figures_item_tag'}>{item.workExperienceList[item.workExperienceList.length-1]?.remark}</div>
                                                <div className={'people_list_popular_figures_item_tag2'}>{item.workExperienceList[item.workExperienceList.length-1]?.post}</div>
                                            </div>
                                        </Link>
                                    )
                                })
                            }
                        </div>
                        <div className={'people_page_block'}>
                            <Pagination total={tableData.total} size={24} page={pageNum} onChange={changePage}></Pagination>
                        </div>
                    </div>
                </div>
            </div>
            <PageFooter></PageFooter>
        </div>
    )
}

export default List;
