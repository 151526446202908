import request from '../utils/request'

class Api {
    userRegistration(data) {
        return request.post('/api/v1/user/addWebUser',data)
    }

    getVerificationCode(data) {
        return request.get('/register/captcha',data)
    }

    userLogin(data) {
        return request.get('/api/v1/user/login',data)
    }

    walletLogin(data) {
        return request.post('/api/v1/user/loginByWallet',data)
    }

    submitForgetPassword(data) {
        return request.post("/api/v1/user/forgotPassword",data)
    }
}

export default new Api();
