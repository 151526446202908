import "../included_module.scss"
import PageHeader from "@/components/PageHeader";
import {useTranslation} from "react-i18next";
import {Button, Checkbox, Form, Input, DatePicker, Select, Spin} from 'antd';
import Upload from '@/components/Upload/index'
import { SearchOutlined } from '@ant-design/icons';
import {useSelector} from "react-redux";
import {useEffect, useState} from "react";
import Radio from '@/components/Radio/index'
import useSyncCallback from "../../../utils/useSyncCallback";
import commonApi from "../../../api/common";
import characterApi from "../../../api/character";
import message from "../../../utils/message";
import investorApi from "../../../api/investor";
import projectsApi from "../../../api/projects";
import {projectTypeObj} from "../../../enum/projectType";
import {setReview} from "../../../utils/middle";
import {useNavigate} from "react-router-dom";
import {peopleContract, playerContract, web3} from "../../../utils/web3Utils";
import {auditStatusObj} from "../../../enum/auditStatus";
import userApi from '@/api/user'
import {formatWithSeparator} from "../../../utils/formatUtils";
import Modal from '@/components/Modal/index'
import dayjs from 'dayjs'

function Character() {
    const {t} = useTranslation()
    const [form] = Form.useForm()
    const navigate = useNavigate()
    const regStr = new RegExp('[a-zA-Z0-9][-a-zA-Z0-9]{0,62}(\\.[a-zA-Z0-9][-a-zA-Z0-9]{0,62})+\\.?');
    const lang = useSelector(state => state.language.value)
    const baseUrl = process.env.REACT_APP_BASE_URL
    const color = useSelector(state => state.color.value)
    const media = useSelector(state => state.media.value)
    const inlineFormStyle = {
        width: '600px',
        marginRight: '102px'
    }
    const [tab,setTab] = useState('')
    const [tabList,setTabList] = useState([])
    const [ecology,setEcology] = useState('')
    const [ecologyList,setEcologyList] = useState([])
    const [mediaList,setMediaList]=useState([{webType: media[0].value,webUrl: ''}])
    const [investmentDatailList,setInvestmentDatailList] = useState([])
    const [schoolPage,setSchoolPage] = useState(1)
    const [schoolList,setSchoolList] = useState([])
    const [schoolName,setSchoolName] = useState('')
    const [schoolValue, setSchoolValue] = useState([])
    const [checkSchoolValue, setCheckSchoolValue] = useState([])
    const [schoolListData,setSchoolListData] = useState([])
    const [workList,setWorkList] = useState([{expId: '',post: '',time: '',name: '',url: ''}])
    const [iPage,setIPage] = useState(1)
    const [iList,setIList] = useState([])
    const [spinning, setSpinning] = useState(false)
    const [remarkModel, setRemarkModel] = useState(false)
    const investmentDataItem = {
        projectName: '',
        synopsis:'',
        projectId: '',
        logo: '',
        coinIssuanceSatus: '',
        label:'',
        round: '',
        amount: null,
        valuation: null,
        time: '',
        led:''
    }
    const [headImgUrl,setHeadImgUrl] = useState('')
    const [name, setName] = useState('')
    const [content,setContent] = useState('')
    const [projectPage,setProjectPage] = useState(1)
    const [projectOption, setProjectOption] = useState([])
    const [projectList, setProjectList] = useState([])
    const [invesList,setInvesList] = useState([])
    const [address, setAddress] = useState('')
    const [staked, setStaked] = useState(0)

    function labelNode(title,text) {
        return (
            <>
                <span className={'included_label_title'}>{title}</span>
                {text && (<span className={'included_label_text'}>{text}</span>)}
            </>
        )
    }

    function addTabs() {
        setTabList((e)=>{
            return [...e,tab]
        })
        setTab('')
    }

    function delTabs(index) {
        const list = [...tabList]
        list.map((item,key)=>{
            if (index === key){
                list.splice(index,1)
                setTabList(list)
            }
        })
    }

    function addMediaLink() {
        const data = {webType: media[0].value,webUrl: ''}
        const list = [...mediaList]
        list.push(data)
        setMediaList(list)
    }

    const getUniversity = useSyncCallback((e = void 0)=>{
        const data = {
            pageNum: schoolPage,
            pageSize: 10,
            name: e
        }
        commonApi.queryUniversityList(data).then(res=>{
            let school = res.rows.map(item=>{
                return {
                    label: lang.value == 0? item.enName:item.name,
                    value: item.id
                }
            })
            setSchoolList([...school])
            setSchoolListData([...res.rows])
        })
    })

    const addSchoolValue = useSyncCallback(()=>{
        console.log(schoolList)
        if(schoolList.length === 0) return
        let list = schoolListData.filter(item=>{
            for (const data of schoolValue) {
                if (item.id === data) return item
            }
        })
        setCheckSchoolValue([...list])
    })

    const delSchoolValue = useSyncCallback((id)=>{
        let list = schoolValue.filter(item=>item !== id)
        setSchoolValue([...list])
        addSchoolValue()
    })

    const addWorkExperience = useSyncCallback(()=>{
        setWorkList(e=>{
            let list = [...e]
            list.push({expId: '',post: '',time: '',name: '',url: ''})
            return [...list]
        })
    })

    const submitForm = useSyncCallback((type)=>{
        setSpinning(true)
        const investmentListDataMap = investmentDatailList.map(item=>{
            let timeObj = {}
            if (item.time) {
                timeObj = {...item}
                timeObj.time = timeObj.time.format('YYYY-MM-DD HH:mm:ss')
            }
            return timeObj
        })
        const data = {
            headImgUrl,name,content,educationExp: schoolValue.toString(),smcList: mediaList,investDetailsList: investmentListDataMap,lang: 0,submitType: type,remark: projectTypeObj.people.type,createTimeString: dayjs(Date.now()).format("YYYY-MM-DD HH:mm:ss")

        }
        if(type === 2) {
            setReview(data,(res)=>{
                console.log(res)
                setSpinning(false)
                navigate('/people/review?id='+res.data)
            })
        }else if(type === 1){
            if (staked < 100 ) {
                setSpinning(false)
                message.fail(t('NoIncluded'))
                return navigate("/user/wiki")
            }
            if (!data.headImgUrl || !data.name || !data.content || !data.content || data.smcList.length === 0) {
                setSpinning(false)
                return message.fail(t('completeInfo'))
            }
            for (const item of data.smcList) {
                if (!item.webType || !item.webUrl) {
                    setSpinning(false)
                    return message.fail(t('completeInfo'))
                }
            }
            characterApi.submitCharacterForm(data).then(res=>{
                message.success(t('saveSuccess'))
                const obj = res.data
                peopleContract.methods.createWiki(obj.mId,obj.cId,obj.lang,obj.qmHash).send({from: address}).then((res)=>{
                    if (res.status === 1n) {
                        commonApi.updateTransactionHash({
                            qmHash: obj.qmHash,
                            transactionHash: res.transactionHash
                        }).then(_=>{
                            userApi.updateAuditStatus({
                                projectId: obj.cId,
                                status: auditStatusObj["unaudited"].type
                            }).then(_=>{
                                message.success(t('saveSuccess'))
                                setSpinning(false)
                            }).catch(err=>{
                                setSpinning(false)
                            })
                        }).catch(err=>{
                            setSpinning(false)
                        })
                    }else {
                        message.fail(t('DenialAuthorization'))
                        setSpinning(false)
                    }
                }).catch(err=>{
                    message.fail(t('DenialAuthorization'))
                    characterApi.delCharacter(obj.cId)
                    commonApi.delExamineDataByQmHash(obj.qmHash)
                    setSpinning(false)
                })
            })
        }else {
            characterApi.submitCharacterForm(data).then(res=>{
                message.success(t('saveSuccess'))
                setSpinning(false)
            })
        }
    })

    const getProject = useSyncCallback((name = void 0)=>{
        console.log(name)
        projectsApi.queryProjectsList({
            pageNum: projectPage,
            pageSize: 10,
            lang: 0,
            sort: 0,
            type: 0,
            location: void 0,
            label: void 0,
            coinIssuanceSatus: void 0,
            financingStatus: void 0,
            name
        }).then(res=>{
            console.log(res)
            setProjectList([...res.rows])
            setProjectOption(()=>{
                return res.rows.map(item=>{
                    return {
                        label: item.name,
                        value: item.id
                    }
                })
            })
        })
    })

    const getInvestorList = useSyncCallback(()=>{
        investorApi.queryInvestorList({
            pageNum: iPage,
            pageSize: 20,
            lang: lang === 'zh'?1:0,
            type: 0,
            sort: 0,
            label:void 0,
            location: void 0,
            establishTime: void 0,
            name: void 0,
            timeFrame: void 0
        }).then(res=>{
            console.log(res)
            setInvesList(res.rows)
            setIList(e=>{
                let list = res.rows.map(item=>{
                    return {
                        label: item.name,
                        value: item.id
                    }
                })
                return [...list]
            })
        })
    })

    const changeInvestmentData = useSyncCallback((id,index)=>{
        console.log(id,index)
        projectsApi.queryProjectsDetail({
            id: id
        }).then(res=>{
            console.log(res)
            const {data: item} = res
            setInvestmentDatailList(e=> {
                let list = [...e]
                list[index].projectName = item.name
                list[index].coinIssuanceSatus = item.coinIssuanceSatus
                list[index].label = item.label
                list[index].synopsis = item.introduction
                list[index].projectId = item.id
                list[index].logo = item.logoUrl
                return [...list]
            })
        })
    })

    useEffect(()=>{
        if(window.ethereum === void 0) {
            message.fail(t("installMetaMask"))
        }else {
            web3.eth.getAccounts().then((res)=>{
                if (!res[0]) {
                    res = window.ethereum.request({method: 'eth_requestAccounts'})
                }
                setAddress(res[0])
                getUniversity()
                getInvestorList()
                playerContract.methods.getUserInfo(res[0]).call().then((res)=>{
                    setStaked(web3.utils.fromWei(res["_stakedAmount"],'ether'))
                })
            })
        }
    },[])

    function formFinish(e) {
        console.log(e)
        characterApi.queryCheckName(e.CharacterName).then(res=>{
            console.log(res)
            if(res.data === 0){
                submitForm(1)
            }else {
                setRemarkModel(true)
            }
        })
    }
    return (
        <>
            <div className={'main_back pb-60'}>
                <PageHeader other={true}></PageHeader>
                <div className={'included_title'}>{t('CharacterTitle')}</div>
                <div className={'included_text'}>{t('projectIncluedText')}</div>
                <div className={'included_form'}>
                    <Form layout="vertical" name="project" autoComplete="off" form={form} onFinish={formFinish}>
                        <Form.Item name="CharacterAvatar"
                                   label={labelNode(t('CharacterAvatar'), t('projectIncluedLogoText'))}
                                   rules={[{required: true}]}>
                            <Upload onSuccess={e => {
                                setHeadImgUrl(e.data)
                                form.setFieldsValue({CharacterAvatar: e.data})
                            }}>
                                {
                                    !headImgUrl && (
                                        <div className={'included_upload_logo'}>
                                            <img src={require("@/assets/image/included/upload_icon.png")} alt='upload'/>
                                        </div>
                                    )
                                }
                                {
                                    headImgUrl && (
                                        <img src={baseUrl + headImgUrl} className={'included_upload_logo'}/>
                                    )
                                }
                                <div className={'included_upload_logo_limit'}>{t('uploadImageLimit')}</div>
                            </Upload>
                        </Form.Item>
                        <Form.Item style={inlineFormStyle} name={'CharacterName'} label={labelNode(t('CharacterName'))}
                                   rules={[{required: true}]}>
                            <Input value={name} onChange={e => setName(e.target.value)}
                                   className={"included_form_input"} placeholder={t("CharacterNamePlaceholder")}/>
                        </Form.Item>
                        <Form.Item name={'BasicIntroduction'} label={labelNode(t('BasicIntroduction'))}
                                   rules={[{required: true}]}>
                            <Input.TextArea value={content} onChange={e => setContent(e.target.value)}
                                            className={'included_form_textarea included_form_input'}
                                            style={{height: 200, resize: 'none'}} maxLength={1500} showCount/>
                        </Form.Item>
                        <Form.Item name={'SocialMediaLinks'} label={labelNode(t('SocialMediaLinks'))}
                                   rules={[{required: true},({ getFieldValue }) => ({
                                       validator(_, value) {
                                           console.log('value',value)
                                           if (!regStr.test(value)) {
                                               return Promise.reject(new Error(t('linkFail')));
                                           };
                                           return Promise.resolve()
                                       },
                                   })]}>
                            <div>
                                {
                                    mediaList.map((item, index) => {
                                        return (
                                            <div key={index} className={'included_media_item'}
                                                 style={index !== 0 ? {marginTop: 12} : {}}>
                                                <Select value={item.webType} onChange={e => {
                                                    setMediaList(item => {
                                                        const list = [...item]
                                                        list[index].webType = e
                                                        return list
                                                    })
                                                }} defaultValue={item.webType} options={media}
                                                        style={{width: 177, height: 50}}/>
                                                <Input value={item.link} onChange={e => {
                                                    setMediaList(item => {
                                                        const list = [...item]
                                                        list[index].webUrl = e.target.value.replace(/\s/g, "")
                                                        return list
                                                    })
                                                }} className={"included_form_input included_media_link_input"}/>
                                                <div className='included_form_event_del'
                                                     style={{lineHeight: '50px'}}
                                                     onClick={() => setMediaList(e => {
                                                         return e.filter((item, key) => index !== key)
                                                     })}>{t('delete')}</div>
                                            </div>
                                        )
                                    })
                                }
                                <div className={'included_form_tag_add_button included_media_add'}
                                     onClick={addMediaLink}>
                                    <img src={require('../../../assets/image/included/add_icon.png')}/>
                                    {t('ContinueToAdd')}
                                </div>
                            </div>
                        </Form.Item>
                        <Form.Item name={'EducationalExperience'} label={labelNode(t('EducationalExperience'))}>
                            <div className={'included_form_tag_input'}>
                                <Select
                                    style={{width: 750}} mode="multiple" filterOption={false}
                                    className={'included_form_add_input included_form_input'}
                                    options={schoolList} onSearch={getUniversity} value={schoolValue} onChange={e => {
                                    setSchoolValue(e)
                                }} suffixIcon={
                                    <img src={require('@/assets/image/included/search_icon.png')}
                                         style={{width: '19px', height: '19px'}}/>
                                }
                                />
                                <div className={'included_form_tag_add_button'} onClick={addSchoolValue}>
                                    <img src={require('../../../assets/image/included/add_icon.png')}/>
                                    Add
                                </div>
                            </div>
                            <div className={'included_form_team_list'} style={{gap: 20}}>
                                {checkSchoolValue.map(item => {
                                    return (
                                        <div className={'included_form_study_list_item'}>
                                            <img src={baseUrl + item.url}
                                                 style={{width: 60, height: 60, borderRadius: '50%'}}
                                                 alt='logo'/>
                                            <div>{lang.value == 0?item.enName:item.name}</div>
                                            <img className={'included_tag_item_close'}
                                                 onClick={() => delSchoolValue(item.id)}
                                                 src={color === 'white' ? require('@/assets/image/included/close_white.png') : require("@/assets/image/included/close_dark.png")}/>
                                        </div>
                                    )
                                })}
                            </div>
                        </Form.Item>
                        <Form.Item>
                            <div className={'included_form_handler_button'}>
                                <div className={'included_form_handler_button_left'}>
                                    <div onClick={() => submitForm(2)}
                                         className={'included_form_handler_button_item included_form_handler_button_preview'}>{t('preview')}</div>
                                    <div onClick={() => submitForm(0)}
                                         className={'included_form_handler_button_item included_form_handler_button_SaveDraft'}>{t('SaveDraft')}</div>
                                </div>
                                <button htmlType="submit"
                                     className={'included_form_handler_button_item included_form_handler_button_SubmitInclusion'}>{t('SubmitInclusion')}</button>
                            </div>
                        </Form.Item>
                    </Form>

                </div>
            </div>
            <Modal open={remarkModel}>
                <div className='project_comment_dialog'>
                    <img onClick={() => setRemarkModel(false)} className={'project_comment_dialog_close'}
                         src={require('@/assets/image/home/dialog_close.png')} alt='close'/>
                    <div className='project_comment_dialog_title'>{t('remind')}</div>
                    <div className='project_comment_dialog_text' style={{color: '#FF2929'}}>{t('remind_text')}</div>
                    <div className='project_handle_btn'>
                        <div onClick={() => setRemarkModel(false)}>{t('close')}</div>
                        <div onClick={()=> {
                            setRemarkModel(false)
                            submitForm(1)
                        }} className='project_handle_btn_confirm'>{t('SubmitInclusion')}</div>
                    </div>
                </div>
            </Modal>
            <Spin tip="Loading" fullscreen spinning={spinning} size="large"/>
        </>
    )
}

export default Character;
