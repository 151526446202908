import './pagination_components.scss'
import {useTranslation} from "react-i18next";
import left from '@/assets/image/pagination/left.png'
import right from '@/assets/image/pagination/right.png'
import leftDark from '@/assets/image/pagination/leftDark.png'
import rightDark from '@/assets/image/pagination/rightDark.png'
import {useSelector} from "react-redux";
import { Pagination as Page } from 'antd';


function Pagination(props) {
    const {t} = useTranslation()
    const pageNumber = Math.ceil(props.total/props.size)
    const pageNumberList = []
    const color = useSelector(state => state.color.value)

    function itemRender(page, type, originalElement) {

        if (type === 'prev') {
            return (<div className={'pagination_handle_arrow_render'}><img className={'pagination_handle_arrow'} src={color === 'white' ? left : leftDark} alt='left'/></div>);
        }
        if (type === 'next') {
            return (<div className={'pagination_handle_arrow_render'}><img className={'pagination_handle_arrow'} src={color === 'white' ? right : rightDark} alt='right'/></div>);
        }
        if (type === 'page') {
            return (<div className={'pagination_handle_button'}>{page}</div>);
        }
        return originalElement;
    }

    function changePage(page) {
        props.onChange(page)
    }

    return (
        <>
            <Page showSizeChanger={false} total={props.total} pageSize={props.size} onChange={changePage}
                  showTotal={()=>(<div className={'pagination_handle_total'}>{t('total')} {props.total}</div>)}
                  itemRender={itemRender} showQuickJumper/>
        </>
    )
}

export default Pagination;
