import {Button, Input, Popover} from "antd";
import {useTranslation} from "react-i18next";
import './pageFooter_components.scss'
import {useSelector} from "react-redux";
import {Link} from "react-router-dom";
import {DownOutlined} from "@ant-design/icons";

function PageFooter() {
    const {t} = useTranslation()
    const color = useSelector(state => state.color.value)

    const content = (
        <div>
            <Link to={'/included/other/tool'}>
                <div className={'home_Popover_text'}>{t('Tool')}</div>
            </Link>
            <Link to={'/included/other/hackathon'}>
                <div className={'home_Popover_text'}>{t('Hackathon')}</div>
            </Link>
        </div>
    )
    return (
        <>
            <div style={{height: 458}}></div>
            <div className={'page_footer_block'}>
                {/*<div className={'page_footer_top'}>
                <div className={'page_footer_top_left'}>
                    <div>{t("join")} Binest</div>
                    <div>{t("join_text")}</div>
                </div>
                <div className={'page_footer_top_right'}>
                    <Input placeholder={t("emailInput")} id="page_footer_input"/>
                    <Button id="page_footer_button">{t("subscribe")}</Button>
                </div>
            </div>*/}
                <div className={'page_footer_bottom'}>
                    <div>
                        <img src={require("@/assets/image/home/home_bottom_logo_color.png")}
                             className={'page_bottom_logo'}
                             alt="logo"/>
                        <div className={'page_join_community'}>{t("joinCommunity")}</div>
                        <div className={'page_footer_english'}>
                            <div className={'page_footer_community_title'}>{t("englishCommunity")}</div>
                            <div className={'page_community_icon'}>
                                <img
                                    src={color === 'white' ? require("@/assets/image/home/community1_white.png") : require("@/assets/image/home/community1_dark.png")}
                                    alt='icon'/>
                                <img
                                    src={color === 'white' ? require("@/assets/image/home/twitter_white.png") : require("@/assets/image/home/twitter_dark.png")}
                                    alt='icon'/>
                                <img
                                    src={color === 'white' ? require("@/assets/image/home/community2_white.png") : require("@/assets/image/home/community2_dark.png")}
                                    alt='icon'/>
                                <img
                                    src={color === 'white' ? require("@/assets/image/home/YUTUBE_white.png") : require("@/assets/image/home/YUTUBE_dark.png")}
                                    alt='icon'/>
                                <img
                                    src={color === 'white' ? require("@/assets/image/home/M_white.png") : require("@/assets/image/home/M_dark.png")}
                                    alt='icon'/>
                                <img
                                    src={color === 'white' ? require("@/assets/image/home/F_white.png") : require("@/assets/image/home/F_dark.png")}
                                    alt='icon'/>
                                <img
                                    src={color === 'white' ? require("@/assets/image/home/in_white.png") : require("@/assets/image/home/in_dark.png")}
                                    alt='icon'/>
                            </div>
                        </div>
                        <div className={'page_footer_chinese'}>
                            <div className={'page_footer_community_title'}>{t("chineseCommunity")}</div>
                            <div className={'page_community_icon'}>
                                <img
                                    src={color === 'white' ? require("@/assets/image/home/weibo_white.png") : require("@/assets/image/home/weibo_dark.png")}
                                    alt='icon'/>
                                <img
                                    src={color === 'white' ? require("@/assets/image/home/tiktok_white.png") : require("@/assets/image/home/tiktok_dark.png")}
                                    alt='icon'/>
                                <img
                                    src={color === 'white' ? require("@/assets/image/home/wechat_white.png") : require("@/assets/image/home/wechat_dark.png")}
                                    alt='icon'/>
                            </div>
                        </div>
                        <div className={"page_bottom_copyright"}>Copyright @ 2024</div>
                    </div>
                    <div className={'page_footer_right'}>
                        <div className={"page_footer_entryInclusion"}>
                            <div className={'page_footer_right_title'}>{t("EntryInclusion")}</div>
                            <Link to={'/included/project'}>
                                <div className={'page_footer_right_text'}>{t("SubmitProject")}</div>
                            </Link>
                            <Link to={'/included/investor'}>
                                <div className={'page_footer_right_text'}>{t("Submitinvestor")}</div>
                            </Link>
                            <Link to={'/included/character'}>
                                <div className={'page_footer_right_text'}>{t("Submitcharacter")}</div>
                            </Link>
                            <Link to={'/included/meeting'}>
                                <div className={'page_footer_right_text'}>{t("Submitconference")}</div>
                            </Link>
                            <Link to={'/included/kol'}>
                                <div className={'page_footer_right_text'}>{t("SubmitKOL")}</div>
                            </Link>
                            <Popover placement="rightTop" content={content}>
                                <div className={'page_footer_right_text'}>
                                    {t("SubmitOthers")} <DownOutlined/>
                                </div>
                            </Popover>
                        </div>
                        <div className={"page_footer_about"}>
                            <div className={'page_footer_right_title'}>{t("About")}</div>
                            <div className={'page_footer_right_text'}>{t('Aboutus')}</div>
                            <div className={'page_footer_right_text'}>{t('Terms&Conditions')}</div>
                            <div className={'page_footer_right_text'}>{t('PrivacyPolicy')}</div>
                        </div>
                        <div>
                            <div className={'page_footer_right_title'}>{t("ContactUs")}</div>
                            <img src={require("@/assets/image/qrcode_logo.png")} className={'page_footer_qrcode'}
                                 alt='qrcode'/>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default PageFooter;
