import {Suspense, useEffect} from "react";
import {useLocation, useNavigate} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {walletEqualsStatus} from "../features/wallet/walletSlice";
import message from "../utils/message";
import Web3 from "web3";
import {useTranslation} from "react-i18next";
import {useAliveController} from "react-activation";
import {playerContract, web3} from "../utils/web3Utils";

const BeforeEach = (props) => {
    const {children} = props //拿到路由组件
    const navigate = useNavigate()
    const {t} = useTranslation()
    const dispatch = useDispatch()
    const route = useLocation()
    const user = useSelector(state => state.user.value)
    const { refreshScope,clear } = useAliveController()
    // 监听路由变化：route.pathname
    useEffect(() => {
        console.log("route/routerouteroute",route);
        window.scrollTo(0, 0)
        if (!route.pathname.includes('/review') && !route.pathname.includes('/included/')){
            if (!route.pathname.includes('/review') && !route.pathname.includes('/update/')) {
                if(route.pathname.includes('/update/') && route.search.includes('draft')) {
                    clear()
                }
            }
        }

        if(route.pathname.includes('/list') || route.pathname === "/more/tool" || route.pathname === "/more/hackathon") {
            clear()
        }
        if (!route.pathname.includes('detail') && route.pathname !== '/user/review'){
            console.log("User_Review")
            refreshScope("User_Review")
        }
        if (route.pathname === "/user/review") {
            const web3 = new Web3(window.ethereum)
            if(window.ethereum === void 0) {
                message.fail(t("installMetaMask"))
            }else {
                web3.eth.getAccounts().then((res)=>{
                    if (!res[0]) {
                        res = window.ethereum.request({method: 'eth_requestAccounts'})
                    }
                    playerContract.methods.getUserInfo(res[0]).call().then((res)=>{
                        console.log(res.isAuditor)
                        if (!res.isAuditor) {
                            navigate("/index",{replace: true})
                        }
                    })
                })
            }
        }

        if (user?.address && route.pathname !== '/login') {
            if(window.ethereum === void 0) {
                message.fail(t("installMetaMask"))
            }else {
                const web3 = new Web3(window.ethereum)
                web3.eth.getAccounts().then((res)=>{
                    if (res.length === 0) {
                        window.ethereum.request({ method: 'eth_requestAccounts' })
                    }else {
                        if (res[0].toUpperCase() === user.address.toUpperCase()){
                            dispatch(walletEqualsStatus(false))
                        }else {
                            dispatch(walletEqualsStatus(true))
                        }
                    }
                })
            }
        }

        console.log('route.pathname',route)
    }, [route.pathname])
    return (<>
        <Suspense fallback={<h1>loading...</h1>}>
            {children}
        </Suspense>
    </>)

}
export default BeforeEach
