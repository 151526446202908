import './people_detail_module.scss'
import {useParams, Link, useSearchParams, useNavigate, useLocation} from 'react-router-dom'
import PageHeader from "@/components/PageHeader";
import PageFooter from "@/components/PageFooter";
import RouterLine from "@/components/RouterLine";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";
import {Input, Dropdown, Tag, Spin} from 'antd'
import {useEffect, useState} from "react";
import Modal from '@/components/Modal'
import characterApi from "../../../api/character";
import useSyncCallback from "../../../utils/useSyncCallback";
import Comment from "../../../components/Comment";
import DetailHandle from "../../../components/DetailHandle";
import {projectTypeObj} from "../../../enum/projectType";
import CreateInfo from "../../../components/CreateInfo";
import commonApi from "../../../api/common";
import dayjs from 'dayjs'
import {getMediaIcon} from "../../../enum/mediaType";
import {check14Days} from "../../../utils/tools";
import {investorContract, peopleContract} from "../../../utils/web3Utils";
import message from "../../../utils/message";
import userApi from "@/api/user"

function Detail() {
    const navigate = useNavigate()
    const route = useLocation()
    const [searchParams,setSearchParams] = useSearchParams()
    const {id} = useParams()
    const { t } = useTranslation()
    const baseUrl = process.env.REACT_APP_BASE_URL
    const color = useSelector(state => state.color.value)
    const walletAddress = useSelector(state => state.walletAddress.value)
    const userInfo = useSelector(state => state.user.value)
    const lang = useSelector(state => state.language.value)
    const [isModalOpen,setIsModalOpen] = useState(false)
    const [updateModalOpen,setUpdateModalOpen] = useState(false)
    const [listType,setListType] = useState(1)
    const [overviewData,setOverviewData] = useState({})
    const [detailInfo,setDetailInfo] = useState({
        workExperienceList: []
    })
    const [expList, setExpList] = useState([])
    const [rightList, setRightList] = useState([])
    const [remarkModel, setRemarkModel] = useState(false)
    const [spinning, setSpinning] = useState(false)

    const items = [
        {
            key: '1',
            label: (
                <span className={'Dropdown_tag_text'}>All</span>
            ),
        },{
            key: '2',
            label: (
                <span className={'Dropdown_tag_text'}>with token</span>
            ),
        },{
            key: '3',
            label: (
                <span className={'Dropdown_tag_text'}>No token</span>
            ),
        }
    ]
    const [createInfo,setCreateInfo] = useState({})

    function setComment() {
        setIsModalOpen(false)
    }

    const getDetail = useSyncCallback(()=>{
        characterApi.queryCharacterDetail({id}).then((res)=>{
            setDetailInfo(res.data)
            getRightList()
            console.log(res.data.educationExp.split(','))
            if(res.data.educationExp) {
                res.data.educationExp.split(',').forEach(item=>{
                    commonApi.queryUniversityInfo({id: item}).then(res=>{
                        setExpList(e=>{
                            let list = {
                                id: item,
                                name: res.data.name,
                                url: res.data.url
                            }
                            return [...e,list]
                        })
                    })
                })
            }
            getCreateUserInfo()
        })
    })

    const getCreateUserInfo = useSyncCallback(()=>{
        commonApi.queryCreateUserInfo({createBy: detailInfo.createBy,projectId: detailInfo.id}).then(res=>{
            console.log('12334',res)
            setCreateInfo({...res.data})
        })
    })

    const getOverview = useSyncCallback(()=>{
        characterApi.queryCharacterOverview({
            id,
            lang: lang.value
        }).then(res=>{
            setOverviewData(res.data)
        })
    })

    const getAuditDetail = useSyncCallback(()=> {
        characterApi.queryCharacterDetailFromDB({
            id: id
        }).then((res)=>{
            setDetailInfo(res.data)
            // getRightList()
            if (searchParams.get("status") === "2") {
                if (userInfo.userId == res.data.updateBy || userInfo.userId == res.data.createBy){
                    setRemarkModel(true)
                }
            }
            if(res.data.educationExp) {
                res.data.educationExp.split(',').forEach(item=>{
                    commonApi.queryUniversityInfo({id: item}).then(res=>{
                        setExpList(e=>{
                            let list = {
                                id: item,
                                name: res.data.name,
                                url: res.data.url
                            }
                            return [...e,list]
                        })
                    })
                })
            }
            getCreateUserInfo()
        })
    })

    const getRightList = useSyncCallback(()=>{
        characterApi.queryRecommendPeople({id,lang: detailInfo.lang}).then(res=>{
            setRightList(res.data)
        })
    })

    const toGetClaim = useSyncCallback(()=>{
        setSpinning(true)
        peopleContract.methods.claimWiki(detailInfo.mainId,detailInfo.qmHash).send({from: walletAddress}).then((res)=>{
            if (res.status === 1n) {
                message.success(t('ClaimSuccess'))
                changeClaimStatus(detailInfo.qmHash)
            }else {
                message.fail(t('DenialAuthorization'))
            }
            setSpinning(false)
        }).catch((err)=>{
            message.fail(t('DenialAuthorization'))
            setSpinning(false)
        })
    })

    const changeClaimStatus = useSyncCallback((qmHash)=>{
        userApi.updateClaimStatus({
            qmHash,
            claimStatus: 0
        }).then(()=>{
            detailInfo.claimStatus = '0'
        })
    })

    const toWork = useSyncCallback((item)=>{
        console.log("item",item)
        if(item.workType === '1') {
            navigate(`/investors/detail/${item.inclusionId}`)
        }else {
            navigate(`/project/detail/${item.inclusionId}`)
        }
    })

    useEffect(()=>{
        if(searchParams.get('type') === 'audit' || (searchParams.get("status") === "2" || searchParams.get("status") === "0")) {
            getAuditDetail()
        }else {
            getDetail()
            getOverview()
        }
    },[route.pathname])
    return (
        <div className={'main_back main_back_white'}>
            <PageHeader other={true}></PageHeader>
            <div className={'people_detail_main_block'}>
                {
                    searchParams.get("type") === 'audit'?
                        (<div className={'router_line_block'}>
                            <img src={require('@/assets/image/project/router_arrow.png')} className='rotateY-180'
                                 alt='arrow'/>
                            <Link to={-1}>
                                <span className={'router_line_block_color'}>{t('back')}</span>
                            </Link>
                        </div>):(<RouterLine name={detailInfo.name}/>)
                }
                <div className={'people_detail_main'}>
                    <div className={'people_detail_main_left'}>
                        <div className={'people_detail_left_info'}>
                            <div className={'people_detail_left_info_content'}>
                                <img className={'people_detail_info_image'}
                                     src={baseUrl+detailInfo.headImgUrl} alt='Empty'/>
                                <div className={'people_detail_info_text'}>{detailInfo.name}</div>
                            </div>

                            {(detailInfo.mainId && !searchParams.get("type")) && <DetailHandle mainId={detailInfo.mainId} name={detailInfo.name}
                                                                inclusionType={projectTypeObj.people.type} lang={detailInfo.lang}
                                                                id={id}></DetailHandle>}
                        </div>
                        <div className={'people_detail_info_link_list'}>
                            {(check14Days(detailInfo.createTime) && detailInfo.createBy == userInfo.userId && detailInfo.claimStatus !== "0") && <Tag style={{cursor: "pointer"}} onClick={toGetClaim} color="success">{t("ClaimRewards")}</Tag>}
                            {
                                detailInfo.smcList?.length > 0 && detailInfo.smcList.map((item,index)=> {
                                    return (
                                        <div key={index} className={'people_detail_info_link'} onClick={()=> {
                                            let url = item.webUrl
                                            if (url.split("://")[0] === "http" || url.split("://")[0] === "https"){
                                                window.open(item.webUrl)
                                            }else {
                                                window.open("http://"+url.split("://")[0])
                                            }
                                        }}>
                                            <img alt='net'
                                                 src={getMediaIcon(item.webType,color)}/>
                                            {item.webType}
                                        </div>
                                    )
                                })
                            }
                        </div>
                        <div className={'people_detail_details'}>
                            <div className={'people_detail_details_title'}>{t('details')}</div>
                            <div className={'people_detail_details_content'}>{detailInfo.content}</div>
                        </div>
                        {detailInfo.workExperienceList.length > 0 && <div className={'people_detail_details'}>
                            <div className={'people_detail_details_title'}>{t('WorkExperience')}</div>
                            <div className={'people_detail_work_list'}>
                                {
                                    detailInfo.workExperienceList.map(item => {
                                        return (
                                            <div className={'people_detail_work_block'} onClick={()=>toWork(item)}>
                                                <img style={{borderRadius: '50%'}} src={baseUrl + item.purl} alt='icon'/>
                                                <div>
                                                    <div className={'people_detail_work_name'}>{item.remark}</div>
                                                    <div className={'people_detail_work_tag'}>{item.post}</div>
                                                    <div
                                                        className={'people_detail_work_time'}>{dayjs(item.time.split(',')[0]).format("YYYY-MM-DD")}-{!item.time.split(',')[1] ?'至今': dayjs(item.time.split(',')[1]).format('YYYY-MM-DD') === dayjs(Date.now()).format('YYYY-MM-DD')?'至今': dayjs(item.time.split(',')[1]).format('YYYY-MM-DD') }</div>
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        </div>}
                        <div className={'people_detail_details'}>
                            <div className={'people_detail_details_title'}>{t('EducationalExperience')}</div>
                            <div className={'people_form_team_list'}>
                                {
                                    expList.map(item => {
                                        return (
                                            <Link to={`/people/university/${item.id}`}>
                                                <div className={'people_detail_work_school_block'}>
                                                    <img src={baseUrl+item.url} style={{borderRadius: '50%'}} alt='icon'/>
                                                    {item.name}
                                                </div>
                                            </Link>
                                        )
                                    })
                                }
                            </div>
                        </div>
                        <div className={'people_detail_details'}>
                            <div className={'people_detail_details_title'}>{t('InvestmentOverview')}</div>
                            <div className={'people_overview_data'}>
                                <div className={'people_overview_data_1 people_overview_data_border'}>
                                    <div className={'people_overview_data_title'}>{t('RoundsInThePastYear')}</div>
                                    <div className={'people_overview_data_number'}>{overviewData.yearCount || 0}</div>
                                </div>
                                <div className={'people_overview_data_2 people_overview_data_border'}>
                                    <div className={'people_overview_data_title'}>{t('TotalRounds')}</div>
                                    <div className={'people_overview_data_number'}>{overviewData.count || 0}</div>
                                </div>
                                <div className={'people_overview_data_1 people_overview_data_border'}>
                                    <div className={'people_overview_data_title'}>{t('PortfolioNumbers')}</div>
                                    <div className={'people_overview_data_number'}>{overviewData.composeCount || 0}</div>
                                </div>
                                <div className={'people_overview_data_3'}>
                                    <div className={'people_overview_data_title'}>{t('LeadInvestment')}</div>
                                    <div className={'people_overview_data_number'}>{overviewData.historyCount || 0}</div>
                                </div>
                            </div>
                        </div>
                        <div className={'people_detail_details people_detail_details_investments'}>
                            <div className={'people_detail_details_title people_detail_details_top_handle'}>
                                {t('Investments')}
                                <div className={'people_detail_handle_btn'}>
                                    <div onClick={()=>setListType(1)} className={listType === 1?'people_detail_handle_portfolio':''}>{t('Portfolio2')}</div>
                                    <div onClick={()=>setListType(2)} className={listType === 2?'people_detail_handle_portfolio':''}>{t('Rounds')}</div>
                                </div>
                            </div>
                            <div className={'people_detail_tag_check'}>
                                <div>
                                    Tag
                                    <img src={require('../../../assets/image/investors/nocheck_icon.png')} alt='noCheck'/>
                                </div>
                                <Dropdown menu={{items}} trigger={['click']}>
                                    <div>
                                        All
                                        <img src={require('../../../assets/image/investors/check_icon.png')}
                                             alt='check'/>
                                    </div>
                                </Dropdown>
                            </div>
                            {
                                listType === 1 && (
                                    <div className={'investors_detail_investors_list'}>
                                        {
                                            detailInfo.investDetailsList?.map((item,index)=>{
                                                return (
                                                    <div className={'investors_table_item_block'}>
                                                        <div className={'investors_table_item_top'}>
                                                            <img src={baseUrl + item.logo} alt='logo'/>
                                                            <div className={'investors_table_item_text'}>
                                                                <div
                                                                    className={'investors_table_item_title'}>{item.projectName}</div>
                                                                <div
                                                                    className={'investors_table_item_content'}>{item.synopsis}</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                )
                            }
                            {
                                listType === 2 && (
                                    <div style={{marginTop: 50}} className={'people_overseas_table'}>
                                        <div className={'people_form_bottom_table'}>
                                            <div className={'people_form_bottom_table_head_line'}>
                                                <div className={'people_form_bottom_table_head_sp'}>
                                                    <img src={require("../../../assets/image/included/icon_name.png")}
                                                         alt='name'/>
                                                    {t('name')}
                                                </div>
                                                <div className={'people_form_bottom_table_head'}>
                                                    <img src={require("../../../assets/image/included/icon_round.png")}
                                                         alt='round'/>
                                                    {t('Round')}
                                                </div>
                                                <div className={'people_form_bottom_table_head'}>
                                                    <img src={require("../../../assets/image/included/icon_amount.png")}
                                                         alt='amount'/>
                                                    {t('amount')}
                                                </div>
                                                <div className={'people_form_bottom_table_head'}>
                                                    <img src={require("../../../assets/image/included/icon_valuation.png")}
                                                         alt='valuation'/>
                                                    {t('valuation')}
                                                </div>
                                                <div className={'people_form_bottom_table_head'}>
                                                    <img src={require("../../../assets/image/included/icon_time.png")}
                                                         alt='time'/>
                                                    {t('date')}
                                                </div>
                                                <div className={'people_form_bottom_table_head'}>
                                                    <img src={require("../../../assets/image/included/icon_investor.png")}
                                                         alt='investor'/>
                                                    {t('leadInvestor')}
                                                </div>
                                            </div>
                                            {
                                                detailInfo.investDetailsList?.map((item, index) => {
                                                    return (
                                                        <div className={'people_form_bottom_table_item_line'}
                                                             style={{marginBottom: 10}}>
                                                            <div className={'people_form_bottom_table_item'}>
                                                                <img className={'people_name_icon'} style={{borderRadius: '50%'}}
                                                                     src={baseUrl+item.logo}
                                                                     alt='project1'/>
                                                                <span>{item.projectName}</span>
                                                                <img className={'people_link_icon'}
                                                                     src={color === 'white' ? require('@/assets/image/project/link_icon.png') : require('@/assets/image/project/link_icon_dark.png')}
                                                                     alt='link'/>
                                                            </div>
                                                            <div
                                                                className={'people_form_bottom_table_item'}>{item.round}</div>
                                                            <div
                                                                className={'people_form_bottom_table_item'}>$ {item.amount}</div>
                                                            <div
                                                                className={'people_form_bottom_table_item'}>$ {item.valuation}</div>
                                                            <div
                                                                className={'people_form_bottom_table_item'}>{item.time}</div>
                                                            <div
                                                                className={'people_form_bottom_table_item'}>{item.led == '1'?'YES':item.led == '0'?'NO':'--'}</div>
                                                        </div>
                                                    )
                                                })
                                            }
                                        </div>
                                    </div>
                                )
                            }
                        </div>

                        {!searchParams.get("type") && <Comment id={id}></Comment>}
                    </div>
                    <div className={'people_detail_main_right'}>
                        <CreateInfo createInfo={createInfo} createBy={detailInfo.createBy} createTime={detailInfo.createTime}></CreateInfo>
                        <div className={'people_detail_contributor'} style={{marginTop: 20}}>
                            <div className={'people_detail_top'} style={{marginBottom: 10}}>
                                <span>{t('Cooperations')}</span>
                            </div>
                            {
                                rightList.map(item=>{
                                    return (
                                        <div style={{cursor: 'pointer'}} onClick={()=>{
                                            navigate(`/people/detail/${item.id}`)
                                            getDetail()
                                        }}>
                                            <div className={'people_contributor_project'}>
                                                <img src={baseUrl + item.headImgUrl} alt='logo'/>
                                                <div>
                                                    <div className={'people_contributor_project_title'}>{item.name}</div>
                                                    <div className={'people_contributor_project_text'}>{item.content}</div>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })

                            }
                        </div>
                    </div>
                </div>
            </div>
            <PageFooter></PageFooter>
            <Modal open={isModalOpen}>
                <div className={'people_comment_dialog'}>
                    <img onClick={() => setIsModalOpen(false)} className={'people_comment_dialog_close'}
                         src={require('../../../assets/image/home/dialog_close.png')} alt='close'/>
                    <div className={'people_comment_dialog_title'}>{t('comment')}</div>
                    <Input.TextArea placeholder={t('dialogPlaceholder')} className={'people_textarea'} autoSize={{ minRows: 6, maxRows: 6 }}></Input.TextArea>
                    <div className={'people_handle_btn'}>
                        <div onClick={()=>setIsModalOpen(false)}>{t('cancle')}</div>
                        <div onClick={setComment} className={'people_handle_btn_confirm'}>{t('confirm')}</div>
                    </div>
                </div>
            </Modal>
            <Modal open={updateModalOpen}>
                <div className={'people_comment_dialog'}>
                    <img onClick={() => setUpdateModalOpen(false)} className={'people_comment_dialog_close'}
                         src={require('../../../assets/image/home/dialog_close.png')} alt='close'/>
                    <div className={'people_comment_dialog_title'}>{t('update')}</div>
                    <div className={'people_detail_update_block'}>
                        <div className={'people_detail_update_small_title'}>{t('updateSmallTitle')}</div>
                        <div className={'people_detail_update_btn_list'}>
                            <Link to={`/people/update/${id}`}>
                                <div>{t('updateContent')}</div>
                            </Link>
                            <div>{t('updateLanguage')}</div>
                            <div>{t('InitiateVoting')}</div>
                        </div>
                        <div className={'people_detail_update_text'}>{t('updateText')}</div>
                    </div>
                </div>
            </Modal>
            <Modal open={remarkModel}>
                <div className='project_comment_dialog'>
                    <img onClick={() => setRemarkModel(false)} className={'project_comment_dialog_close'}
                         src={require('@/assets/image/home/dialog_close.png')} alt='close'/>
                    <div className='project_comment_dialog_title'>{t('ReviewOpinions')}</div>
                    <div className='project_comment_dialog_text' style={{color: '#FF2929'}}>{detailInfo.examineReport}</div>
                    <div className='project_handle_btn'>
                        <div onClick={() => setRemarkModel(false)}>{t('close')}</div>
                        <Link to={`/people/update/${id}?type=audit&update=1`}>
                            <div className='project_handle_btn_confirm'>{t('toUpdate')}</div>
                        </Link>
                    </div>
                </div>
            </Modal>
            <Spin tip="Loading" fullscreen spinning={spinning} size="large"/>
        </div>
    )
}

export default Detail;
