import '../user_module.scss'
import {useTranslation} from "react-i18next";
import {Input} from "antd";
import {useRef, useState} from "react";
import {useSelector, useDispatch} from "react-redux";
import message from "../../../utils/message";
import Web3 from "web3";
import userApi from '@/api/user'
import {setUserInfo} from "../../../features/user/userSlice";
import useSyncCallback from "../../../utils/useSyncCallback";

function Account() {
    const dispatch = useDispatch()
    const {t} = useTranslation()
    const userInfo = useSelector(state => state.user.value)
    const [googleVerify,setGoogleVerify] = useState(false)
    const [connect,setConnect] = useState(false)
    const [address,setAddress] = useState(userInfo.address)
    const [key, setKey] = useState(null)
    const [input1, setInput1] = useState(null)
    const [input2, setInput2] = useState(null)
    const [input3, setInput3] = useState(null)
    const [input4, setInput4] = useState(null)
    const [input5, setInput5] = useState(null)
    const [input6, setInput6] = useState(null)
    const inputRef1 = useRef();
    const inputRef2 = useRef();
    const inputRef3 = useRef();
    const inputRef4 = useRef();
    const inputRef5 = useRef();
    const inputRef6 = useRef();

    function linkWallet() {
        if(window.ethereum === void 0) {
            message.fail(t("installMetaMask"))
        }else {
            setConnect(true)
            const web3 = new Web3(window.ethereum)

            window.ethereum.request({ method: 'eth_requestAccounts' }).then(res=>{
                setAddress(res[0])
                const data = {
                    userId: userInfo.userId,
                    address
                }
                userApi.updateUserInfo(data).then(_r=>{
                    getUserInfo()
                })
                console.log(data)
            }).catch(err=>{
                message.fail(err.message)
                setConnect(false)
            })
        }
    }

    function getUserInfo() {
        userApi.queryUserInfo().then(res=>{
            console.log(res)
            dispatch(setUserInfo(res.data))
        })
    }
    const bindGoogle = useSyncCallback(()=>{
        userApi.sendGoogleCode().then(res=>{
            setKey(res.key)
            setGoogleVerify(true)
            getFocus(1)
        })
    })

    const getFocus = useSyncCallback((val)=>{
        console.log(inputRef1.current)
        switch (val) {
            case 1:
                inputRef1.current.focus({cursor: 'start'});
                break;
            case 2:
                if (input1){
                    inputRef2.current.focus({cursor: 'start'});
                }
                break;
            case 3:
                if (input2){
                    inputRef3.current.focus({cursor: 'start'});
                }
                break;
            case 4:
                if (input3){
                    inputRef4.current.focus({cursor: 'start'});
                }
                break;
            case 5:
                if (input4){
                    inputRef5.current.focus({cursor: 'start'});
                }
                break;
            case 6:
                if (input5){
                    inputRef6.current.focus({cursor: 'start'});
                }
                break;
        }
    })

    const verifyCode = useSyncCallback(()=>{
        if (!input1 || !input2 || !input3 || !input4 || !input5 || !input6){
            return
        }
        const data = {
            secret: key,
            code: input1+input2+input3+input4+input5+input6
        }
        userApi.verifyGoogleCode(data).then(res=>{
            message.success(t('saveSuccess'))
            setKey(null)
            setInput1(null)
            setInput2(null)
            setInput3(null)
            setInput4(null)
            setInput5(null)
            setInput6(null)
            setGoogleVerify(false)
        })
    })

    return (
        <div className={'profile_main_block'}>
            <div className={'profile_main_block_title'}>{t('AccountManagement')}</div>
            {
                googleVerify && (
                    <div>
                        <div className={'account_google_ver_block'}>
                            <div className={'account_google_ver_block_title'}>Two Factor Authentication</div>
                            <div className={'account_google_ver_block_text'}>Use Google Authenticator app for Android and
                                iOS to protect your account.
                            </div>
                        </div>
                        <div className={'account_google_ver_num_block'}>
                            <div className={'account_google_ver_num_block_title'}>Disable Google authenticator</div>
                            <div className={'account_google_ver_num_block_text'}>Google 2FA Authenticator enabled.</div>
                            <div className={'account_google_ver_num_block_text'}>Enter google authenticator 6 digit code
                                to disable:
                            </div>
                            <div className={'account_google_ver_num_input'}>
                                <Input ref={inputRef1} value={input1} onChange={e=>{
                                    setInput1(e.target.value)
                                    getFocus(2)
                                    verifyCode()
                                }} onPressEnter={verifyCode} maxLength={1} className={'account_ver_num_input included_form_input'}/>
                                <Input ref={inputRef2} value={input2} onChange={e=>{
                                    setInput2(e.target.value)
                                    getFocus(3)
                                    verifyCode()
                                }} onPressEnter={verifyCode} maxLength={1} className={'account_ver_num_input included_form_input'}/>
                                <Input ref={inputRef3} value={input3} onChange={e=>{
                                    setInput3(e.target.value)
                                    getFocus(4)
                                    verifyCode()
                                }} onPressEnter={verifyCode} maxLength={1} className={'account_ver_num_input included_form_input'}/>
                                <div className={'account_google_ver_num_line'}></div>
                                <Input ref={inputRef4} value={input4} onChange={e=>{
                                    setInput4(e.target.value)
                                    getFocus(5)
                                    verifyCode()
                                }} onPressEnter={verifyCode} maxLength={1} className={'account_ver_num_input included_form_input'}/>
                                <Input ref={inputRef5} value={input5} onChange={e=>{
                                    setInput5(e.target.value)
                                    getFocus(6)
                                    verifyCode()
                                }} onPressEnter={verifyCode} maxLength={1} className={'account_ver_num_input included_form_input'}/>
                                <Input ref={inputRef6} value={input6} onChange={e=>{
                                    setInput6(e.target.value)
                                    inputRef6.current.blur()
                                    verifyCode()
                                }} onPressEnter={verifyCode} maxLength={1} className={'account_ver_num_input included_form_input'}/>
                            </div>
                            <div className={'account_google_ver_num_btn'} onClick={()=>setGoogleVerify(false)}>Disable</div>
                        </div>
                    </div>
                )
            }
            {
            !googleVerify && (
                    <div>
                        <div className={'account_email_address_block'}>
                            <div className={'account_email_address_title'}>{t('EmailAddress')}</div>
                            <div className={'account_email_address'}>{userInfo.email}</div>
                        </div>
                        <div className={'profile_main_nickname'}>
                            <div className={'profile_main_nickname_title'}>{t('wallet')}<span>*</span></div>
                            <Input value={address} disabled onChange={e=>setAddress(e.target.value)} placeholder={t('WalletAddress')}
                                   className={'profile_main_nickname_input included_form_input'}/>
                        </div>
                        <div className={'account_main_link_btn'}>
                            <div className={'account_main_link_wallet'} onClick={linkWallet}>
                                {t('LinkWallet')}
                                <img src={require('../../../assets/image/user/link_icon.png')} alt='link'/>
                            </div>
                            <div className={'account_main_link_google'} onClick={bindGoogle}>
                                Bind Google Authenticator
                                <img src={require('../../../assets/image/user/google_icon.png')} alt='link'/>
                            </div>
                        </div>
                        <div className={'account_main_save'} onClick={linkWallet}>{t('save')}</div>
                    </div>
                )
            }
        </div>
    )
}

export default Account;
