import '../user_module.scss'
import Pagination from "../../../components/Pagination";
import {Link} from "react-router-dom";
import {checkDetailPage, projectTypeIndex} from "../../../enum/projectType";
import {useTranslation} from "react-i18next";

function Project(props) {
    const baseUrl = process.env.REACT_APP_BASE_URL
    const {t} = useTranslation()
    const list = props.list || []
    return (
        <div className={'collection_list_block'}>
            <div className={'collection_list_grid_3_small'}>
                {
                    list.map((item, index) => {
                        console.log("12235252",item)
                        return (
                            <Link to={item.status === "0"?`${checkDetailPage(item.type,item.id)}?type=audit&status=${item.status}`:checkDetailPage(item.type,item.id)+`?status=${item.status}`}>
                                <div className={'project_table_item collection_project_table'}>
                                    {item.status === "0" && <img className={'submission_audit_image'}
                                                                 src={require('../../../assets/image/user/audit.png')}
                                                                 alt='audit'/>}
                                    {item.status === "1" && <img className={'submission_audit_image'}
                                                                 src={require('../../../assets/image/user/collecteo.png')}
                                                                 alt='audit'/>}
                                    {item.status === "2" && <img className={'submission_audit_image'}
                                                                 src={require('../../../assets/image/user/peview.png')}
                                                                 alt='audit'/>}
                                    <div className={'project_table_item_top'}>
                                        <img src={baseUrl + item.url} alt='logo'/>
                                        <div className={'project_table_item_text'}>
                                            <div className={'project_table_item_title'}>{item.name}</div>
                                            <div
                                                className={'project_table_item_content'}>{item.type ? t(projectTypeIndex[item.type]) : ''}</div>
                                            <div className={'project_table_item_content'}>{item.content}</div>
                                        </div>
                                    </div>
                                </div>
                            </Link>
                        )
                    })
                }
            </div>
        </div>
    )
}

export default Project;
