import './project_detail_module.scss'
import {useParams, Link, useSearchParams} from 'react-router-dom'
import PageHeader from "@/components/PageHeader";
import PageFooter from "@/components/PageFooter";
import RouterLine from "@/components/RouterLine";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";
import {DatePicker, Input, Tooltip} from 'antd'
import TimeLine from "@/components/TimeLine";
import {useEffect, useState} from "react";
import Modal from '@/components/Modal'
import useSyncCallback from "../../../utils/useSyncCallback";
import projectsApi from "../../../api/projects";
import dayjs from "dayjs";
import Comment from "../../../components/Comment";
import commonApi from '../../../api/common'
import {projectTypeObj} from "../../../enum/projectType";
import message from '../../../utils/message'
import DetailHandle from "../../../components/DetailHandle";
import {getReview} from "../../../utils/middle";
import {getMediaIcon} from "../../../enum/mediaType";
import {formatMillion} from "../../../utils/formatUtils";

function Review() {
    const [searchParams,setSearchParams] = useSearchParams()
    const baseUrl = process.env.REACT_APP_BASE_URL
    const { t } = useTranslation()
    const color = useSelector(state => state.color.value)
    const userInfo = useSelector(state => state.user.value)
    const [keyEventList,setKeyEventList] = useState([])
    const [detailInfo, setDetailInfo] = useState({
        eventList: [],
        foreignInvestList: [],
        workExperienceList: [],
        projectInvestDetailsList: []
    })
    const [pageNum, setPageNum] = useState(1)

    const getDetail = useSyncCallback(()=> {
        getReview(searchParams.get('id'),(res)=>{
            setDetailInfo({...res.data})
        })
    })
    const getRecommendList = useSyncCallback(()=>{
        projectsApi.queryProjectRecommend({
            label: 'a',
            name: detailInfo.name,
            lang: detailInfo.lang
        }).then(res=>{
            console.log('res',res)
        })
    })

    const ledListDom = (item)=>{
        console.log("itemtmetmeemtemtemt0",item)
            let ledList = item.ledInstitution.split(",")
        console.log('ledList',ledList)
                return ledList.map(item=>{
                console.log("detailInfo.ledCheckList",detailInfo.ledCheckList)
                console.log("detailInfo.ledCheckList item",item)
                let info = detailInfo.ledCheckList.find(params=>params.value === item)
                console.log("infoffsikodgdogoasdg",info)
                return info?(<div
                    className={'project_investor_leadInvestor_item'}>
                    <img
                        className={'project_investor_leadInvestor_image'}
                        src={baseUrl + info.logo} alt='icon'/>
                    <span>{info?.label}</span>
                </div>):(<></>)
            })
    }

    const invListDom = (item)=>{
        let ledList = item.investor.split(",").filter(params => {
            return item.ledInstitution.indexOf(params) === -1
        })
        return ledList.map(item=>{
            console.log("detailInfo.ledCheckList",detailInfo.ledCheckList)
            console.log("detailInfo.ledCheckList item",item)
            let info = detailInfo.ledCheckList.find(params=>params.value === item)
            console.log("infoffsikodgdogoasdg",info)
            return (<div
                className={'project_investor_leadInvestor_item'}>
                <img
                    className={'project_investor_leadInvestor_image'}
                    src={baseUrl + info.logo} alt='icon'/>
                <span>{info?.label}</span>
            </div>)
        })
    }

    useEffect(()=>{
        getDetail()
    },[])


    return (
        <div className={'main_back main_back_white'}>
            <PageHeader other={true}></PageHeader>
            <div className={'project_detail_main_block'}>
                <div className={'router_line_block'}>
                    <img src={require('@/assets/image/project/router_arrow.png')} className='rotateY-180'
                         alt='arrow'/>
                    <Link to={-1}>
                        <span className={'router_line_block_color'}>{t('back')}</span>
                    </Link>
                </div>

                <div className={'project_detail_main'}>
                    <div className={'project_detail_main_left'}>
                        <div className={'project_detail_left_info'}>
                            <div className={'project_detail_left_info_content'}>
                                <img className={'project_detail_info_image'}
                                     src={baseUrl + detailInfo.logoUrl} alt='Empty'/>
                                <div className={'project_detail_info_text'}>
                                    <div className={'project_detail_info_name'}>
                                        {detailInfo.name}<span>{detailInfo.tokenAbbreviation ? `（${detailInfo.tokenAbbreviation}）` : ''}</span>
                                        <div className={'project_detail_info_running'}>
                                            <img src={require('@/assets/image/project/running.png')} alt='running'/>
                                            {t('running')}
                                        </div>
                                    </div>
                                    <div className={'project_detail_info_context'}>{detailInfo.introduction}</div>
                                    <div className={'project_detail_info_tag'}>
                                        {
                                            detailInfo.label && detailInfo.label.split(',').map((item, index) => {
                                                return (
                                                    <div key={index} className={'project_detail_info_tag_item'}>
                                                        <img
                                                            src={color === 'white' ? require('@/assets/image/project/tool.png') : require('@/assets/image/project/tool_dark.png')}
                                                            alt='tool'/>
                                                        {item}
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={'project_detail_info_link_list'}>
                            {detailInfo.officialWebsite && <div className={'project_detail_info_link'} onClick={() => {
                                let url = detailInfo.officialWebsite
                                if (url.split("://")[0] === "http" || url.split("://")[0] === "https") {
                                    window.open(detailInfo.officialWebsite)
                                } else {
                                    window.open("http://" + url.split("://")[0])
                                }
                            }}>
                                <img src={getMediaIcon("Website", color)}/>
                                Website
                            </div>}
                            {
                                detailInfo.smcList?.length > 0 && detailInfo.smcList.map((item, index) => {
                                    return (item.webUrl &&
                                        <div key={index} className={'project_detail_info_link'} onClick={() => {
                                            let url = item.webUrl
                                            if (url.split("://")[0] === "http" || url.split("://")[0] === "https") {
                                                window.open(item.webUrl)
                                            } else {
                                                window.open("http://" + url.split("://")[0])
                                            }
                                        }}>
                                            <img alt='net' src={getMediaIcon(item.webType, color)}/>
                                            {item.webType}
                                        </div>
                                    )
                                })
                            }
                            {detailInfo.whitePaperLink && <div className={'project_detail_info_link'}
                                                               onClick={() => window.open(detailInfo.whitePaperLink)}>
                                <img
                                    src={color === 'white' ? require('@/assets/image/project/whitePaper.png') : require('@/assets/image/project/whitePaper_dark.png')}/>
                                White Paper
                            </div>}
                            {detailInfo.companyName &&
                                <Tooltip overlayInnerStyle={{color: '#262626', fontSize: 12, fontWeight: 500}}
                                         color={color === 'white' ? '#C8C8C8' : '#9DA3AE'} placement="bottomLeft"
                                         title={`Audit Report From ${detailInfo.companyName}`}>
                                    <div className={'project_detail_info_link'} onClick={() => {
                                        window.open(detailInfo.auditReportUrl)
                                    }}>
                                        <img src={baseUrl + detailInfo.companyLogo} alt='audit1'/>
                                        {detailInfo.companyName}
                                    </div>
                                </Tooltip>}
                        </div>
                        <div className={'project_detail_text_info'}>
                            <div><span>{t('founded')}: </span>{dayjs(detailInfo.establishTime).format('YYYY')}</div>
                            {detailInfo.location !== "unknown" && detailInfo.location !== "不详" && detailInfo.location ? (
                                <div
                                    style={{marginLeft: 75, cursor: 'pointer'}}>
                                    <span>{t('location')}: </span>{detailInfo.location}</div>) : (<></>)}
                            <div><span>{t('ecosystem')}: </span>{detailInfo.ecology}</div>
                            {detailInfo.publicOfferingPrice && (
                                <div><span>{t('offerPrice')} </span>${detailInfo.publicOfferingPrice}</div>)}
                        </div>
                        {detailInfo.content && (
                            <div className={'project_detail_details'}>
                                <div className={'project_detail_details_title'}>{t('details')}</div>
                                <div className={'project_detail_details_content'}>{detailInfo.content}</div>
                            </div>)}
                        {
                            detailInfo.workExperienceList && <div className={'project_detail_details'}>
                                <div className={'project_detail_details_title'}>{t('TeamMembers')}</div>
                                <div className={'project_form_team_list'}>
                                    {
                                        detailInfo.workExperienceList?.map((item, index) => {
                                            return (
                                                <Link to={`/people/detail/${item.expId}`}>
                                                    <div key={index} className={'project_form_team_list_item'}>
                                                        <img style={{width: 60, height: 60, borderRadius: '50%'}}
                                                             src={baseUrl + item.url}
                                                             alt='logo'/>
                                                        <div>
                                                            <div
                                                                className={'project_form_team_list_item_name'}>{item.name}</div>
                                                            <div>{item.post}</div>
                                                        </div>
                                                        <img className={'project_form_team_list_item_media'}
                                                             src={require('../../../assets/image/included/media_logo.png')}
                                                             alt='logo'/>
                                                    </div>
                                                </Link>
                                            )
                                        })
                                    }
                                </div>
                            </div>
                        }
                        {
                            detailInfo.eventList && (<div className={'project_detail_details'}>
                                <div className={'project_detail_details_title'}>{t('KeyEvents')}</div>
                                <TimeLine list={detailInfo.eventList}/>
                            </div>)
                        }
                        {detailInfo.projectInvestDetailsList && (
                            <div className={'project_detail_details'}>
                                <div className={'project_detail_details_title'}
                                     style={{marginBottom: 48}}>{t('investor')}</div>
                                {

                                    detailInfo.projectInvestDetailsList.map(item => {
                                        console.log('item', item)
                                        return (
                                            <div className={'project_investor_block'}>
                                                <div className={'project_investor_block_left'}>
                                                    <div className={'project_investor_block_left_time'}>
                                                        <img
                                                            src={require('../../../assets/image/project/investor_icon.png')}
                                                            alt='icon'/>{item.time}
                                                    </div>
                                                    <div
                                                        className={'project_investor_block_left_price'}>$ {item.amount ? formatMillion(item.amount) : item.amount}</div>
                                                    <div
                                                        className={'project_investor_block_left_series'}>{t("valuation")}：$ {item.valuation ? formatMillion(item.valuation) : item.valuation}</div>

                                                    <div className={'project_investor_block_left_series'}>{item.round}</div>
                                                    <div className={'project_investor_block_left_link'} onClick={() => {
                                                        let url = item.link
                                                        if (url.split("://")[0] === "http" || url.split("://")[0] === "https") {
                                                            window.open(item.link)
                                                        } else {
                                                            window.open("http://" + url.split("://")[0])
                                                        }
                                                    }}>
                                                        <img
                                                            src={require('@/assets/image/project/investor_link_icon.png')}
                                                            alt='link'/>
                                                        {t('link')}
                                                    </div>
                                                    <div className={'project_investor_block_left_line'}></div>
                                                </div>
                                                <div className={'project_investor_block_right'}>
                                                    {item.ledInstitution?<div>
                                                        <div>{t('leadInvestor')}</div>
                                                        <div className={'project_investor_leadInvestor_list'}>
                                                            {
                                                                ledListDom(item)
                                                            }
                                                        </div>
                                                    </div>:(<></>)}
                                                    {item.investor?<div>
                                                        <div
                                                            className={'project_investor_block_right_bottom'}>{t('investor')}</div>
                                                        <div className={'project_investor_leadInvestor_list'}>
                                                            {invListDom(item)}
                                                        </div>
                                                    </div>:(<></>)}
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        )}
                        {
                            (detailInfo.foreignInvestList && detailInfo.foreignInvestList.length > 0)  && (
                                <div className={'project_detail_details'}>
                                    <div className={'project_detail_details_title'}
                                         style={{marginBottom: 50}}>{t('overseasInvestment')}</div>
                                    <div className={'project_overseas_table'}>
                                        <div className={'project_form_bottom_table'}>
                                            <div className={'project_form_bottom_table_head_line'}>
                                                <div className={'project_form_bottom_table_head_sp'}>
                                                    <img src={require("../../../assets/image/included/icon_name.png")}
                                                         alt='name'/>
                                                    {t('name')}
                                                </div>
                                                <div className={'project_form_bottom_table_head'}>
                                                    <img src={require("../../../assets/image/included/icon_round.png")}
                                                         alt='round'/>
                                                    {t('Round')}
                                                </div>
                                                <div className={'project_form_bottom_table_head'}>
                                                    <img src={require("../../../assets/image/included/icon_amount.png")}
                                                         alt='amount'/>
                                                    {t('amount')}
                                                </div>
                                                <div className={'project_form_bottom_table_head'}>
                                                    <img src={require("../../../assets/image/included/icon_valuation.png")}
                                                         alt='valuation'/>
                                                    {t('valuation')}
                                                </div>
                                                <div className={'project_form_bottom_table_head'}>
                                                    <img src={require("../../../assets/image/included/icon_time.png")}
                                                         alt='time'/>
                                                    {t('date')}
                                                </div>
                                                <div className={'project_form_bottom_table_head'}>
                                                    <img src={require("../../../assets/image/included/icon_investor.png")}
                                                         alt='investor'/>
                                                    {t('leadInvestor')}
                                                </div>
                                            </div>
                                            {
                                                detailInfo.foreignInvestList.map((item, index) => {
                                                    return (
                                                        <div className={'project_form_bottom_table_item_line'}
                                                             style={{marginBottom: 10}}>
                                                            <div className={'project_form_bottom_table_item'}>
                                                                <img className={'project_name_icon'}
                                                                     src={require('@/assets/image/project/project1.png')}
                                                                     alt='project1'/>
                                                                <span>{item.projectName}</span>
                                                                <img className={'project_link_icon'}
                                                                     src={color === 'white' ? require('@/assets/image/project/link_icon.png') : require('@/assets/image/project/link_icon_dark.png')}
                                                                     alt='link'/>
                                                            </div>
                                                            <div className={'project_form_bottom_table_item'}>{item.round}</div>
                                                            <div
                                                                className={'project_form_bottom_table_item'}>$ {item.amount ? formatMillion(item.amount) : item.amount}</div>
                                                            <div
                                                                className={'project_form_bottom_table_item'}>$ {item.valuation?formatMillion(item.valuation):item.valuation}</div>
                                                            <div className={'project_form_bottom_table_item'}>{item.time}</div>
                                                            <div className={'project_form_bottom_table_item'}>{item.ledStatus === "1"?t('YES'):t('NO')}</div>
                                                        </div>
                                                    )
                                                })
                                            }
                                        </div>
                                    </div>
                                </div>
                            )
                        }
                    </div>
                </div>
            </div>
            <PageFooter></PageFooter>
        </div>
    )
}

export default Review;
