import { createSlice } from '@reduxjs/toolkit'
import zhCN from 'antd/locale/zh_CN';
import enUS from 'antd/locale/en_US'

export const langSlice = createSlice({
    name: 'language',
    initialState: {
        value: sessionStorage.getItem('lang') === 'zh'?{code: 'zh_CN', text: '中文', File: zhCN, value: 1}: {code: 'en_US', text: 'En', File: enUS, value: 0}
},
    reducers: {
        english: state => {
            state.value = {
                code: 'en_US',
                text: 'En',
                File: enUS,
                value: 0
            }
            sessionStorage.setItem('lang','en')
            window.location.reload()
        },
        chinese: state => {
            state.value = {
                code: 'zh_CN',
                text: '中文',
                File: zhCN,
                value: 1
            }
            sessionStorage.setItem('lang','zh')
            window.location.reload()
        },
        languageByAction: (state, action) => {
            state.value = action.payload
        }
    }
})

export const { english,chinese,languageByAction } = langSlice.actions

export default langSlice.reducer
