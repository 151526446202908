import './more_module.scss'
import PageHeader from "../../components/PageHeader";
import PageFooter from "../../components/PageFooter";
import {useTranslation} from "react-i18next";
import {Link, Outlet, useLocation} from "react-router-dom";

function More() {
    const {t} = useTranslation()
    const location = useLocation()
    return (
        <div className={'main_back'}>
            <PageHeader other={true}></PageHeader>
            <div className={'more_main_block'}>
                <div className={'more_main_block_left'}>
                    <Link to={'/more/tool'}>
                        <div className={location.pathname === '/more/tool'?'more_main_block_check_left':''}>{t('Tool')}</div>
                    </Link>
                    <Link to={'/more/hackathon'}>
                        <div className={location.pathname === '/more/hackathon'?'more_main_block_check_left':''}>{t('Hackathon')}</div>
                    </Link>
                    <Link to={'/more/soon'}>
                        <div className={location.pathname === '/more/soon'?'more_main_block_check_left':''}>So On</div>
                    </Link>
                </div>
                <div className={'more_main_block_right'}>
                    <Outlet />
                </div>
            </div>
            <PageFooter></PageFooter>
        </div>
    )
}

export default More;
