import './kol_detail_module.scss'
import {useParams, Link, useSearchParams} from 'react-router-dom'
import PageHeader from "@/components/PageHeader";
import PageFooter from "@/components/PageFooter";
import RouterLine from "@/components/RouterLine";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";
import { Input } from 'antd'
import {useEffect, useState} from "react";
import Modal from '@/components/Modal'
import kolApi from '@/api/kol'
import projectsApi from '@/api/projects'
import useSyncCallback from "@/utils/useSyncCallback";
import message from "@/utils/message";
import dayjs from "dayjs";
import commonApi from "@/api/common";
import {projectTypeIndex, projectTypeObj} from "../../../enum/projectType";
import Comment from "../../../components/Comment";
import DetailHandle from "../../../components/DetailHandle";
import CreateInfo from "../../../components/CreateInfo";
import {getMediaIcon} from "../../../enum/mediaType";

function Detail() {
    const {id} = useParams()
    const { t } = useTranslation()
    const [searchParams, setSearchParams] = useSearchParams()
    const baseUrl = process.env.REACT_APP_BASE_URL
    const color = useSelector(state => state.color.value)
    const userInfo = useSelector(state => state.user.value)
    const [updateModalOpen,setUpdateModalOpen] = useState(false)
    const [createInfo, setCreateInfo] = useState({})
    const [detailInfo,setDetailInfo] = useState({
        smcList: []
    })
    const [remarkModel, setRemarkModel] = useState(false)

    useEffect(()=>{
        if (searchParams.get("type") || (searchParams.get("status") === "2" || searchParams.get("status") === "0")){
            getAuditDetail()
        }else {
            getKolDetail()
        }
    },[])

    const getAuditDetail = useSyncCallback(()=>{
        kolApi.queryKolDetailFromDB({id}).then(res=>{
            setDetailInfo({...res.data})
            if (searchParams.get("status") === "2") {
                if (userInfo.userId == res.data.updateBy || userInfo.userId == res.data.createBy){
                    setRemarkModel(true)
                }
            }
            getCreateUserInfo()
        })
    })

    const getKolDetail = useSyncCallback(()=> {
        kolApi.queryKolDetail({id}).then(res=>{
            setDetailInfo({...res.data})
            getCreateUserInfo()
        })
    })

    const getCreateUserInfo = useSyncCallback(()=>{
        commonApi.queryCreateUserInfo({createBy: detailInfo.createBy,projectId: detailInfo.id}).then(res=>{
            setCreateInfo({...res.data})
        })
    })

    return (
        <div className='main_back main_back_white'>
            <PageHeader other={true}></PageHeader>
            <div className='kol_detail_main_block'>
                {
                    searchParams.get("type") === 'audit'?
                        (<div className={'router_line_block'}>
                            <img src={require('@/assets/image/project/router_arrow.png')} className='rotateY-180'
                                 alt='arrow'/>
                            <Link to={-1}>
                                <span className={'router_line_block_color'}>{t('back')}</span>
                            </Link>
                        </div>):(<RouterLine name={detailInfo.name}/>)
                }
                <div className='kol_detail_main'>
                    <div className='kol_detail_main_left'>
                        <div className='kol_detail_left_info'>
                            <div className='kol_detail_left_info_content'>
                                <img className='kol_detail_info_image'
                                     src={baseUrl + detailInfo.kolUrl} alt='Empty'/>
                                <div className='kol_detail_info_text'>
                                    <div className='kol_detail_info_name'>
                                        {detailInfo.nickname}
                                        <img className='kol_detail_popular_figures_kol_icon'
                                             src={require('@/assets/image/kol/kol_icon.png')} alt='icon'/>
                                    </div>
                                    <div className='kol_detail_info_context'>{detailInfo.country !== 'unknown' && detailInfo.country !== '不详'?detailInfo.country:''}</div>
                                </div>
                            </div>

                            {(detailInfo.mainId && !searchParams.get("type")) && <DetailHandle mainId={detailInfo.mainId} name={detailInfo.nickname}
                                                                inclusionType={projectTypeObj.kol.type} lang={detailInfo.lang}
                                                                id={id}></DetailHandle>}
                        </div>
                        <div className='kol_detail_info_link_list'>
                            {
                                detailInfo.smcList.map(item => {
                                    return (
                                        <div onClick={() => {
                                            let url = item.webUrl
                                            if (url.split("://")[0] === "http" || url.split("://")[0] === "https"){
                                                window.open(item.webUrl)
                                            }else {
                                                window.open("http://"+url.split("://")[0])
                                            }
                                        }} className='kol_detail_info_link'>
                                            <img
                                                src={getMediaIcon(item.webType,color)}/>
                                            {item.webType}
                                        </div>
                                    )
                                })
                            }
                        </div>
                        <div className='kol_detail_details'>
                            <div className='kol_detail_details_title'>{t('details')}</div>
                            <div className='kol_detail_details_content'>{detailInfo.content}</div>
                        </div>
                        {!searchParams.get("type") && <Comment id={id}></Comment>}
                    </div>
                    <div className='kol_detail_main_right'>
                        <CreateInfo createInfo={createInfo} createBy={detailInfo.createBy} createTime={detailInfo.createTime}></CreateInfo>
                    </div>
                </div>
            </div>
            <PageFooter></PageFooter>
            <Modal open={updateModalOpen}>
                <div className='kol_comment_dialog'>
                    <img onClick={() => setUpdateModalOpen(false)} className='kol_comment_dialog_close'
                         src={require('@/assets/image/home/dialog_close.png')} alt='close'/>
                    <div className='kol_comment_dialog_title'>{t('update')}</div>
                    <div className='kol_detail_update_block'>
                        <div className='kol_detail_update_small_title'>{t('updateSmallTitle')}</div>
                        <div className='kol_detail_update_btn_list'>
                            <Link to={`/kol/update/${id}`}><div>{t('updateContent')}</div></Link>
                            <div>{t('updateLanguage')}</div>
                            <div>{t('InitiateVoting')}</div>
                        </div>
                        <div className='kol_detail_update_text'>{t('updateText')}</div>
                    </div>
                </div>
            </Modal>
            <Modal open={remarkModel}>
                <div className='project_comment_dialog'>
                    <img onClick={() => setRemarkModel(false)} className={'project_comment_dialog_close'}
                         src={require('@/assets/image/home/dialog_close.png')} alt='close'/>
                    <div className='project_comment_dialog_title'>{t('ReviewOpinions')}</div>
                    <div className='project_comment_dialog_text' style={{color: '#FF2929'}}>{detailInfo.examineReport}</div>
                    <div className='project_handle_btn'>
                        <div onClick={() => setRemarkModel(false)}>{t('close')}</div>
                        <Link to={`/kol/update/${id}?type=audit&update=1`}>
                            <div className='project_handle_btn_confirm'>{t('toUpdate')}</div>
                        </Link>
                    </div>
                </div>
            </Modal>
        </div>
    )
}

export default Detail;
