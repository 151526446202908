import './region_components.scss'
import {Select} from "antd";
import {useEffect, useState} from "react";
import useSyncCallback from "../../utils/useSyncCallback";
import commonApi from "../../api/common";
import {getTranslation, getTranslationBatch} from "../../utils/middle";
import {useSelector} from "react-redux";

function Region({change,value}) {
    const [locationList,setLocationList] = useState([])
    const lang = useSelector(state => state.language.value)

    const queryLocation = useSyncCallback(()=>{
        commonApi.queryDictData({dictType: 'area'}).then(res=>{
            const arrList = res.rows.map(item=>item.dictLabel)
            if (lang.value === 0){
                console.log(arrList)
                getTranslationBatch(arrList.toString(),"zh","en",(res)=>{
                    console.log(res)
                    res.data.TargetTextList.forEach(item=>{
                        setLocationList(e=>{
                            return [...e,{label: item,value: item}]
                        })
                    })
                })
            }else {
                arrList.forEach(item=>{
                    setLocationList(e=>{
                        return [...e,{label:item,value: item}]
                    })
                })
            }
        })
    })

    useEffect(() => {
        queryLocation()
    }, []);
    return (
        <>
            <Select value={value} className={"included_form_input"} options={locationList} onChange={e=>change(e)}/>
        </>
    );
}

export default Region;
