import axios from "axios";
import message from './message'
import store from '@/app/store'
import {Logout} from "../features/auth/authSlice";
import {getTranslation} from "./middle";

const blackList = [
    '/myLikes/getLikeStatus','/myNolikes/getNolikeStatus','/myCollection/getCollectionStatus'
]

function request(options={}){

    return new Promise((resolve, reject)=>{
        if (blackList.indexOf(options.url) !== -1 && !store.getState().auth.value) return resolve({})
        axios({
            url: options.url,
            method: options.method,
            baseURL: '/api',
            data: options.method !== 'get' && options.method !== 'delete'?options.data:{},
            params: options.method === 'get' || options.method === 'delete'?options.data:{},
        }).then(res=>{
            if (res.data.code === 200) {
                resolve(res.data)
            }
        }).catch(err=>{
            reject(err)
        })
    })
}

axios.interceptors.request.use(function (config) {
    config.headers["Authorization"] = 'Bearer '+store.getState().auth.value
    return config;
}, function (error) {
    return Promise.reject(error);
});

axios.interceptors.response.use(function (response) {
    if (response.data.code !== 200) {
        getTranslation(response.data.msg,'zh','en',(res)=>{
            message.fail(res.data.TargetText)
        })
        if (response.data.msg === 'token error') {
            store.dispatch(Logout())
        }
    }
    return response;
}, function (error) {
    return Promise.reject(error);
});
function get(url,data,options={}) {
    options.url = url
    options.data = data
    options.method = 'get'
    return request(options)
}

function post(url,data,options={}) {
    options.url = url
    options.data = data
    options.method = 'post'
    return request(options)
}

function put(url,data,options={}) {
    options.url = url
    options.data = data
    options.method = 'put'
    return request(options)
}

function del(url,data,options={}) {
    options.url = url
    options.data = data
    options.method = 'delete'
    return request(options)
}

export default {
    get,
    post,
    put,
    del
}
