import request from '@/utils/request'

class Api {
    queryLikeStatus(data) {
        return request.get('/myLikes/getLikeStatus',data)
    }

    queryNoLikeStatus(data) {
        return request.get('/myNolikes/getNolikeStatus',data)
    }

    queryCollectionStatus(data) {
        return request.get('/myCollection/getCollectionStatus',data)
    }

    addCollectionData(data) {
        return request.post('/myCollection/add',data)
    }

    cancelCollectionData(data) {
        return request.get('/myCollection/cancel',data)
    }

    queryCommentStatus(data) {
        return request.get('/userCommentLikes/getStatus',data)
    }

    likeComment(data) {
        return request.post('/userComment/updateLikeCount',data)
    }

    unLikeComment(data) {
        return request.post('/userComment/updateNoLikeCount',data)
    }

    queryUniversityList(data) {
        return request.get('/universityInformation/list',data)
    }

    submitLike(data) {
        return request.post('/myLikes/add',data)
    }

    submitNoLike(data) {
        return request.post('/myLikes/reduce',data)
    }

    submitUnLike(data) {
        return request.post('/myNolikes/add',data)
    }

    submitNoUnLike(data) {
        return request.post('/myNolikes/reduce',data)
    }

    queryLikeCount(data) {
        return request.get('/myLikes/getCount',data)
    }

    queryKeyEvent(data) {
        return request.get('/keyEvent/list',data)
    }

    queryForeignInvest(data) {
        return request.get('/foreignInvest/list',data)
    }

    queryProjectInvestDetails(data) {
        return request.get('/projectInvestDetails/list',data)
    }

    queryDictData(data) {
        return request.get('/system/dict/data/list',data)
    }

    updateTransactionHash(data) {
        return request.post('/inclusionExamine/updateStatusByQmHash',data)
    }

    queryCreateUserInfo(data) {
        return request.get('/system/user/getUserInfo',data)
    }

    queryCreateHistory(data) {
        return request.get('/inclusionExamine/getIncluisionByUserId',data)
    }

    delExamineDataByQmHash(qmHash) {
        return request.del('/inclusionExamine/deleteByQmHash',{qmHash})
    }

    queryUniversityInfo(data) {
        return request.get('/universityInformation/getInfo',data)
    }

    queryUserInfoByEsMainId(data) {
        return request.get('/figureInclusion/getFigureInclusionEsInfo',data)
    }
}

export default new Api();
