import Web3 from "web3";
import playerJSON from '@/assets/json/player.json'
import wikiJSON from '@/assets/json/wiki.json'
import projectJSON from '@/assets/json/project.json'

export const playerAddress = process.env.REACT_APP_PLAYER_ADDRESS
export const projectAddress = process.env.REACT_APP_CREATE_PROJECT
export const wikiAddress = process.env.REACT_APP_WIKI_TOKEN
export const investorAddress = process.env.REACT_APP_CREATE_INVESTOR
export const peopleAddress = process.env.REACT_APP_CREATE_PEOPLE
export const meetingAddress = process.env.REACT_APP_CREATE_MEETING
export const kolAddress = process.env.REACT_APP_CREATE_KOL
export const toolAddress = process.env.REACT_APP_CREATE_TOOL
export const hackathonAddress = process.env.REACT_APP_CREATE_HACKATHON

export const web3 = new Web3(window.ethereum)
export const playerContract = new web3.eth.Contract(playerJSON,playerAddress);
export const projectContract = new web3.eth.Contract(projectJSON,projectAddress);
export const wikiContract = new web3.eth.Contract(wikiJSON,wikiAddress);
export const investorContract = new web3.eth.Contract(projectJSON,investorAddress);
export const peopleContract = new web3.eth.Contract(projectJSON,peopleAddress);
export const meetingContract = new web3.eth.Contract(projectJSON,meetingAddress);
export const kolContract = new web3.eth.Contract(projectJSON,kolAddress);
export const toolContract = new web3.eth.Contract(projectJSON,toolAddress);
export const hackathonContract = new web3.eth.Contract(projectJSON,hackathonAddress);
