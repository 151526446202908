import '../user_module.scss'
import dayjs from "dayjs";
import {Link} from "react-router-dom";

function Meeting(props) {
    const arrList = props.list || []
    const baseUrl = process.env.REACT_APP_BASE_URL
    return (
        <div className={'collection_list_block'}>
            <div className={'collection_list_grid_3'}>
                {
                    arrList.map(item => {
                        return (
                            <Link to={`/meeting/detail/${item.id}?status=${item.status}`}>
                                <div className={'collection_meeting_item'}>
                                    { item.status === "0" && <img className={'submission_audit_image'} src={require('../../../assets/image/user/audit.png')} alt='audit'/>}
                                    { item.status === "1" && <img className={'submission_audit_image'} src={require('../../../assets/image/user/collecteo.png')} alt='audit'/>}
                                    { item.status === "2" && <img className={'submission_audit_image'} src={require('../../../assets/image/user/peview.png')} alt='audit'/>}
                                    <img className={'collection_meeting_image'}
                                         src={baseUrl + item.meetingUrl} alt='news'/>
                                    <div style={{width: 300}}
                                         className={'collection_meeting_text text-overflow-line-2-style'}>{item.content}</div>
                                    <div className={'collection_meeting_time'}>
                                        <img className={'collection_meeting_time_icon'}
                                             src={require('../../../assets/image/user/icon_PledgeTime.png')}
                                             alt='time'/>
                                        {dayjs(item.startTime).format('YYYY-MM-DD HH:mm')}~{dayjs(item.endTime).format('YYYY-MM-DD HH:mm')}
                                    </div>
                                </div>
                            </Link>
                        )
                    })
                }
            </div>
        </div>
    )
}

export default Meeting;
