import "../../Included/included_module.scss"
import PageHeader from "@/components/PageHeader";
import {useTranslation} from "react-i18next";
import {Button, Checkbox, Form, Input, DatePicker, Select, Spin} from 'antd';
import Upload from '@/components/Upload/index'
import { SearchOutlined } from '@ant-design/icons';
import {useSelector} from "react-redux";
import {useEffect, useRef, useState} from "react";
import Radio from '@/components/Radio/index'
import moreApi from '@/api/more'
import useSyncCallback from "@/utils/useSyncCallback";
import message from "@/utils/message";
import {useNavigate, useParams, useSearchParams} from "react-router-dom";
import commonApi from '@/api/common'
import {projectContract, toolContract, web3} from "../../../utils/web3Utils";
import {auditStatusObj} from "../../../enum/auditStatus";
import {setReview} from "../../../utils/middle";
import userApi from '@/api/user'
import Language from "../../../components/Language";
import dayjs from "dayjs";
import Modal from "../../../components/Modal";
import investorApi from "../../../api/investor";

function Tool() {
    const navigate = useNavigate()
    const {t} = useTranslation()
    const {id} = useParams()
    const [form] = Form.useForm()
    const [searchParams, setSearchParams] = useSearchParams()
    const baseUrl = process.env.REACT_APP_BASE_URL
    const color = useSelector(state => state.color.value)
    const media = useSelector(state => state.media.value)
    const userInfo = useSelector(state=>state.user.value)
    const walletAddress = useSelector(state => state.walletAddress.value)
    const [remarkModel, setRemarkModel] = useState(false)
    const inlineFormStyle = {
        width: '600px',
        marginRight: '102px'
    }
    const [tab,setTab] = useState([])
    const [tabList,setTabList] = useState([])
    const [ecology,setEcology] = useState('')
    const [ecologyList,setEcologyList] = useState([])
    const [operation,setOperation] = useState(null)
    const [auditReport,setAuditReport] = useState(null)
    const [logoUrl, setLogoUrl] = useState('')
    const [name, setName] = useState('')
    const [officialWebsite, setOfficialWebsite] = useState('')
    const [synopsis,setSynopsis] = useState('')
    const [content,setContent] = useState('')
    const [reportAddress, setReportAddress] = useState('')
    const [mainId,setMainId] = useState('')
    const [tagDataList, setTagDataList] = useState([])
    const [lang, setLang] = useState(0)
    const [oldLang, setOldLang] = useState(0)
    const [spinning, setSpinning] = useState(false)
    const [createBy, setCreateBy] = useState('')
    const [createTime, setCreateTime] = useState('')
    const [qmHash, setQmHash] = useState('')
    const radioRef = useRef()
    const radioAuditRef = useRef()

    function labelNode(title,text) {
        return (
            <>
                <span className={'included_label_title'}>{title}</span>
                {text && (<span className={'included_label_text'}>{text}</span>)}
            </>
        )
    }

    function addTabs() {
        if (tab) {
            console.log(tab)
            setTabList((e)=>{
                return [...new Set([...e,...tab])]
            })
            setTab([])
        }
    }

    function delTabs(index) {
        const list = [...tabList]
        list.map((item,key)=>{
            if (index === key){
                list.splice(index,1)
                setTabList(list)
            }
        })
    }

    function addEcology() {
        setEcologyList((e)=>{
            console.log(ecology)
            return [...e,ecology]
        })
        setEcology('')
    }

    function delEcology(index) {
        const list = [...ecologyList]
        list.map((item,key)=>{
            if (index === key){
                list.splice(index,1)
                setEcologyList(list)
            }
        })
    }

    const submitForm = useSyncCallback((type)=>{
        setSpinning(true)
        const data = {
            mainId,
            logoUrl,name,officialWebsite,ecology: ecologyList.toString(),synopsis,label: tabList.toString(),content,walletStatus: operation,wauditStatus: auditReport,reportAddress: auditReport === 0?'':reportAddress,submitType: type, lang, createTime, createBy, updateTimeString: dayjs(Date.now()).format("YYYY-MM-DD HH:mm:ss")
        }
        if (type === 0) {
            moreApi.updateToolsDraft({...data,id}).then(res=>{
                setSpinning(false)
                message.success(t('saveSuccess'))
            }).catch(err=>{
                moreApi.submitToolsForm({...data}).then(res=>{
                    setSpinning(false)
                    message.success(t('saveSuccess'))
                })
            })
        }else if (type === 1) {
            toolContract.methods.projectBase(mainId).call().then(res=>{
                const dataBase = web3.utils.fromWei(res["0"],'ether')
                const firstQmHash = res.firstQmHash
                if ((dataBase === '0.') || (qmHash === firstQmHash && searchParams.get("update")==='1')) {
                    data.mainId = void 0
                    moreApi.submitToolsForm({...data,createTimeString: dayjs(Date.now()).format("YYYY-MM-DD HH:mm:ss")}).then(res=>{
                        const obj = res.data
                        toolContract.methods.createWiki(obj.mId,obj.cId,obj.lang,obj.qmHash).send({from: walletAddress}).then((res)=>{
                            console.log('上链参数',res)
                            if (res.status === 1n) {
                                commonApi.updateTransactionHash({
                                    qmHash: obj.qmHash,
                                    transactionHash: res.transactionHash
                                }).then(_=>{
                                    userApi.updateAuditStatus({
                                        projectId: obj.cId,
                                        status: auditStatusObj["unaudited"].type
                                    }).then(_=>{
                                        if(searchParams.get("update")==='1') {
                                            message.success(t('Resubmit'))
                                        }else {
                                            message.success(t('saveSuccess'))
                                        }
                                        if(searchParams.get("draftId")) {
                                            userApi.delUserDraft(searchParams.get("draftId")).then(res=>{
                                                navigate(-1)
                                            })
                                        }
                                    })
                                })
                            }else {
                                message.fail(t('DenialAuthorization'))
                            }
                            setSpinning(false)
                        }).catch((err)=>{
                            console.log(err)
                            moreApi.delTools(obj.cId)
                            commonApi.delExamineDataByQmHash(obj.qmHash)
                            setSpinning(false)
                        })
                    })
                }else {
                    if(createBy == userInfo.userId && searchParams.get("update") !== '1') {
                        setSpinning(false)
                        return message.fail(t("createUpdateFail"))
                    }
                    moreApi.updateToolsDetail({...data, oldLang, oldId: id}).then(res=>{
                        const obj = res.data
                        console.log(obj)
                        console.log(walletAddress)
                        toolContract.methods.updateWiki(obj.mId,obj.cId,obj.lang,obj.qmHash).send({from: walletAddress}).then((res)=>{
                            console.log('上链参数',res)
                            if (res.status === 1n) {
                                commonApi.updateTransactionHash({
                                    qmHash: obj.qmHash,
                                    transactionHash: res.transactionHash
                                }).then(_=>{
                                    userApi.updateAuditStatus({
                                        projectId: obj.cId,
                                        status: auditStatusObj["unaudited"].type
                                    }).then(_=>{
                                        if(searchParams.get("update")==='1') {
                                            message.success(t('Resubmit'))
                                        }else {
                                            message.success(t('saveSuccess'))
                                        }
                                    })
                                })
                            }else {
                                message.fail(t('DenialAuthorization'))
                            }
                            setSpinning(false)
                        }).catch((err)=>{
                            console.log(err)
                            moreApi.delTools(obj.cId)
                            commonApi.delExamineDataByQmHash(obj.qmHash)
                            setSpinning(false)
                        })
                    })
                }
            })

        } else {
            setReview(data,(res)=>{
                setSpinning(false)
                navigate(`/more/tool/review?id=${res.data}`)
            })
        }
    })

    const getTagList = useSyncCallback((text)=>{
        commonApi.queryDictData({
            dictType: 'project_lable',
            dictLabel: text
        }).then(res=>{
            setTagDataList(res.rows)
        })
    })

    const getAuditDetail = useSyncCallback(()=>{
        moreApi.queryToolsDetailFromDB({id}).then(res=>{
            setLogoUrl(res.data.logoUrl)
            setName(res.data.name)
            setOfficialWebsite(res.data.officialWebsite)
            setTabList(()=>{
                return res.data.label.split(',')
            })
            setEcologyList(()=>{
                return res.data.ecology.split(',')
            })
            setQmHash(res.data.qmHash)
            setSynopsis(res.data.synopsis)
            setContent(res.data.content)
            setOperation(res.data.walletStatus)
            radioRef.current.checkData(Number(res.data.walletStatus))
            radioAuditRef.current.checkData(Number(res.data.wauditStatus))
            setReportAddress(res.data.reportAddress)
            setAuditReport(Number(res.data.wauditStatus))
            setMainId(res.data.mainId)
            setCreateBy(res.data.createBy || void 0)
            setCreateTime(res.data.createTime || void 0)
            form.setFieldsValue({
                name: res.data.name,
                officialWebsite: res.data.officialWebsite,
                ProjectIntroduction: res.data.synopsis,
                Introduction: res.data.content
            })
        })
    })

    const getDetail = useSyncCallback(()=>{
        moreApi.queryToolsDetail({id}).then(res=>{
            setLogoUrl(res.data.logoUrl)
            setName(res.data.name)
            setOfficialWebsite(res.data.officialWebsite)
            setTabList(()=>{
                return res.data.label.split(',')
            })
            setEcologyList(()=>{
                return res.data.ecology.split(',')
            })
            setQmHash(res.data.qmHash)
            setSynopsis(res.data.synopsis)
            setContent(res.data.content)
            setOperation(res.data.walletStatus)
            radioRef.current.checkData(Number(res.data.walletStatus))
            radioAuditRef.current.checkData(Number(res.data.wauditStatus))
            setReportAddress(res.data.reportAddress)
            setAuditReport(Number(res.data.wauditStatus))
            setMainId(res.data.mainId)
            setCreateBy(res.data.createBy || void 0)
            setCreateTime(res.data.createTime || void 0)
            form.setFieldsValue({
                name: res.data.name,
                officialWebsite: res.data.officialWebsite,
                ProjectIntroduction: res.data.synopsis,
                Introduction: res.data.content
            })
        })
    })

    useEffect(()=>{
        if (searchParams.get("type")) {
            getAuditDetail()
        }else {
            getDetail()
        }
    },[])
    return (
        <>
            <div className={'main_back pb-60'}>
                <PageHeader other={true}></PageHeader>
                <div className={'included_title included_title_other'}>{t('OtherTitle')}</div>
                <div className={'included_text'}>{t('projectIncluedText')}</div>
                <Language lang={lang} onChange={e => setLang(e)}></Language>
                <div className={'included_form'}>
                    <Form layout="vertical" name="project" autoComplete="off" form={form}>
                        <Form.Item name="logo" label={labelNode('Logo', t('projectIncluedLogoText'))}
                                   rules={[{required: true}]}>
                            <Upload onSuccess={e => setLogoUrl(e.data)}>
                                {
                                    !logoUrl && (
                                        <div className={'included_upload_logo'}>
                                            <img src={require("@/assets/image/included/upload_icon.png")} alt='upload'/>
                                        </div>
                                    )
                                }
                                {
                                    logoUrl && (
                                        <img src={baseUrl + logoUrl} className={'included_upload_logo'} alt='icon'/>
                                    )
                                }
                                <div className={'included_upload_logo_limit'}>{t('uploadImageLimit')}</div>
                            </Upload>
                        </Form.Item>
                        <div className={'flex'}>
                            <Form.Item style={inlineFormStyle} name={'name'} label={labelNode(t('name'))}
                                       rules={[{required: true}]}>
                                <Input value={name} onChange={e => setName(e.target.value)}
                                       className={"included_form_input"} placeholder={t("namePlaceholder")}/>
                            </Form.Item>
                            <Form.Item style={inlineFormStyle} name={'officialWebsite'}
                                       label={labelNode(t('officialWebsite'))} rules={[{required: true}]}>
                                <Input className={"included_form_input"} value={officialWebsite}
                                       onChange={e => setOfficialWebsite(e.target.value.replace(/\s/g, ""))}
                                       placeholder={t("officialWebsitePlaceholder")}/>
                            </Form.Item>
                        </div>
                        <Form.Item name={'Tabs'} label={labelNode(t('Tabs'))} rules={[{required: true}]}>
                            <div className={'included_form_tag_input'}>
                                <Select
                                    style={{width: 750}} mode="multiple" filterOption={false}
                                    fieldNames={{label: 'dictLabel', value: 'dictLabel'}}
                                    className={'included_form_add_input included_form_input'}
                                    options={tagDataList} onSearch={getTagList} value={tab} onChange={e => {
                                    setTab(e)
                                }} suffixIcon={
                                    <img src={require('@/assets/image/included/search_icon.png')}
                                         style={{width: '19px', height: '19px'}}/>
                                }
                                />
                                <div className={'included_form_tag_add_button'} onClick={addTabs}>
                                    <img src={require('@/assets/image/included/add_icon.png')}/>
                                    Add
                                </div>
                            </div>
                            <div className={'included_tag_list'}>
                                {tabList.length > 0 && (
                                    <>
                                        {tabList.map((item, index) => {
                                            return (
                                                <div className={'included_tag_item'}>
                                                    {item}
                                                    <img onClick={() => delTabs(index)}
                                                         className={'included_tag_item_close'}
                                                         src={color === 'white' ? require('@/assets/image/included/close_white.png') : require("@/assets/image/included/close_dark.png")}/>
                                                </div>
                                            )
                                        })}
                                    </>
                                )}
                            </div>
                        </Form.Item>
                        <Form.Item name={'ProjectEcology'} label={labelNode(t('ProjectEcology'))}>
                            <div className={'included_form_tag_input'}>
                                <Input className={'included_form_add_input included_form_input'} value={ecology}
                                       onChange={(e) => {
                                           setEcology(e.target.value)
                                       }} suffix={
                                    <img src={require('@/assets/image/included/search_icon.png')}
                                         style={{width: '19px', height: '19px'}}/>
                                }/>
                                <div className={'included_form_tag_add_button'} onClick={addEcology}>
                                    <img src={require('@/assets/image/included/add_icon.png')}/>
                                    Add
                                </div>
                            </div>
                            <div className={'included_tag_list'}>
                                {ecologyList.length > 0 && (
                                    <>
                                        {ecologyList.map((item, index) => {
                                            return (
                                                <div className={'included_tag_item'}>
                                                    {item}
                                                    <img onClick={() => delEcology(index)}
                                                         className={'included_tag_item_close'}
                                                         src={color === 'white' ? require('@/assets/image/included/close_white.png') : require("@/assets/image/included/close_dark.png")}/>
                                                </div>
                                            )
                                        })}
                                    </>
                                )}
                            </div>
                        </Form.Item>
                        <Form.Item name={'ProjectIntroduction'} label={labelNode(t('ProjectIntroduction'))}
                                   rules={[{required: true}]}>
                            <Input value={synopsis} onChange={e => setSynopsis(e.target.value)} maxLength={100} showCount
                                   className={"included_form_input"}/>
                        </Form.Item>
                        <Form.Item name={'Introduction'} label={labelNode(t('Introduction'))}
                                   rules={[{required: true}]}>
                            <Input.TextArea value={content} onChange={e => setContent(e.target.value)}
                                            className={'included_form_textarea included_form_input'}
                                            style={{height: 200, resize: 'none'}} maxLength={1500} showCount/>
                        </Form.Item>
                        <Form.Item name={'ConnectWalletTitle'} label={labelNode(t('ConnectWalletTitle'))}
                                   rules={[{required: true}]}>
                            <Radio ref={radioRef} list={[
                                {value: 0, label: t("need"), id: 0, text: ''},
                                {value: 1, label: t("notRequired"), id: 0, text: ''}
                            ]} onChange={(e) => setOperation(e)}></Radio>
                            <div className={'included_tools_ConnectWalletTitle'}>
                                <Radio ref={radioAuditRef} list={[
                                    {value: 0, label: t("NoAuditReport"), id: 0, text: ''},
                                    {value: 1, label: t("HaveAuditReport"), id: 0, text: ''}
                                ]} onChange={(e) => {
                                    setAuditReport(e)
                                }}></Radio>
                                {
                                    auditReport === 1 && (
                                        <>
                                            <Upload onSuccess={e => setReportAddress(e.data)}>
                                                <div className={'included_upload_button'}>
                                                    <img src={require("@/assets/image/included/button_upload_icon.png")}
                                                         alt='icon'/>
                                                    <span>{t('UploadAuditReport')}</span>
                                                </div>
                                            </Upload>
                                            {reportAddress && (<div>{baseUrl + reportAddress}</div>)}
                                        </>
                                    )
                                }
                            </div>
                        </Form.Item>
                    </Form>
                    <div className={'included_form_handler_button'}>
                        <div className={'included_form_handler_button_left'}>
                            <div onClick={() => submitForm(2)}
                                className={'included_form_handler_button_item included_form_handler_button_preview'}>{t('preview')}</div>
                            <div onClick={() => submitForm(0)}
                                 className={'included_form_handler_button_item included_form_handler_button_SaveDraft'}>{t('SaveDraft')}</div>
                        </div>
                        <div onClick={() => {
                            moreApi.queryCheckNameTools(name).then(res=>{
                                console.log(res)
                                if(res.data === 0){
                                    submitForm(1)
                                }else {
                                    if(searchParams.get("type") === "draft") {
                                        setRemarkModel(true)
                                    }else {
                                        submitForm(1)
                                    }
                                }
                            })
                        }}
                             className={'included_form_handler_button_item included_form_handler_button_SubmitInclusion'}>{t('SubmitInclusion')}</div>
                    </div>
                </div>
            </div>
            <Modal open={remarkModel}>
                <div className='project_comment_dialog'>
                    <img onClick={() => setRemarkModel(false)} className={'project_comment_dialog_close'}
                         src={require('@/assets/image/home/dialog_close.png')} alt='close'/>
                    <div className='project_comment_dialog_title'>{t('remind')}</div>
                    <div className='project_comment_dialog_text' style={{color: '#FF2929'}}>{t('remind_text')}</div>
                    <div className='project_handle_btn'>
                        <div onClick={() => setRemarkModel(false)}>{t('close')}</div>
                        <div onClick={()=> {
                            setRemarkModel(false)
                            submitForm(1)
                        }} className='project_handle_btn_confirm'>{t('SubmitInclusion')}</div>
                    </div>
                </div>
            </Modal>
            <Spin tip="Loading" fullscreen spinning={spinning} size="large"/>
        </>
    )
}

export default Tool;
