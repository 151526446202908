import './login_module.scss'
import Sign from './Sign'
import Wallet from './Wallet'
import Forget from './Forget'
import {useState} from "react";
function Login() {
    const [pageType,setPageType] = useState(0)

    function connectWallet(e) {
        setPageType(e)
    }
    return (
        <>
            <div className={'login_main'}>
                <div className={'login_left_back'}>
                    <img className={'login_left_top_logo'} src={require("@/assets/image/login/logo.png")} alt='logo'/>
                    <img className={'login_left_middle_image'} src={require("@/assets/image/login/login_image.gif")} alt='logo'/>
                    <div className={'login_copyright'}>Copyright @ 2024</div>
                </div>
                {
                    pageType === 0 && (<Sign onButtonClick={connectWallet}></Sign>)
                }
                {
                    pageType === 1 && (<Wallet onButtonClick={connectWallet}></Wallet>)
                }
                {
                    pageType === 2 && (<Forget onButtonClick={connectWallet}></Forget>)
                }
            </div>
        </>
    )
}

export default Login
