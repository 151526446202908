import request from './request'
import axios from "axios";
const baseUrl = process.env.REACT_APP_MIDDLE_URL
export const setReview = (data,callback)=> {
    axios({
        url: '/review/set',
        baseURL: '/middle',
        method: 'post',
        data
    }).then(res=>{
        callback(res.data)
    })
}

export const getReview = (id,callback)=> {
    axios({
        url: '/review/get',
        baseURL: '/middle',
        method: 'get',
        params: {id}
    }).then(res=>{
        callback(res.data)
    })
}

export const getTranslation = (text,lang,getLang,callback)=>{
    axios({
        url: '/translation/getTranslations',
        baseURL: '/middle',
        method: 'get',
        params: {
            text,
            type: lang,
            getType: getLang
        }
    }).then(res=>{
        callback(res.data)
    })
}

export const getTranslationBatch = (textList,lang,getLang,callback)=>{
    axios({
        url: '/translation/getTranslationsBatch',
        baseURL: '/middle',
        method: 'get',
        params: {
            textList,
            type: lang,
            getType: getLang
        }
    }).then(res=>{
        callback(res.data)
    })
}

export const queryLikeAllList = (options,callback)=>{
    axios({
        url: '/user_data/my_like',
        baseURL: '/middle',
        method: 'get',
        params: {
            pageNum: options.pageNum,
            pageSize: options.pageSize,
            lang: options.lang
        }
    }).then(res=>{
        callback(res.data)
    })
}

export const queryCollectionAllList = (options,callback)=>{
    axios({
        url: '/user_data/my_collection',
        baseURL: '/middle',
        method: 'get',
        params: {
            pageNum: options.pageNum,
            pageSize: options.pageSize,
            lang: options.lang
        }
    }).then(res=>{
        callback(res.data)
    })
}

export const querySubmissionAllList = (options,callback)=>{
    axios({
        url: '/user_data/my_submission',
        baseURL: '/middle',
        method: 'get',
        params: {
            pageNum: options.pageNum,
            pageSize: options.pageSize,
            lang: options.lang
        }
    }).then(res=>{
        callback(res.data)
    })
}
