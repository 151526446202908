import './home_module.scss'
import '@/utils/canvasBack.js'
import {useEffect, useRef, useState} from "react";
import PageHeader from "@/components/PageHeader/index.js";
import {useTranslation} from 'react-i18next';
import middleLogo from '@/assets/image/home/middleLogo.svg'
import {gsap} from "gsap";
import {ScrollTrigger} from "gsap/ScrollTrigger";
import values1 from '@/assets/image/home/values1.svg'
import values2 from '@/assets/image/home/values2.svg'
import values3 from '@/assets/image/home/values3.svg'
import {Button, Input, Popover} from "antd";
import Matter from 'matter-js'
import {Link} from 'react-router-dom'
import { DownOutlined } from '@ant-design/icons'
import useSyncCallback from "../../utils/useSyncCallback";
import {setUserInfo} from "../../features/user/userSlice";
import userApi from '@/api/user'
import {useDispatch} from "react-redux";
import homeApi from '@/api/home'
import message from "../../utils/message";
import {playerContract, web3} from "../../utils/web3Utils";
import {setWalletAddress} from "../../features/walletAddress/walletAddressSlice";

function Home() {
    const {t, i18n} = useTranslation()
    const dispatch = useDispatch()
    const [mapKey, setMapKey] = useState(0)
    const [scrollImage] = useState(0)
    const leftDotRef = useRef(null)
    const home_bottom_middle = useRef(null)
    const [subEmail, setSubEmail] = useState('')
    gsap.registerPlugin(ScrollTrigger);
    const roadMap = [
        {id: 0, key: 'InformationHub', text: 'InformationHub_text', state: 'StageOne'},
        {id: 1, key: 'PrivateFundraisingLaunchpad', text: 'PrivateFundraisingLaunchpad_text', state: 'StageTwo'},
        {
            id: 2,
            key: 'TradingPlatformforInvestmentQuotas',
            text: 'TradingPlatformforInvestmentQuotas_text',
            state: 'StageThree'
        },
        {
            id: 3,
            key: 'DividendPlatformforPrivateInvestments',
            text: 'DividendPlatformforPrivateInvestments_text',
            state: 'StageFour'
        },
    ]

    const platformValue = [
        {image: values1, title: 'platformValue1', text: 'platformValue1_text'},
        {image: values2, title: 'platformValue2', text: 'platformValue2_text'},
        {image: values3, title: 'platformValue3', text: 'platformValue3_text'}
    ]


    const getUserInfo = useSyncCallback(()=>{
        userApi.queryUserInfo().then(res=>{
            dispatch(setUserInfo(res.data))
        })
    })

    useEffect(() => {
        getConfig()
        if (sessionStorage.getItem('lang')) {
            i18n.changeLanguage(sessionStorage.getItem('lang'))
        }
        if(localStorage.getItem("auth")) {
            if(window.ethereum === void 0) {
                message.fail(t("installMetaMask"))
            }else {
                web3.eth.getAccounts().then((res)=>{
                    if (!res[0]) {
                        res = window.ethereum.request({method: 'eth_requestAccounts'})

                    }
                    dispatch(setWalletAddress(res[0]))
                    getUserInfo()
                })
            }
        }
        // getUserInfo()
        ScrollTrigger.create({
            trigger: '.home_middle_roadmap_block', //skyblue元素
            start: 'top top', //.skyblue元素的顶部开始
            end: '0',  // 到1600px结束
            markers: false, //开始标记  结束标记
            scrub: true,  // 关键点  和滚动条绑定
            pin: true,  //固定起始位置,
            animation: gsap.timeline()
                .fromTo('.home_middle_roadmap_title', {scale: 1, lineHeight: '171px'}, {scale: 0, lineHeight: '0px'}),
            onUpdate(self) {
                if (self.progress <= 0.25) {
                    setMapKey(0)
                } else if (self.progress <= 0.5) {
                    setMapKey(1)
                } else if (self.progress <= 0.75) {
                    setMapKey(2)
                } else {
                    setMapKey(3)
                }
            }
        })

        ScrollTrigger.create({
            trigger: '.home_invite_block', //skyblue元素
            start: 'top 130px', //.skyblue元素的顶部开始
            end: '+=1000px',  // 到1600px结束
            markers: false, //开始标记  结束标记
            scrub: true,  // 关键点  和滚动条绑定
            pin: true,  //固定起始位置,
            animation: gsap.timeline()
                .fromTo('.home_invite_title', {height: '280px', scale: 1}, {height: '0px', scale: 0})
                .fromTo('.home_invite_main_pic_p1', {left: '155px', top: '242px'}, {left: '0', top: '54px'}, "<")
                .fromTo('.home_invite_main_pic_p2', {left: '155px', top: '242px'}, {left: '0', top: '478px'}, "<")
                .fromTo('.home_invite_main_pic_p3', {right: '155px', top: '242px'}, {right: '0', top: '286px'}, "<")
                .fromTo('.home_invite_main_pic_p4', {right: '155px', top: '242px'}, {right: '70px', top: '458px'}, "<")
        })

        ScrollTrigger.create({
            trigger: '.home_investment_block', //skyblue元素
            start: 'top top', //.skyblue元素的顶部开始
            end: '+=1000px',  // 到1600px结束
            markers: false, //开始标记  结束标记
            scrub: true,  // 关键点  和滚动条绑定
            pin: true,  //固定起始位置,
            animation: gsap.timeline()
                .to('.home_invest4', {top: '50px', left: '192px'})
                .to('.home_invest4', {top: '178px', left: '140px', zIndex: '11'})
                .to('.home_invest4', {top: '417px', left: '112px', zIndex: '11'})
                .to('.home_invest3', {top: '156px', left: '160px'})
                .to('.home_invest3', {top: '397px', left: '124px', zIndex: '11'})
                .to('.home_invest2', {top: '50px', left: '192px'})
                .to('.home_invest2', {top: '120px', left: '120px', zIndex: '11'})
                .to('.home_invest2', {top: '377px', left: '100px', zIndex: '11'})
                .to('.home_invest5', {top: '50px', left: '192px'})
                .to('.home_invest5', {top: '130px', left: '140px'})
                .to('.home_invest5', {top: '371px', left: '206px', zIndex: '11'})
                .to('.home_invest6', {top: '50px', left: '192px'})
                .to('.home_invest6', {top: '110px', left: '295px'})
                .to('.home_invest6', {top: '318px', left: '477px', zIndex: '11'})
                .to('.home_invest7', {top: '60px', left: '175px'})
                .to('.home_invest7', {top: '54px', left: '-246px'})
                .to('.home_invest7', {top: '54px', left: '-408px'})
                .to('.home_invest21', {top: '60px', left: '175px'})
                .to('.home_invest21', {top: '92px', left: '140px', zIndex: '12'})
                .to('.home_invest21', {top: '459px', left: '120px', zIndex: '12'})
                .to('.home_invest8', {top: '60px', left: '175px'})
                .to('.home_invest8', {top: '-140px', left: '-200px'})
                .to('.home_invest8', {top: '-165px', left: '-340px'})
                .to('.home_invest9', {top: '60px', left: '360px'})
                .to('.home_invest9', {top: '-140px', left: '520px'})
                .to('.home_invest9', {top: '-183px', left: '982px'})
                .to('.home_invest10', {top: '60px', left: '460px'})
                .to('.home_invest10', {top: '20px', left: '520px'})
                .to('.home_invest10', {top: '-14px', left: '829px'})
                .to('.home_invest11', {top: '80px', left: '350px'})
                .to('.home_invest11', {top: '50px', left: '440px'})
                .to('.home_invest11', {top: '36px', left: '493px'})
                .to('.home_invest12', {top: '80px', left: '200px'})
                .to('.home_invest12', {top: '90px', left: '-43px'})
                .to('.home_invest13', {top: '80px', left: '350px'})
                .to('.home_invest13', {top: '50px', left: '440px'})
                .to('.home_invest13', {top: '193px', left: '550px'})
                .to('.home_invest1', {top: '50px', left: '192px'})
                .to('.home_invest1', {top: '120px', left: '100px'})
                .to('.home_invest1', {top: '241px', left: '66px'})
                .to('.home_invest14', {top: '180px', left: '188px'}, "<")
                .to('.home_invest15', {top: '165px', left: '135px'}, "<")
                .to('.home_invest16', {top: '132px', left: '369px'}, "<")
                .to('.home_invest17', {top: '115px', left: '233px'}, "<")
                .to('.home_invest18', {top: '84px', left: '320px'}, "<")
                .to('.home_invest19', {top: '52px', left: '216px'}, "<")
                .to('.home_invest20', {top: '77px', left: '151px'}, "<")
        })

        ScrollTrigger.create({
            trigger: '.home_big_banner', //skyblue元素
            start: 'top 70px', //.skyblue元素的顶部开始
            end: '+=1000px',  // 到1600px结束
            markers: false, //开始标记  结束标记
            scrub: true,  // 关键点  和滚动条绑定
            pin: true,  //固定起始位置,
            animation: gsap.timeline()
                .fromTo('.home_big_banner_swiper', {right: '-3186px'}, {right: '0'})
        })

        const Engine = Matter.Engine
        const Bodies = Matter.Bodies
        const Render = Matter.Render
        const Composite = Matter.Composite
        const Runner = Matter.Runner
        const width = home_bottom_middle.current.clientWidth

        let engine = Engine.create()
        let render = Render.create({
            element: home_bottom_middle.current, // 绑定页面元素
            engine: engine, // 绑定引擎
            options: {
                width: width,
                height: 540,
                wireframes: false, // 关闭线框模式,
                background :"#00000000",
            }
        })


        let stack_circle = Composite.create()
        let y = 0
        let x = 0
        let key = 0
        for (let i = 0; i < 3; i++) {
            y = i*170
            for (let j = 0; j < 4;j++){
                x = j * 210
                key++
                const body = Bodies.circle(x,y,74,{
                    restitution: 0.8,
                    mass: 3,
                    render: {
                        sprite: {
                            texture: require(`@/assets/image/home/img${key}.png`)
                        }
                    }
                })
                Composite.add(stack_circle, body);
            }
        }

        let ground = Bodies.rectangle(width/2, 550, width, 20, {isStatic: true,render: {fillStyle: "rgba(0,0,0,0)"}})
        let left = Bodies.rectangle(-10, 270, 20, 540, {isStatic: true,render: {fillStyle: "rgba(0,0,0,0)"}})
        let right = Bodies.rectangle(width+10, 270, 20, 540, {isStatic: true,render: {fillStyle: "rgba(0,0,0,0)"}})
        let top = Bodies.rectangle(width/2, -10, width, 20, {isStatic: true,render: {fillStyle: "rgba(0,0,0,0)"}})
        let mouse = Matter.Mouse.create(render.canvas)
        let mouseConstraint = Matter.MouseConstraint.create(engine, {
            mouse: mouse,
            constraint: {
                stiffness: 0.2,
                render: {
                    visible: false // 默认为 true，会显示鼠标拖拽轨迹
                }
            }
        })
        Matter.Events.on(mouseConstraint, "mouseup", function(event) {
            console.log(stack_circle)
            console.log(event)
            console.log('抬起')
        })
        Composite.add(engine.world,[stack_circle,ground,left,right,top,mouseConstraint])
        Render.run(render)
        var runner = Runner.create()
        Runner.run(runner, engine)
    }, [])

    function inviteMouseMove() {
        console.log(leftDotRef.current.getBoundingClientRect().left)
    }



    const content = (
        <div>
            <Link to={'/included/other/tool'}>
                <div className={'home_Popover_text'}>{t('Tool')}</div>
            </Link>
            <Link to={'/included/other/hackathon'}>
                <div className={'home_Popover_text'}>{t('Hackathon')}</div>
            </Link>
        </div>
    )

    const toSub = useSyncCallback(()=>{
        homeApi.subscribeInfo({email: subEmail}).then(res=>{
            message.success(t('success'))
            setSubEmail('')
        })
    })

    const getConfig = useSyncCallback(()=>{
        homeApi.queryTextConfig().then(res=>{
            console.log("rexsssss",res)
        })
    })


    return (
        <div id="smooth-wrapper">
        <div id="smooth-content">
                <PageHeader></PageHeader>
                <div className={'home_top_title'}>BINEST</div>
                <div className={'home_top_text'}>
                    <div>{t('home_top_text_one')}</div>
                    <div>{t('home_top_text_two')}</div>
                </div>
                <div className={'home_middle_logo_block'}>
                    <img src={middleLogo} alt='logo' className={'home_middle_logo'}/>
                </div>
                <div className={'home_middle_roadmap_block'}>
                    <div className={'home_middle_roadmap_title'}>{t('home_middle_Roadmap')}</div>
                    <img src={require("@/assets/image/home/home_middle_back.png")} className={'home_middle_back_img'} alt='logo'/>
                    <div className={'home_roadmap_block'}>
                        <img src={require("@/assets/image/home/home_mobile.png")} className={'home_roadmap_mobile'} alt='mobile'/>
                        <div className={'home_checkMap'} style={{top: mapKey * 200 + 'px'}}>
                            <div className={'home_map_state'}>{t(roadMap[mapKey].state)}</div>
                        </div>
                        {
                            roadMap.map(item => {
                                return (
                                    <div className={`home_map ${mapKey === item.id ? 'home_map_check' : ''}`}
                                         key={item.id}>
                                        <div className={'home_map_title'}>{t(item.key)}</div>
                                        {mapKey === item.id && (<div className={'home_map_text'}>{t(item.text)}</div>)}
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
                <div className={'home_invite_block'}>
                    <div className={'home_invite_title'}>
                        <div>{t("Innovative")}<span className={'home_referral_title'}>{t("Referral")}</span></div>
                        <div className={'home_invite_system'}>
                            {t("System")}
                            <img src={require("@/assets/image/home/home_invite_v.png")} className={'home_invite_v'} alt='v'/>
                        </div>
                    </div>
                    <div className={'home_invite_main_block'}>
                        <div className={'home_invite_main_left'}>
                            <div>
                                <img src={require("@/assets/image/home/home_mobile_small.png")} alt='mobile'/>
                            </div>
                            <div className={'home_invite_left_main'}>
                                <img src={require("@/assets/image/home/p_main.png")} alt='main' className={'home_invite_main_pic'}/>
                                <img src={require("@/assets/image/home/p1.png")} alt='p1' className={'home_invite_main_pic_p1'}/>
                                <img src={require("@/assets/image/home/p2.png")} alt='p2' className={'home_invite_main_pic_p2'}/>
                                <img src={require("@/assets/image/home/p3.png")} alt='p3' className={'home_invite_main_pic_p3'}/>
                                <img src={require("@/assets/image/home/p4.png")} alt='p4' className={'home_invite_main_pic_p4'}/>
                            </div>
                        </div>
                        <div className={'home_invite_main_right'}>
                            <div className={'home_invite_text'}>{t("invite_text")}</div>
                            <div className={'home_invite_data'}>
                                <div className={'home_invited_data_title'}>
                                    <div className={'dataNumber'}>1</div>
                                    <div>{t('FriendsInvited')}</div>
                                </div>
                                <div className={'home_invited_data_title'}>
                                    <div className={'dataNumber'}>0.5</div>
                                    <div>{t('ProjectedEarnings')} (WIKI)</div>
                                </div>
                            </div>
                            <div className={'home_invite_data_scroll'}>
                                <div className={'home_invite_middle_inline'}>
                                    <div ref={leftDotRef} className={'home_line_dot home_invite_line_left_dot'}></div>
                                    <div className={'home_line_dot home_invite_line_right_dot'}></div>
                                </div>
                                {/*<img src={homeLineScroll} alt='scroll' className={'home_line_scroll_image'} draggable/>*/}
                                <div className={'home_line_scroll_image'} style={{left: scrollImage + 'px'}}
                                     onDrag={inviteMouseMove}></div>
                            </div>
                            <div className={'home_line_arrowhead'}>
                                <img src={require("@/assets/image/home/arrowhead.gif")} alt='arrowhead'/>
                            </div>
                            <div className={'home_line_Button'}>
                                <Button id="home_line_button">{t('Invite')}</Button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={'home_middle_swiper'}>
                    <img src={require("@/assets/image/home/home_middle_swiper_logo.png")} alt='swiper logo' className={'home_middle_swiper_logo'}/>
                    <img src={require("@/assets/image/home/home_middle_swiper_logo.png")} alt='swiper logo' className={'home_middle_swiper_logo'}/>
                    <img src={require("@/assets/image/home/home_middle_swiper_logo.png")} alt='swiper logo' className={'home_middle_swiper_logo'}/>
                    <img src={require("@/assets/image/home/home_middle_swiper_logo.png")} alt='swiper logo' className={'home_middle_swiper_logo'}/>
                    <img src={require("@/assets/image/home/home_middle_swiper_logo.png")} alt='swiper logo' className={'home_middle_swiper_logo'}/>
                    <img src={require("@/assets/image/home/home_middle_swiper_logo.png")} alt='swiper logo' className={'home_middle_swiper_logo'}/>
                </div>
                <div className={'home_investment_block'}>
                    <div className={'home_investment_title'}>{t('Investment_with')}</div>
                    <div className={'home_investment_title home_investment_color_text'}>
                        <span>Binest</span> <span>{t('Tokens')}</span>
                    </div>
                    <div className={'home_investment_text'}>{t("Investment_text")}</div>
                    <div className={'home_money_package'}>
                        <img src={require("@/assets/image/home/home_money_package.png")} alt='money package' className={'home_money_package_image'}/>
                        <img src={require("@/assets/image/home/invest1.png")} alt='invest' className={'home_invest_icon home_invest1'}/>
                        <img src={require("@/assets/image/home/invest2.png")} alt='invest' className={'home_invest_icon home_invest2'}/>
                        <img src={require("@/assets/image/home/invest2.png")} alt='invest' className={'home_invest_icon home_invest3'}/>
                        <img src={require("@/assets/image/home/invest2.png")} alt='invest' className={'home_invest_icon home_invest4'}/>
                        <img src={require("@/assets/image/home/invest3.png")} alt='invest' className={'home_invest_icon home_invest5'}/>
                        <img src={require("@/assets/image/home/invest4.png")} alt='invest' className={'home_invest_icon home_invest6'}/>
                        <img src={require("@/assets/image/home/invest5.png")} alt='invest' className={'home_invest_icon home_invest7'}/>
                        <img src={require("@/assets/image/home/invest6.png")} alt='invest' className={'home_invest_icon home_invest8'}/>
                        <img src={require("@/assets/image/home/invest7.png")} alt='invest' className={'home_invest_icon home_invest9'}/>
                        <img src={require("@/assets/image/home/invest8.png")} alt='invest' className={'home_invest_icon home_invest10'}/>
                        <img src={require("@/assets/image/home/invest9.png")} alt='invest' className={'home_invest_icon home_invest11'}/>
                        <img src={require("@/assets/image/home/invest10.png")} alt='invest' className={'home_invest_icon home_invest12'}/>
                        <img src={require("@/assets/image/home/invest11.png")} alt='invest' className={'home_invest_icon home_invest13'}/>
                        <img src={require("@/assets/image/home/invest18.png")} alt='invest' className={'home_invest_icon home_invest20'}/>
                        <img src={require("@/assets/image/home/invest17.png")} alt='invest' className={'home_invest_icon home_invest19'}/>
                        <img src={require("@/assets/image/home/invest16.png")} alt='invest' className={'home_invest_icon home_invest18'}/>
                        <img src={require("@/assets/image/home/invest15.png")} alt='invest' className={'home_invest_icon home_invest17'}/>
                        <img src={require("@/assets/image/home/invest12.png")} alt='invest' className={'home_invest_icon home_invest14'}/>
                        <img src={require("@/assets/image/home/invest13.png")} alt='invest' className={'home_invest_icon home_invest15'}/>
                        <img src={require("@/assets/image/home/invest14.png")} alt='invest' className={'home_invest_icon home_invest16'}/>
                        <img src={require("@/assets/image/home/invest19.png")} alt='invest' className={'home_invest_icon home_invest21'}/>
                    </div>
                </div>
                <div className={'home_big_banner'}>
                    <div className={'home_swiper_overflow'}>
                        <div className={'home_big_banner_swiper'}>PLATFORM&nbsp;VALUES</div>
                    </div>
                </div>
                <div className={'home_platform_block'}>
                    <div className={'home_platform_title'}>{t('PlatformValues')}</div>
                    <div className={'home_values_grid'}>
                        {
                            platformValue.map((item, index) => {
                                return (
                                    <div className={'home_values_grid_block'} key={index}>
                                        <div className={'home_values_image'} style={{}}>
                                            <img src={item.image} alt='svg'/>
                                        </div>
                                        <div className={'home_values_title'}
                                             style={index === 1 ? {width: '325px'} : {}}>{t(item.title)}</div>
                                        <div className={'home_values_text'}>{t(item.text)}</div>
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
                <div className={'home_bottom_block'}>
                    <div className={'home_bottom_title'}>{t('VisionForTheFuture')}</div>
                    <div className={'home_bottom_text'}>{t('VisionForTheFuture_text')}</div>
                    <div className={'home_bottom_middle'}>
                        <img src={require("@/assets/image/home/left_arrow.png")} alt='left'/>
                        <div ref={home_bottom_middle} className={'home_bottom_middle_block'}>
                        </div>
                        <img src={require("@/assets/image/home/right_arrow.png")} alt='right'/>
                    </div>
                    <div className={'home_footer_block'}>
                        <div className={'home_footer_top'}>
                            <div className={'home_footer_top_left'}>
                                <div>{t("join")} Binest</div>
                                <div>{t("join_text")}</div>
                            </div>
                            <div className={'home_footer_top_right'}>
                                <Input value={subEmail} onChange={e=>setSubEmail(e.target.value)} placeholder={t("emailInput")} id="home_footer_input"/>
                                <Button onClick={toSub} id="home_footer_button">{t("subscribe")}</Button>
                            </div>
                        </div>
                        <div className={'home_footer_bottom'}>
                            <div>
                                <img src={require("@/assets/image/home/home_bottom_logo.png")} className={'home_bottom_logo'} alt="logo"/>
                                <div className={'home_join_community'}>{t("joinCommunity")}</div>
                                <div className={'home_footer_english'}>
                                    <div className={'home_footer_community_title'}>{t("englishCommunity")}</div>
                                    <div className={'home_community_icon'}>
                                        <img src={require("@/assets/image/home/community1.png")} alt='icon'/>
                                        <img src={require("@/assets/image/home/twitter.png")} alt='icon'/>
                                        <img src={require("@/assets/image/home/community2.png")} alt='icon'/>
                                        <img src={require("@/assets/image/home/YUTUBE.png")} alt='icon'/>
                                        <img src={require("@/assets/image/home/M.png")} alt='icon'/>
                                        <img src={require("@/assets/image/home/F.png")} alt='icon'/>
                                        <img src={require("@/assets/image/home/in.png")} alt='icon'/>
                                    </div>
                                </div>
                                <div className={'home_footer_chinese'}>
                                    <div className={'home_footer_community_title'}>{t("chineseCommunity")}</div>
                                    <div className={'home_community_icon'}>
                                        <img src={require("@/assets/image/home/weibo.png")} alt='icon'/>
                                        <img src={require("@/assets/image/home/tiktok.png")} alt='icon'/>
                                        <img src={require("@/assets/image/home/wechat.png")} alt='icon'/>
                                    </div>
                                </div>
                                <div className={"home_bottom_copyright"}>Copyright @ 2024</div>
                            </div>
                            <div className={'home_footer_right'}>
                                <div className={"home_footer_entryInclusion"}>
                                    <div className={'home_footer_right_title'}>{t("EntryInclusion")}</div>
                                    <Link to={'/included/project'}>
                                        <div className={'home_footer_right_text'}>{t("SubmitProject")}</div>
                                    </Link>
                                    <Link to={'/included/investor'}>
                                        <div className={'home_footer_right_text'}>{t("Submitinvestor")}</div>
                                    </Link>
                                    <Link to={'/included/character'}>
                                        <div className={'home_footer_right_text'}>{t("Submitcharacter")}</div>
                                    </Link>
                                    <Link to={'/included/meeting'}>
                                        <div className={'home_footer_right_text'}>{t("Submitconference")}</div>
                                    </Link>
                                    <Link to={'/included/kol'}>
                                        <div className={'home_footer_right_text'}>{t("SubmitKOL")}</div>
                                    </Link>
                                    <Popover placement="rightTop" content={content}>
                                        <div className={'home_footer_right_text'}>
                                            {t("SubmitOthers")} <DownOutlined />
                                        </div>
                                    </Popover>
                                </div>
                                <div className={"home_footer_about"}>
                                    <div className={'home_footer_right_title'}>{t("About")}</div>
                                    <div className={'home_footer_right_text'}>{t('Aboutus')}</div>
                                    <div className={'home_footer_right_text'}>{t('Terms&Conditions')}</div>
                                    <div className={'home_footer_right_text'}>{t('PrivacyPolicy')}</div>
                                </div>
                                <div>
                                    <div className={'home_footer_right_title'}>{t("ContactUs")}</div>
                                    <img src={require("@/assets/image/qrcode_logo.png")} className={'home_footer_qrcode'} alt='qrcode'/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Home
