import '../user_module.scss'
import {Link} from "react-router-dom";

function Kol(props) {
    const arrList = props.list || []
    const baseUrl = process.env.REACT_APP_BASE_URL

    return (
        <div className={'collection_list_block'}>
            <div className={'collection_list_grid_3'}>
                {
                    arrList.map(item => {
                        return (
                            <Link to={`/kol/detail/${item.id}?status=${item.status}`}>
                                <div className={'collection_kol_item'}>
                                    { item.status === "0" && <img className={'submission_audit_image'} src={require('../../../assets/image/user/audit.png')} alt='audit'/>}
                                    { item.status === "1" && <img className={'submission_audit_image'} src={require('../../../assets/image/user/collecteo.png')} alt='audit'/>}
                                    { item.status === "2" && <img className={'submission_audit_image'} src={require('../../../assets/image/user/peview.png')} alt='audit'/>}
                                    <div className={'collection_kol_item_top'}>
                                        <img className={'collection_kol_item_avatar'}
                                             src={baseUrl + item.kolUrl} alt='avatar'/>
                                        <div className={'collection_kol_item_info'}>
                                            <div className={'collection_kol_item_name'}>
                                                {item.nickname}
                                                <img className={'kol_detail_popular_figures_kol_icon'}
                                                     src={require('@/assets/image/kol/kol_icon.png')} alt='icon'/>
                                            </div>
                                            <div>{item.country}</div>
                                        </div>
                                    </div>
                                    <div style={{width: 340, height: 96}}
                                         className={'collection_kol_item_bottom text-overflow-line-4-style'}>{item.content}</div>
                                </div>
                            </Link>
                        )
                    })
                }
            </div>
        </div>
    )
}

export default Kol;
