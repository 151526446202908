import './meeting_list_module.scss'
import PageHeader from "@/components/PageHeader";
import PageFooter from "@/components/PageFooter";
import Pagination from "@/components/Pagination";
import {useTranslation} from "react-i18next";
import {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import {Link} from "react-router-dom";
import meetingApi from "../../../api/meeting";
import useSyncCallback from "../../../utils/useSyncCallback";
import dayjs from "dayjs";

function List() {
    const baseUrl = process.env.REACT_APP_BASE_URL
    const { t } = useTranslation();
    const color = useSelector(state => state.color.value)
    const lang = useSelector(state => state.language.value)
    const [peopleListLocation,setPeopleListLocation] = useState(1)
    const [peopleArray,setPeopleArray] = useState([])
    const [width,setWidth] = useState(1920)
    const [pageNum, setPageNum] = useState(1)
    const [requestType,setRequestType] = useState()
    const [requestSort,setRequestSort] = useState(0)
    const [tableData,setTableData] = useState({
        list:[],
        total: 0
    })
    useEffect(() => {
        setWidth(document.documentElement.clientWidth)
        getList()
        getRecommendList()
    }, []);

    function changePage(e) {
        setPageNum(e)
        getList()
        getRecommendList()
    }

    function clickLocaiton(flag) {
        if (flag) {
            setPeopleListLocation((e)=>{
                return e+1
            })
        }else {
            setPeopleListLocation((e)=>{
                return e-1
            })
        }
    }

    const getList = useSyncCallback(()=>{
        meetingApi.queryMeetingList({
            pageNum,
            pageSize: 12,
            lang:lang.value,
            sort: requestSort === void 0?0:requestSort,
            type: requestType === void 0?0:requestType,
        }).then(res=>{
            console.log(res)
            setTableData(()=>{
                return {
                    total: res.total,
                    list: res.rows
                }
            })
        })
    })

    const checkHottest = useSyncCallback((type)=>{
        setRequestSort(()=>void 0)
        setPageNum(1)
        setRequestType(type || 0)
        if(requestSort !== void 0) {
            if (requestSort ===  1){
                setRequestSort(0)
            }else {
                setRequestSort(1)
            }
        }else {
            setRequestSort(0)
        }
        getList()
    })

    const checkRecently = useSyncCallback((type)=>{
        setRequestSort(()=>void 0)
        setPageNum(1)
        setRequestType(type || 1)
        if(requestSort !== void 0) {
            if (requestSort ===  1){
                setRequestSort(0)
            }else {
                setRequestSort(1)
            }
        }else {
            setRequestSort(0)
        }
        getList()
    })

    const getRecommendList = useSyncCallback(()=>{
        meetingApi.queryMeetingList({
            pageNum,
            pageSize: 12,
            lang:lang.value,
            type: 0,
            sort: 0,
            recommend: 1
        }).then(res=>{
            console.log(res)
            setPeopleArray(res.rows)
            if (res.rows.length === 1){
                setPeopleListLocation(-1)
            }else if(res.rows.length === 2){
                setPeopleListLocation(0)
            }else {
                setPeopleListLocation(1)
            }
        })
    })
    return (
        <div className={'main_back'}>
            <PageHeader other={true}></PageHeader>
            <div className={'meeting_list_block'}>
                <div className={'meeting_list_title'}>{t('meeting')}</div>
                <div className={'meeting_list_text'}>{t('MeetingText')}</div>
                <div className={'meeting_list_popular_figures_block'}>
                    <span className={'meeting_list_popular_figures_title'}>{t('RecentMeetings')}</span>
                    <div className={'meeting_list_popular_figures'} style={{width: width}}>
                        <img onClick={() => clickLocaiton(true)} className={'meeting_list_arrow'}
                             src={require('@/assets/image/people/people_arrow_l.png')} alt='arrow'/>
                        <img onClick={() => clickLocaiton(false)} className={'meeting_list_arrow'}
                             src={require('@/assets/image/people/people_arrow_r.png')} alt='arrow'/>
                        <div className={'meeting_list_left_modal'}></div>
                        <div className={'meeting_list_right_modal'}></div>
                        {
                            peopleArray.map((item, index) => {
                                return (
                                    <Link to={`/meeting/detail/${item.id}`}>
                                        <div className={'meeting_list_popular_figures_item'}
                                             style={{left: -407 * peopleListLocation,overflow: "hidden"}}>
                                            <img className={'meeting_list_popular_figures_item_img'}
                                                 src={baseUrl + item.meetingUrl}
                                                 alt='logo'/>
                                            <div className={'meeting_list_popular_figures_item_name'}>{item.theme}</div>
                                            <div className={'meeting_list_popular_figures_item_time'}>
                                                <img src={require('../../../assets/image/meeting/time_icon.png')} alt='time'/>
                                                {item.startTime}~{item.endTime}
                                            </div>
                                        </div>
                                    </Link>
                                )
                            })
                        }
                    </div>
                </div>
                <div className={'meeting_list_popular_figures_block meeting_list_character_block'}
                     style={{paddingBottom: 45}}>
                    <span className={'meeting_list_popular_figures_title'}
                          style={{marginLeft: 0}}>{t('MeetingList')}</span>
                    <div className={'meeting_list_character_main'}>
                        <div className={'meeting_list_character_sort'}>
                            <div className={'meeting_list_character_sort_block'}>
                                <div className={'project_list_left_menu_top_grid_item'} onClick={() => checkHottest(0)}>
                                    {requestType !== 0 && (
                                        <img src={require('@/assets/image/project/sort_icon.png')} alt='sort'/>)}
                                    {(requestType === 0 && requestSort === 1) && (
                                        <img src={require('@/assets/image/project/sort_icon_up.png')} alt='sort'/>)}
                                    {(requestType === 0 && requestSort === 0) && (
                                        <img src={require('@/assets/image/project/sort_icon_down.png')} alt='sort'/>)}
                                    {t('Hottest')}
                                </div>
                                <div className={'project_list_left_menu_top_grid_item'}
                                     onClick={() => checkRecently(1)}>
                                    {requestType !== 1 && (
                                        <img src={require('@/assets/image/project/sort_icon.png')} alt='sort'/>)}
                                    {(requestType === 1 && requestSort === 1) && (
                                        <img src={require('@/assets/image/project/sort_icon_up.png')} alt='sort'/>)}
                                    {(requestType === 1 && requestSort === 0) && (
                                        <img src={require('@/assets/image/project/sort_icon_down.png')} alt='sort'/>)}
                                    {t('Recently')}
                                </div>
                            </div>
                        </div>
                        <div className={'meeting_list_character_list'}>
                            {
                                tableData.list.map((item) => {
                                    return (
                                        <Link to={`/meeting/detail/${item.id}`}>
                                            <div style={{overflow: "hidden"}}
                                                 className={'meeting_list_popular_figures_item'}>
                                                <img className={'meeting_list_popular_figures_item_img'}
                                                     src={baseUrl + item.meetingUrl} alt='logo'/>
                                                <div style={{height: 60, boxSizing: 'border-box'}}
                                                     className={'meeting_list_popular_figures_item_name'}>{item.theme}</div>
                                                <div className={'meeting_list_popular_figures_item_time'}>
                                                    <img src={require('../../../assets/image/meeting/time_icon.png')}
                                                         alt='time'/>
                                                    {dayjs(item.startTime).format('YYYY-MM-DD HH:mm')}~{dayjs(item.endTime).format('YYYY-MM-DD HH:mm')}
                                                </div>
                                            </div>
                                        </Link>
                                    )
                                })
                            }
                        </div>
                        <div className={'meeting_page_block'}>
                            <Pagination total={tableData.total} size={12} page={pageNum}
                                        onChange={changePage}></Pagination>
                        </div>
                    </div>
                </div>
            </div>
            <PageFooter></PageFooter>
        </div>
    )
}

export default List;
