import "../../included_module.scss"
import PageHeader from "@/components/PageHeader";
import {useTranslation} from "react-i18next";
import {Button, Checkbox, Form, Input, DatePicker, Select, Spin} from 'antd';
import Upload from '@/components/Upload/index'
import { SearchOutlined } from '@ant-design/icons';
import {useSelector} from "react-redux";
import {useEffect, useState} from "react";
import Radio from '@/components/Radio/index'
import moreApi from '@/api/more'
import useSyncCallback from "../../../../utils/useSyncCallback";
import message from "../../../../utils/message";
import {projectTypeObj} from "../../../../enum/projectType";
import {useNavigate} from "react-router-dom";
import {setReview} from "../../../../utils/middle";
import {hackathonContract} from "../../../../utils/web3Utils";
import {auditStatusObj} from "../../../../enum/auditStatus";
import commonApi from '@/api/common'
import userApi from '@/api/user'
import dayjs from "dayjs";
import Modal from '@/components/Modal/index'

function Hackathon() {
    const navigate = useNavigate()
    const {t} = useTranslation()
    const regStr = new RegExp('[a-zA-Z0-9][-a-zA-Z0-9]{0,62}(\\.[a-zA-Z0-9][-a-zA-Z0-9]{0,62})+\\.?');
    const [form] = Form.useForm()
    const baseUrl = process.env.REACT_APP_BASE_URL
    const color = useSelector(state => state.color.value)
    const media = useSelector(state => state.media.value)
    const walletAddress = useSelector(state => state.walletAddress.value)
    const inlineFormStyle = {
        width: '600px',
        marginRight: '102px'
    }
    const [logoUrl,setLogoUrl] = useState('')
    const [name, setName] = useState('')
    const [content, setContent] = useState('')
    const [remarkModel, setRemarkModel] = useState(false)
    const [time,setTime] = useState('')
    const [link, setLink] = useState('')
    const [winnersList, setWinnersList] = useState([])
    const [spinning, setSpinning] = useState(false)
    const winnersListItem = {
        name: '',
        detail: ''
    }

    function labelNode(title,text) {
        return (
            <>
                <span className={'included_label_title'}>{title}</span>
                {text && (<span className={'included_label_text'}>{text}</span>)}
            </>
        )
    }

    const submitForm = useSyncCallback((type)=>{
        setSpinning(true)
        const data = {
            logoUrl,name,content,time: time?time.format("YYYY-MM-DD"):'',link,winnersList,lang: 0,submitType: type,remark: projectTypeObj.hackathon.type,createTimeString: dayjs(Date.now()).format("YYYY-MM-DD HH:mm:ss")
        }
        if (type === 0) {
            moreApi.submitHackForm(data).then(res=>{
                setSpinning(false)
                message.success(t('saveSuccess'))
            })
        }else if (type === 1){
            if (!data.logoUrl || !data.name || !data.content || !data.time || !data.link) {
                setSpinning(false)
                return message.fail(t('completeInfo'))
            }
            moreApi.submitHackForm(data).then(res=>{
                const obj = res.data
                hackathonContract.methods.createWiki(obj.mId,obj.cId,obj.lang,obj.qmHash).send({from: walletAddress}).then((res)=>{
                    console.log('上链参数',res)
                    if (res.status === 1n) {
                        commonApi.updateTransactionHash({
                            qmHash: obj.qmHash,
                            transactionHash: res.transactionHash
                        }).then(_=>{
                            userApi.updateAuditStatus({
                                projectId: obj.cId,
                                status: auditStatusObj["unaudited"].type
                            }).then(_=>{
                                message.success(t('saveSuccess'))
                                setSpinning(false)
                            })
                        })
                    }else {
                        message.fail(t('DenialAuthorization'))
                        setSpinning(false)
                    }
                    setSpinning(false)
                }).catch((err)=>{
                    message.fail(t('DenialAuthorization'))
                    moreApi.delHack(obj.cId)
                    commonApi.delExamineDataByQmHash(obj.qmHash)
                    setSpinning(false)
                })
            })
        }else {
            setReview(data,(res)=>{
                setSpinning(false)
                navigate(`/more/hackathon/review?id=${res.data}`)
            })
        }

    })

    function formFinish(e) {
        moreApi.queryCheckNameHack(e.name).then(res=>{
            console.log(res)
            if(res.data === 0){
                submitForm(1)
            }else {
                setRemarkModel(true)
            }
        })
    }


    return (
        <>
            <div className={'main_back pb-60'}>
                <PageHeader other={true}></PageHeader>
                <div className={'included_title included_title_other'}>{t('OtherTitle')}</div>
                <div className={'included_text'}>{t('projectIncluedText')}</div>
                <div className={'included_form'}>
                    <Form layout="vertical" name="project" autoComplete="off" form={form} onFinish={formFinish}>
                        <Form.Item name="logo" label={labelNode('Logo', t('projectIncluedLogoText'))}
                                   rules={[{required: true}]}>
                            <Upload onSuccess={e => {
                                setLogoUrl(e.data)
                                form.setFieldsValue({logo: e.data})
                            }}>
                                {
                                    !logoUrl && (
                                        <div className={'included_upload_logo'}>
                                            <img src={require("@/assets/image/included/upload_icon.png")} alt='upload'/>
                                        </div>
                                    )
                                }
                                {
                                    logoUrl && (
                                        <img src={baseUrl + logoUrl} className={'included_upload_logo'} alt='icon'/>
                                    )
                                }
                                <div className={'included_upload_logo_limit'}>{t('uploadImageLimit')}</div>
                            </Upload>
                        </Form.Item>
                        <div className={'flex'}>
                            <Form.Item style={inlineFormStyle} name={'name'} label={labelNode(t('name'))}
                                       rules={[{required: true}]}>
                                <Input value={name} onChange={e => setName(e.target.value)}
                                       className={"included_form_input"} placeholder={t("namePlaceholder")}/>
                            </Form.Item>
                            <Form.Item style={inlineFormStyle} name={'time'} label={labelNode(t('time'))} rules={[{required: true}]}>
                                <DatePicker value={time} onChange={(e) => {
                                    setTime(e)
                                }} className={"included_form_input included_form_datepicker"} inputReadOnly/>
                            </Form.Item>
                        </div>
                        <div className={'flex'}>
                            <Form.Item style={inlineFormStyle} name={'briefIntroduction'}
                                       label={labelNode(t('briefIntroduction'))}
                                       rules={[{required: true}]}>
                                <Input value={content} onChange={e => setContent(e.target.value)}
                                       className={"included_form_input"}/>
                            </Form.Item>
                            <Form.Item style={inlineFormStyle} name={'link'}
                                       label={labelNode(t('link'))} rules={[{required: true},{pattern: regStr, message:t('linkFail')}]}>
                                <Input value={link} onChange={e => setLink(e.target.value.replace(/\s/g, ""))}
                                       className={"included_form_input"}/>
                            </Form.Item>
                        </div>
                        <Form.Item name={'AwardList'}
                                   label={labelNode(t('AwardList'))}>
                            {winnersList.length > 0 && (
                                <div className={'included_form_bottom_table'}>
                                    <div
                                        className={'included_form_bottom_table_head_line included_form_bottom_table_head_line3'}>
                                        <div className={'included_form_bottom_table_head'}>
                                            <img src={require("@/assets/image/included/icon_name.png")} alt='name'/>
                                            {t('name')}
                                        </div>
                                        <div className={'included_form_bottom_table_head'}>
                                            <img src={require("@/assets/image/included/icon_round.png")}
                                                 alt='round'/>
                                            {t('Round')}
                                        </div>
                                    </div>
                                    {
                                        winnersList.map((item, index) => {
                                            return (
                                                <div key={index}
                                                     className={'included_form_bottom_table_head_line included_form_bottom_table_head_line3'}
                                                     style={{marginTop: 8}}>
                                                    <Input value={item.name} onChange={e => {
                                                        setWinnersList(item => {
                                                            let list = [...item]
                                                            list[index].name = e.target.value
                                                            return [...list]
                                                        })
                                                    }} placeholder='--' className={'included_form_table_input'}/>
                                                    <Input value={item.detail} onChange={e => {
                                                        setWinnersList(item => {
                                                            let list = [...item]
                                                            list[index].detail = e.target.value
                                                            return [...list]
                                                        })
                                                    }} placeholder='--' className={'included_form_table_input'}/>
                                                    <div className='included_form_event_del'
                                                         style={{lineHeight: '50px'}}
                                                         onClick={() => setWinnersList(e => {
                                                             return e.filter((item, key) => index !== key)
                                                         })}>{t('delete')}</div>
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                            )}
                            <div className={'included_form_tag_add_button included_media_add'} onClick={() => {
                                setWinnersList((e) => {
                                    return [...e, winnersListItem]
                                })
                            }}>
                                <img src={require('@/assets/image/included/add_icon.png')}/>
                                {t('ContinueToAdd')}
                            </div>
                        </Form.Item>
                        <Form.Item>
                            <div className={'included_form_handler_button'}>
                                <div className={'included_form_handler_button_left'}>
                                    <div onClick={() => submitForm(2)}
                                         className={'included_form_handler_button_item included_form_handler_button_preview'}>{t('preview')}</div>
                                    <div onClick={() => submitForm(0)}
                                         className={'included_form_handler_button_item included_form_handler_button_SaveDraft'}>{t('SaveDraft')}</div>
                                </div>
                                <button htmlType="submit"
                                     className={'included_form_handler_button_item included_form_handler_button_SubmitInclusion'}>{t('SubmitInclusion')}</button>
                            </div>
                        </Form.Item>
                    </Form>

                </div>
            </div>
            <Modal open={remarkModel}>
                <div className='project_comment_dialog'>
                    <img onClick={() => setRemarkModel(false)} className={'project_comment_dialog_close'}
                         src={require('@/assets/image/home/dialog_close.png')} alt='close'/>
                    <div className='project_comment_dialog_title'>{t('remind')}</div>
                    <div className='project_comment_dialog_text' style={{color: '#FF2929'}}>{t('remind_text')}</div>
                    <div className='project_handle_btn'>
                        <div onClick={() => setRemarkModel(false)}>{t('close')}</div>
                        <div onClick={()=> {
                            setRemarkModel(false)
                            submitForm(1)
                        }} className='project_handle_btn_confirm'>{t('SubmitInclusion')}</div>
                    </div>
                </div>
            </Modal>
            <Spin spinning={spinning} fullscreen size="large"/>
        </>
    )
}

export default Hackathon;
