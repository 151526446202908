import {RouterProvider, useNavigate, useRoutes} from "react-router-dom";
import {ConfigProvider, Input} from "antd";
import { useSelector } from 'react-redux';
import './i18n/i18n.js'
import { useDispatch } from 'react-redux'
import userApi from '@/api/user'
import {setUserInfo} from "./features/user/userSlice";
import {useEffect, useState} from "react";
import message from "./utils/message";
import Web3 from "web3";
import {useTranslation} from "react-i18next";
import useSyncCallback from "./utils/useSyncCallback";
import Modal from "./components/Modal";
import {auditStatusObj} from "./enum/auditStatus";
import {Login, Logout} from "./features/auth/authSlice";
import BeforeEach from "./router/beforeEach";
import routes from './router/index'
import {walletEqualsStatus} from "./features/wallet/walletSlice";
import loginApi from "@/api/login"
import {setWalletAddress} from "./features/walletAddress/walletAddressSlice";
import {playerContract} from "./utils/web3Utils";
import {ethers} from "ethers";


function App() {
    const dispatch = useDispatch()
    const element = useRoutes(routes)
    const {t} = useTranslation()
    const auth = useSelector(state => state.auth.value)
    const lang = useSelector(state => state.language.value)
    const color = useSelector(state => state.color.value)
    const wallet = useSelector(state => state.wallet.value)
    const navigate = useNavigate()
    const [account, setAccount] = useState()
    const [modalOpen, setModalOpen] = useState(false)
    if (color === 'white') {
        document.documentElement.setAttribute('theme', 'light');
        sessionStorage.setItem('theme','white')
    }else {
        document.documentElement.setAttribute('theme', 'dark');
        sessionStorage.setItem('theme','dark')
    }

    const getUserInfo = useSyncCallback(()=>{
        if (localStorage.getItem("auth")) {
            userApi.queryUserInfo().then(res=>{
                dispatch(setUserInfo(res.data))
            })
        }
    })

    function connectWallet() {
        if(window.ethereum === void 0) {
            message.fail(t("installMetaMask"))
        }else {
            window.ethereum.request({ method: 'eth_requestAccounts' }).then(res=>{
                const address = res[0]
                const timeStampString = new Date().getTime().toString()
                window.ethereum.request({
                    method: "personal_sign",
                    params: [`login&time=${timeStampString}`, address],
                }).then(_r=>{
                    const data = {
                        timestamp: timeStampString,
                        signature: _r,
                        address
                    }
                    loginApi.walletLogin(data).then(res=>{
                        dispatch(setWalletAddress(address))
                        dispatch(Login(res.data.tokenValue))
                        getUserInfo()
                        window.location.reload()
                    })
                }).catch(_e=>{
                    message.fail(_e.message)
                })
            }).catch(err=>{
                message.fail(err.message)
            })
        }
    }

    useEffect(() => {
        if (auth || localStorage.getItem("auth")) {
            if(window.ethereum === void 0) {
                message.fail(t("installMetaMask"))
            }else {
                window.ethereum.request({ method: 'wallet_switchEthereumChain',params:[{chainId: ethers.utils.hexlify(137)}]})
                const web3 = new Web3(window.ethereum)
                web3.eth.getAccounts().then((res)=>{
                    if (res.length === 0) {
                        window.ethereum.request({ method: 'eth_requestAccounts' })
                    }else {
                        setAccount(res[0])
                        dispatch(setWalletAddress(res[0]))
                    }
                    console.log(1412142141241)
                    playerContract.methods.getUserInfo(res[0]).call().then((result)=>{
                        console.log('result',result)
                    }).catch(err=>{
                        console.log('err',err)

                    })
                    getUserInfo()
                })
            }
            window.ethereum.on("accountsChanged", function(accounts) {
                dispatch(walletEqualsStatus(true))
            });
        }else {
            getUserInfo()
        }
    }, []);

  return (
      <>
          <ConfigProvider theme={{
              components: {
                  Collapse: {
                      headerBg: 'rgba(0,0,0,0)'
                  },
              }
          }} locale={lang.File}>
              <BeforeEach>
                  {element}
              </BeforeEach>
          </ConfigProvider>
          <Modal open={wallet}>
              <div className='project_comment_dialog'>
                  <div className='project_comment_dialog_title'>{t('SwitchWallets')}</div>
                  <div style={{padding:'0 30px'}}>{t("SwitchWalletsNotice")}</div>
                  <div className='project_handle_btn'>
                      <div onClick={() => {
                          dispatch(walletEqualsStatus(false))
                          dispatch(Logout())
                      }}>{t('cancle')}</div>
                      <div onClick={connectWallet}
                           className='project_handle_btn_confirm'>{t('confirm')}</div>
                  </div>
              </div>
          </Modal>
      </>
  )
}

export default App
