import { Enum } from '@/utils/enum'

export const mediaListObj = Enum({
    Website: {whiteIcon: require("../assets/image/media/web.png"),darkIcon: require("../assets/image/media/web_dark.png"),whiteIconCircle: require("../assets/image/media/web_circle.png"),darkIconCircle: require("../assets/image/media/web_circle_dark.png")},
    Discord: {whiteIcon: require("../assets/image/media/discord.png"),darkIcon: require("../assets/image/media/discord_dark.png"),whiteIconCircle: require("../assets/image/media/discord_circle.png"),darkIconCircle: require("../assets/image/media/discord_circle_dark.png")},
    Twitter: {whiteIcon: require("../assets/image/media/twitter.png"),darkIcon: require("../assets/image/media/twitter_dark.png"),whiteIconCircle: require("../assets/image/media/twitter_circle.png"),darkIconCircle: require("../assets/image/media/twitter_circle_dark.png")},
    Telegram: {whiteIcon: require("../assets/image/media/telegram.png"),darkIcon: require("../assets/image/media/telegram_dark.png"),whiteIconCircle: require("../assets/image/media/telegram_circle.png"),darkIconCircle: require("../assets/image/media/telegram_circle_dark.png")},
    Medium: {whiteIcon: require("../assets/image/media/medium.png"),darkIcon: require("../assets/image/media/medium_dark.png"),whiteIconCircle: require("../assets/image/media/medium_circle.png"),darkIconCircle: require("../assets/image/media/medium_circle_dark.png")},
    Snapshot: {whiteIcon: require("../assets/image/media/snapshot.png"),darkIcon: require("../assets/image/media/snapshot_dark.png"),whiteIconCircle: require("../assets/image/media/snapshot_circle.png"),darkIconCircle: require("../assets/image/media/snapshot_circle_dark.png")},
    Github: {whiteIcon: require("../assets/image/media/github.png"),darkIcon: require("../assets/image/media/github_dark.png"),whiteIconCircle: require("../assets/image/media/github_circle.png"),darkIconCircle: require("../assets/image/media/github_circle_dark.png")},
    Blog: {whiteIcon: require("../assets/image/media/blog.png"),darkIcon: require("../assets/image/media/blog_dark.png"),whiteIconCircle: require("../assets/image/media/blog_circle.png"),darkIconCircle: require("../assets/image/media/blog_circle_dark.png")},
    Forum: {whiteIcon: require("../assets/image/media/web.png"),darkIcon: require("../assets/image/media/web_dark.png"),whiteIconCircle: require("../assets/image/media/web_circle.png"),darkIconCircle: require("../assets/image/media/web_circle_dark.png")},
    Linkedin: {whiteIcon: require("../assets/image/media/ins.png"),darkIcon: require("../assets/image/media/ins_dark.png"),whiteIconCircle: require("../assets/image/media/ins_circle.png"),darkIconCircle: require("../assets/image/media/ins_circle_dark.png")},
    Instagram: {whiteIcon: require("../assets/image/media/ins.png"),darkIcon: require("../assets/image/media/ins_dark.png"),whiteIconCircle: require("../assets/image/media/ins_circle.png"),darkIconCircle: require("../assets/image/media/ins_circle_dark.png")},
})

export const getMediaIcon = (index,type)=>{
    console.log('websiteKey',index)
    if (type === "white") {
        return mediaListObj[index].whiteIcon
    }else {
        return mediaListObj[index].darkIcon
    }
}

export const getMediaCircleIcon = (index,type)=>{
    console.log('websiteKey',index)
    if (type === "white") {
        return mediaListObj[index].whiteIconCircle
    }else {
        return mediaListObj[index].darkIconCircle
    }
}
