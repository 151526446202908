import request from '@/utils/request'

class Api {
    submitKolForm(data) {
        return request.post('/kolInclusion/add',data)
    }

    submitProjectForm(data) {
        return request.post('/projectInclusion/add',data)
    }

    submitInvestorForm(data) {
        return request.post('/investorInclusion/add',data)
    }

    submitPeopleForm(data) {
        return request.post('/figureInclusion/add',data)
    }

    submitMeeingForm(data) {
        return request.post('/meetingInclusion/add',data)
    }

    submitKolForm(data) {
        return request.post('/kolInclusion/add',data)
    }

    submitToolForm(data) {
        return request.post('/toolInclusion/add',data)
    }

    submitHackForm(data) {
        return request.post('/hackathonInclusion/add',data)
    }
}

export default new Api();
