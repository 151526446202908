import '../user_module.scss'
import {Select} from "antd";
import Kol from "./kol";
import {useEffect, useState} from "react";
import People from "./people";
import Meeting from "./meeting";
import Other from "./other";
import Investor from "./investor";
import Project from "./project";
import {projectTypeObj} from "../../../enum/projectType";
import {useTranslation} from "react-i18next";
import useSyncCallback from "../../../utils/useSyncCallback";
import userApi from "../../../api/user";
import Pagination from "../../../components/Pagination";
import {useSelector} from "react-redux";
import {querySubmissionAllList} from "../../../utils/middle";
import All from './all'

function Submissions() {
    const {t} = useTranslation()
    const lang = useSelector(state => state.language.value)
    const [type,setType] = useState("")
    const [options,setOptions] = useState()
    const [listData,setListData] = useState({
        total:0,
        list:[]
    })
    const [pageNum,setPageNum] = useState(1)
    const [pageSize,setPageSize] = useState(9)

    useEffect(() => {
        let list = []
        for (const item in projectTypeObj) {
            if (projectTypeObj[item].type !== "6" && projectTypeObj[item].type !== "7") {
                list.push({
                    value: projectTypeObj[item].type,
                    label: t(projectTypeObj[item].label)
                })
                setOptions(()=>{
                    return [{value: '',label: t('all')},...list]
                })
                getInclusionList()
            }
        }
    }, []);

    useEffect(()=>{
        setListData(()=>{
            return {
                total: 0,
                list: []
            }
        })
        getInclusionList()
    },[type])

    const getInclusionList = useSyncCallback(()=>{
        if (type === "") {
            return querySubmissionAllList({pageNum,pageSize,lang: lang.value},(res)=>{
                setListData(()=>{
                    return {
                        total : res.total,
                        list : res.rows
                    }
                })
            })
        }
        userApi.queryUserInclusion({
            pageNum,
            pageSize,
            type: type,
            lang: lang.value
        }).then(res=>{
            console.log(res)
            setListData(()=>{
                return {
                    total: res.total,
                    list: res.rows
                }
            })
        })
    })

    function changePage(e) {
        setPageNum(e)
        getInclusionList()
    }

    return (
        <div className={'profile_main_block'}>
            <div className={'profile_main_block_title'}>{t('MySubmissions')}</div>
            <div className={'collection_select'}>
                <Select defaultValue={type} options={options} style={{width: 234, height: 32}} onChange={(e)=>{
                    setListData({total: 0, list: []})
                    setType(e)
                    setPageSize(()=>{
                        let count = 9
                        switch (e) {
                            case "2":
                                count = 8
                                break;
                            case "3":
                            case "4":
                                count = 6
                                break;
                        }
                        return count
                    })
                }}/>
            </div>
            {listData.list.length > 0 && <div className={'collection_list'}>
                {type === "" && (<All list={listData.list}/>)}
                {type === "0" && (<Project list={listData.list}/>)}
                {type === "1" && (<Investor list={listData.list}/>)}
                {type === "2" && (<People list={listData.list}/>)}
                {type === "3" && (<Meeting list={listData.list}/>)}
                {type === "4" && (<Kol list={listData.list}/>)}
                {(type === "5" || type === "6" || type === "7") && (<Other type={type} list={listData.list}/>)}
                <div className={'collection_page_block'}>
                    <Pagination total={listData.total} size={pageSize} page={pageNum}
                                onChange={changePage}></Pagination>
                </div>
            </div>}
        </div>
    )
}

export default Submissions;
