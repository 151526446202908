import PageHeader from "../../../components/PageHeader";
import {useParams, useSearchParams} from "react-router-dom";
import {useEffect, useState} from "react";
import Project from './Detail/project'
import Investor from './Detail/investor'
import People from './Detail/people'
import Meeting from './Detail/meeting'
import Kol from './Detail/kol'
import Tool from './Detail/tool'
import Hack from './Detail/hack'
import {useTranslation} from "react-i18next";

function Contrast() {
    const {oId,nId} = useParams()
    const [searchParams,setSearchParams] = useSearchParams()
    const {t} = useTranslation()
    const [type,setType] = useState()

    useEffect(() => {
        setType(searchParams.get('type'))
    }, []);
    return (
        <>
            <div className='main_back'>
                <PageHeader other={true}></PageHeader>
                <div className='contrastBlock'>
                    <div className='contrastBlock_left contrastBlock_item'>
                        <div className='contrastBlock_item_text'>{t('updateBefore')}</div>
                        <div style={{width: 1920}}>
                            {type === '0' && (<Project id={oId}></Project>)}
                            {type === '1' && (<Investor id={oId}></Investor>)}
                            {type === '2' && (<People id={oId}></People>)}
                            {type === '3' && (<Meeting id={oId}></Meeting>)}
                            {type === '4' && (<Kol id={oId}></Kol>)}
                            {type === '6' && (<Tool id={oId}></Tool>)}
                            {type === '7' && (<Hack id={oId}></Hack>)}
                        </div>
                    </div>
                    <div className='contrastBlock_right contrastBlock_item'>
                        <div className='contrastBlock_item_text'>{t('updateAfter')}</div>
                        <div style={{width: 1920}}>
                            {type === '0' && (<Project id={nId} newDetail></Project>)}
                            {type === '1' && (<Investor id={nId} newDetail></Investor>)}
                            {type === '2' && (<People id={nId} newDetail></People>)}
                            {type === '3' && (<Meeting id={nId} newDetail></Meeting>)}
                            {type === '4' && (<Kol id={nId} newDetail></Kol>)}
                            {type === '6' && (<Tool id={nId} newDetail></Tool>)}
                            {type === '7' && (<Hack id={nId} newDetail></Hack>)}
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Contrast;
