import '../../Project/Detail/project_detail_module.scss'
import {useParams, Link, useSearchParams} from 'react-router-dom'
import PageHeader from "@/components/PageHeader";
import PageFooter from "@/components/PageFooter";
import RouterLine from "@/components/RouterLine";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";
import {DatePicker, Input, Tooltip} from 'antd'
import TimeLine from "@/components/TimeLine";
import {useEffect, useState} from "react";
import Modal from '@/components/Modal'
import moreApi from '@/api/more'
import useSyncCallback from "../../../utils/useSyncCallback";
import Comment from '../../../components/Comment'
import DetailHandle from "../../../components/DetailHandle";
import {projectTypeObj} from "../../../enum/projectType";
import {getReview} from "../../../utils/middle";

function Review() {
    const [searchParams, setSearchParams] = useSearchParams()
    const { t } = useTranslation()
    const baseUrl = process.env.REACT_APP_BASE_URL
    const color = useSelector(state => state.color.value)
    const [isModalOpen,setIsModalOpen] = useState(false)
    const [updateModalOpen,setUpdateModalOpen] = useState(false)
    const [detailInfo,setDetailInfo] = useState({
        winnersList: []
    })

    function setComment() {
        setIsModalOpen(false)
    }

    const getDetail = useSyncCallback(()=>{
        getReview(searchParams.get("id"),(res)=>{
            setDetailInfo(res.data)
        })
    })

    useEffect(() => {
        getDetail()
    }, []);

    return (
        <div className={'main_back main_back_white'}>
            <PageHeader other={true}></PageHeader>
            <div className={'project_detail_main_block'}>

                <div className={'router_line_block'}>
                    <img src={require('@/assets/image/project/router_arrow.png')} className='rotateY-180' alt='arrow'/>
                    <Link to={-1}>
                        <span className={'router_line_block_color'}>{t('back')}</span>
                    </Link>
                </div>
                <div className={'project_detail_main'}>
                    <div className={'project_detail_main_left'}>
                        <div className={'project_detail_left_info'}>
                            <div className={'project_detail_left_info_content'}>
                                <img className={'project_detail_info_image'}
                                     src={baseUrl + detailInfo.logoUrl} alt='Empty'/>
                                <div className={'project_detail_info_text'}>
                                    <div className={'project_detail_info_name'}>{detailInfo.name}</div>
                                    <div onClick={() => {
                                        let url = detailInfo.link
                                        console.log()
                                        if (url.split("://")[0] === "http" || url.split("://")[0] === "https"){
                                            window.open(detailInfo.link)
                                        }else {
                                            window.open("http://"+url.split("://")[0])
                                        }
                                    }} className={'more_hackathon_info_context'}>Link
                                        <img
                                            src={color === 'white' ? require('../../../assets/image/more/more_link.png') : require('../../../assets/image/more/more_link_dark.png')}
                                            alt='link'/>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={'project_detail_details'}>
                            <div className={'project_detail_details_title'}>{t('details')}</div>
                            <div className={'project_detail_details_content'}>{detailInfo.content}</div>
                            <div className={'more_hackathon_detail_time'}>Time: <span>{detailInfo.time}</span></div>
                        </div>
                        {detailInfo.winnersList.length > 0 && <div className={'project_detail_details'}>
                            <div className={'project_detail_details_title'}>{t('AwardList')}</div>
                            {
                                detailInfo.winnersList && detailInfo.winnersList.map(item => {
                                    return (
                                        <div className={'more_award_list_item'}>
                                            <div className={'more_award_list_item_title'}>{item.name}</div>
                                            <div className={'more_award_list_item_text'}>{item.detail}</div>
                                        </div>
                                    )
                                })
                            }
                        </div>}
                    </div>
                    <div className={'project_detail_main_right'}></div>
                </div>
            </div>
            <PageFooter></PageFooter>
        </div>
    )
}

export default Review;
