import '../user_module.scss'
import {useSelector} from "react-redux";
import {Link} from "react-router-dom";
import {getMediaCircleIcon} from "../../../enum/mediaType";

function People(props) {
    const baseUrl = process.env.REACT_APP_BASE_URL
    const arrList = props.list || []
    const color = useSelector(state => state.color.value)
    return (
        <div className={'collection_list_block'}>
            <div className={'collection_list_grid_4'}>
                {
                    arrList.map((item)=>{
                        console.log("item",item)
                        return (
                            <Link to={`/people/detail/${item.id}?status=${item.status}`}>
                                <div className={'collection_people_list_character_list_item'}>
                                    { item.status === "0" && <img className={'submission_audit_image'} src={require('../../../assets/image/user/audit.png')} alt='audit'/>}
                                    { item.status === "1" && <img className={'submission_audit_image'} src={require('../../../assets/image/user/collecteo.png')} alt='audit'/>}
                                    { item.status === "2" && <img className={'submission_audit_image'} src={require('../../../assets/image/user/peview.png')} alt='audit'/>}
                                    <img style={{borderRadius: '50%'}} className={'collection_people_list_popular_figures_item_img'}
                                         src={baseUrl + item.headImgUrl}
                                         alt='logo'/>
                                    <div
                                        className={'collection_people_list_popular_figures_item_name'}>{item.name}</div>
                                    <div className={'collection_people_list_popular_figures_item_video_img'}>
                                        {
                                            item?.smcList.map((params)=>{
                                                return (
                                                    <img src={getMediaCircleIcon(params.webType,color)}
                                                         alt="params"/>
                                                )
                                            })
                                        }
                                    </div>
                                    <div className={'collection_people_list_popular_figures_item_tag'}>{item.workExperienceList[item.workExperienceList.length-1]?.remark}</div>
                                    <div className={'collection_people_list_popular_figures_item_tag2'}>{item.workExperienceList[item.workExperienceList.length-1]?.post}</div>
                                </div>
                            </Link>
                        )
                    })
                }
            </div>
        </div>
    )
}

export default People;
