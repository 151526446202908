import "../../included_module.scss"
import PageHeader from "@/components/PageHeader";
import {useTranslation} from "react-i18next";
import { Button, Checkbox, Form, Input, DatePicker, Select, Spin } from 'antd';
import Upload from '@/components/Upload/index'
import { SearchOutlined } from '@ant-design/icons';
import {useSelector} from "react-redux";
import {useEffect, useState} from "react";
import Radio from '@/components/Radio/index'
import moreApi from '@/api/more'
import useSyncCallback from "@/utils/useSyncCallback";
import message from "@/utils/message";
import {projectTypeObj} from "@/enum/projectType";
import commonApi from "@/api/common";
import {web3, playerContract, toolContract} from '../../../../utils/web3Utils'
import userApi from '@/api/user'
import {auditStatusObj} from '../../../../enum/auditStatus'
import {useNavigate} from "react-router-dom";
import {setReview} from "../../../../utils/middle";
import dayjs from "dayjs";
import Modal from '@/components/Modal/index'
// import

function Tool() {
    const {t} = useTranslation()
    const [form] = Form.useForm()
    const navigate = useNavigate()
    const regStr = new RegExp('[a-zA-Z0-9][-a-zA-Z0-9]{0,62}(\\.[a-zA-Z0-9][-a-zA-Z0-9]{0,62})+\\.?');
    const baseUrl = process.env.REACT_APP_BASE_URL
    const color = useSelector(state => state.color.value)
    const media = useSelector(state => state.media.value)
    const walletAddress = useSelector(state => state.walletAddress.value)
    const inlineFormStyle = {
        width: '600px',
        marginRight: '102px'
    }
    const [tab,setTab] = useState([])
    const [tabList,setTabList] = useState([])
    const [remarkModel, setRemarkModel] = useState(false)
    const [ecology,setEcology] = useState('')
    const [ecologyList,setEcologyList] = useState([])
    const [mediaList,setMediaList]=useState([{media: media[0].value,link: ''}])
    const [spinning, setSpinning] = useState(false)
    const radioList = [
        {value: 0,label: t('UnissuedCoins'),id: 0,text: ''},
        {value: 1,label: t('IssuedCoins'),id: 1,text: ''},
        {value: 2,label: t('Empty'),id: 2,text: t('EmptyText')},
        {value: 3,label: t('NoTokens'),id: 3,text: ''}
    ]
    const methodRedioList = [
        {value: 0,label: t("PublicOfferingPrice"),id: 0,text: ''},
        {value: 1,label: t("mining"),id: 0,text: ''}
    ]
    const companyList = [
        {value: 0, icon: require("@/assets/image/included/company_icon.png"),name: 'Quantstamp Quantstamp', node: (
                <div className={'included_form_audit_options_node'}>
                    <img src={require("@/assets/image/included/company_icon.png")}/>
                    Quantstamp Quantstamp
                </div>)},
        {value: 1, icon: require("@/assets/image/included/company_icon.png"), name: 'QQQQuantstamp Quantstamp', node: (
                <div className={'included_form_audit_options_node'}>
                    <img src={require("@/assets/image/included/company_icon.png")}/>
                    QQQQuantstamp Quantstamp
                </div>)},
        {value: 2, icon: require("@/assets/image/included/company_icon.png"), name: 'TTTTQuantstamp Quantstamp', node: (
                <div className={'included_form_audit_options_node'}>
                    <img src={require("@/assets/image/included/company_icon.png")}/>
                    TTTTQuantstamp Quantstamp
                </div>)},
        {value: 3, icon: undefined,name: t('OtherAuditFirms'), node: (
                <div className={'included_form_audit_options_node'}>
                    {t('OtherAuditFirms')}
                </div>)},
    ]
    const [coinRadio,setCoinRadio] = useState(null)
    const [methodRadio,setMethodRadio] = useState(null)
    const [operation,setOperation] = useState(null)
    const [auditReport,setAuditReport] = useState(null)
    const [company,setCompany] = useState(companyList[0].value)
    const [eventList,setEventList] = useState([])
    const [tagDataList, setTagDataList] = useState([])
    const [staked, setStaked] = useState(0)
    const eventSelectList = [
        {value: 0, label: t("TestOnlineCable")},
        {value: 1, label: t("MainOnlineCable")},
        {value: 2, label: t("AttackedAmountInUSDollars")},
    ]
    const [logoUrl, setLogoUrl] = useState('')
    const [name, setName] = useState('')
    const [officialWebsite, setOfficialWebsite] = useState('')
    const [synopsis,setSynopsis] = useState('')
    const [content,setContent] = useState('')
    const [reportAddress, setReportAddress] = useState('')

    function labelNode(title,text) {
        return (
            <>
                <span className={'included_label_title'}>{title}</span>
                {text && (<span className={'included_label_text'}>{text}</span>)}
            </>
        )
    }

    function addTabs() {
        if (tab) {
            console.log(tab)
            setTabList((e)=>{
                return [...new Set([...e,...tab])]
            })
            form.setFieldsValue({Tabs: tab})
            setTab([])
        }
    }

    function delTabs(index) {
        const list = [...tabList]
        list.map((item,key)=>{
            if (index === key){
                list.splice(index,1)
                setTabList(list)
            }
        })
    }

    function addEcology() {
        setEcologyList((e)=>{
            console.log(ecology)
            return [...e,ecology]
        })
        setEcology('')
    }

    function delEcology(index) {
        const list = [...ecologyList]
        list.map((item,key)=>{
            if (index === key){
                list.splice(index,1)
                setEcologyList(list)
            }
        })
    }

    function addMediaLink() {
        const data = {media: media[0].value,link: ''}
        const list = [...mediaList]
        list.push(data)
        setMediaList(list)
    }

    function handleChange(value) {
        console.log(value)
        setCompany(value)
    }

    function eventHandlerChange(value,index) {
        console.log(value,index)
        setEventList((e)=>{
            const list = [...e]
            list[index].cable = value
            return list
        })
    }

    const submitForm = useSyncCallback((type)=>{
        setSpinning(true)
        const data = {
            logoUrl,name,officialWebsite,ecology: ecologyList.toString(),synopsis,label: tabList.toString(),content,walletStatus: operation,wauditStatus: auditReport,reportAddress,lang: 0,submitType: type,remark: projectTypeObj.tool.type,createTimeString: dayjs(Date.now()).format("YYYY-MM-DD HH:mm:ss")
        }
        if (type === 0) {
            moreApi.submitToolsForm(data).then(res=>{
                setSpinning(false)
                message.success(t('saveSuccess'))
            })
        }else if (type === 1) {
            if (!data.logoUrl || !data.name || !data.label || !data.synopsis || !data.content || (data.walletStatus === null || data.walletStatus === void 0)) {
                setSpinning(false)
                return message.fail(t('completeInfo'))
            }
            moreApi.submitToolsForm(data).then(res=>{
                const obj = res.data
                toolContract.methods.createWiki(obj.mId,obj.cId,obj.lang,obj.qmHash).send({from: walletAddress}).then((res)=>{
                    console.log('上链参数',res)
                    if (res.status === 1n) {
                        commonApi.updateTransactionHash({
                            qmHash: obj.qmHash,
                            transactionHash: res.transactionHash
                        }).then(_=>{
                            userApi.updateAuditStatus({
                                projectId: obj.cId,
                                status: auditStatusObj["unaudited"].type
                            }).then(_=>{
                                message.success(t('saveSuccess'))
                            })
                        })
                    }else {
                        message.fail(t('DenialAuthorization'))
                    }
                    setSpinning(false)
                }).catch((err)=>{
                    console.log(err)
                    moreApi.delTools(obj.cId)
                    commonApi.delExamineDataByQmHash(obj.qmHash)
                    setSpinning(false)
                })
            })
        } else {
            setReview(data,(res)=>{
                setSpinning(false)
                navigate(`/more/tool/review?id=${res.data}`)
            })
        }
    })

    const getTagList = useSyncCallback((text)=>{
        commonApi.queryDictData({
            dictType: 'project_lable',
            dictLabel: text
        }).then(res=>{
            setTagDataList(res.rows)
        })
    })

    useEffect(()=>{
        if(window.ethereum === void 0) {
            message.fail(t("installMetaMask"))
        }else {
            web3.eth.getAccounts().then((res)=>{
                if (!res[0]) {
                    res = window.ethereum.request({method: 'eth_requestAccounts'})
                }
                getTagList()
                playerContract.methods.getUserInfo(res[0]).call().then((res)=>{
                    setStaked(web3.utils.fromWei(res["_stakedAmount"],'ether'))
                })
            })
        }
    },[])

    function formFinish(e) {
        moreApi.queryCheckNameTools(e.name).then(res=>{
            console.log(res)
            if(res.data === 0){
                submitForm(1)
            }else {
                setRemarkModel(true)
            }
        })
    }

    return (
        <>
            <div className={'main_back pb-60'}>
                <PageHeader other={true}></PageHeader>
                <div className={'included_title included_title_other'}>{t('OtherTitle')}</div>
                <div className={'included_text'}>{t('projectIncluedText')}</div>
                <div className={'included_form'}>
                    <Form layout="vertical" name="project" autoComplete="off" form={form} onFinish={formFinish}>
                        <Form.Item name="logo" label={labelNode('Logo', t('projectIncluedLogoText'))}
                                   rules={[{required: true}]}>
                            <Upload onSuccess={e => {
                                setLogoUrl(e.data)
                                form.setFieldsValue({logo: e.data})
                            }}>
                                {
                                    !logoUrl && (
                                        <div className={'included_upload_logo'}>
                                            <img src={require("@/assets/image/included/upload_icon.png")} alt='upload'/>
                                        </div>
                                    )
                                }
                                {
                                    logoUrl && (
                                        <img src={baseUrl + logoUrl} className={'included_upload_logo'} alt='icon'/>
                                    )
                                }
                                <div className={'included_upload_logo_limit'}>{t('uploadImageLimit')}</div>
                            </Upload>
                        </Form.Item>
                        <div className={'flex'}>
                            <Form.Item style={inlineFormStyle} name={'name'} label={labelNode(t('name'))}
                                       rules={[{required: true,message: 'Please input name!'}]}>
                                <Input value={name} onChange={e => setName(e.target.value)}
                                       className={"included_form_input"} placeholder={t("namePlaceholder")}/>
                            </Form.Item>
                            <Form.Item style={inlineFormStyle} name={'officialWebsite'}
                                       label={labelNode(t('officialWebsite'))} rules={[{required: true},{pattern: regStr, message:t('linkFail')}]}>
                                <Input className={"included_form_input"} value={officialWebsite}
                                       onChange={e => setOfficialWebsite(e.target.value.replace(/\s/g, ""))}
                                       placeholder={t("officialWebsitePlaceholder")}/>
                            </Form.Item>
                        </div>
                        <Form.Item name={'Tabs'} label={labelNode(t('Tabs'))} rules={[{required: true,message: 'Please check Tabs!'}]}>
                            <div className={'included_form_tag_input'}>
                                <Select
                                    style={{width: 750}} mode="multiple" filterOption={false} fieldNames={{label: 'dictLabel', value: 'dictLabel'}}
                                    className={'included_form_add_input included_form_input'}
                                    options={tagDataList} onSearch={getTagList} value={tab} onChange={e => {
                                    setTab(e)
                                }} suffixIcon={
                                    <img src={require('@/assets/image/included/search_icon.png')}
                                         style={{width: '19px', height: '19px'}}/>
                                }
                                />
                                <div className={'included_form_tag_add_button'} onClick={addTabs}>
                                    <img src={require('@/assets/image/included/add_icon.png')}/>
                                    Add
                                </div>
                            </div>
                            <div className={'included_tag_list'}>
                                {tabList.length > 0 && (
                                    <>
                                        {tabList.map((item, index) => {
                                            return (
                                                <div className={'included_tag_item'}>
                                                    {item}
                                                    <img onClick={() => delTabs(index)}
                                                         className={'included_tag_item_close'}
                                                         src={color === 'white' ? require('@/assets/image/included/close_white.png') : require("@/assets/image/included/close_dark.png")}/>
                                                </div>
                                            )
                                        })}
                                    </>
                                )}
                            </div>
                        </Form.Item>
                        <Form.Item name={'ProjectEcology'} label={labelNode(t('ProjectEcology'))}>
                            <div className={'included_form_tag_input'}>
                                <Input className={'included_form_add_input included_form_input'} value={ecology}
                                       onChange={(e) => {
                                           setEcology(e.target.value)
                                       }} suffix={
                                    <img src={require('@/assets/image/included/search_icon.png')}
                                         style={{width: '19px', height: '19px'}}/>
                                }/>
                                <div className={'included_form_tag_add_button'} onClick={addEcology}>
                                    <img src={require('@/assets/image/included/add_icon.png')}/>
                                    Add
                                </div>
                            </div>
                            <div className={'included_tag_list'}>
                                {ecologyList.length > 0 && (
                                    <>
                                        {ecologyList.map((item, index) => {
                                            return (
                                                <div className={'included_tag_item'}>
                                                    {item}
                                                    <img onClick={() => delEcology(index)}
                                                         className={'included_tag_item_close'}
                                                         src={color === 'white' ? require('@/assets/image/included/close_white.png') : require("@/assets/image/included/close_dark.png")}/>
                                                </div>
                                            )
                                        })}
                                    </>
                                )}
                            </div>
                        </Form.Item>
                        <Form.Item name={'ProjectIntroduction'} label={labelNode(t('ProjectIntroduction'))}
                                   rules={[{required: true,message: 'Please input project introduction!'}]}>
                            <Input value={synopsis} onChange={e => setSynopsis(e.target.value)} maxLength={100} showCount
                                   className={"included_form_input"}/>
                        </Form.Item>
                        <Form.Item name={'Introduction'} label={labelNode(t('Introduction'))}
                                   rules={[{required: true,message: 'Please input introduction!'}]}>
                            <Input.TextArea value={content} onChange={e => setContent(e.target.value)}
                                            className={'included_form_textarea included_form_input'}
                                            style={{height: 200, resize: 'none'}} maxLength={1500} showCount/>
                        </Form.Item>
                        <Form.Item name={'ConnectWalletTitle'} label={labelNode(t('ConnectWalletTitle'))}
                                   rules={[{required: true,message: 'Please check!'}]}>
                            <Radio list={[
                                {value: 0, label: t("need"), id: 0, text: ''},
                                {value: 1, label: t("notRequired"), id: 0, text: ''}
                            ]} onChange={(e) => setOperation(e)}></Radio>

                        </Form.Item>
                        <Form.Item>
                            <div className={'included_tools_ConnectWalletTitle'}>
                                <Radio list={[
                                    {value: 0, label: t("NoAuditReport"), id: 0, text: ''},
                                    {value: 1, label: t("HaveAuditReport"), id: 0, text: ''}
                                ]} onChange={(e) => {
                                    setAuditReport(e)
                                    setReportAddress('')
                                }}></Radio>
                                {
                                    auditReport === 1 && (
                                        <>
                                            <Upload type='pdf' onSuccess={e => setReportAddress(e.data)}>
                                                <div className={'included_upload_button'}>
                                                    <img src={require("@/assets/image/included/button_upload_icon.png")}
                                                         alt='icon'/>
                                                    <span>{t('UploadAuditReport')}</span>
                                                </div>
                                            </Upload>
                                            {reportAddress && (<div>{baseUrl + reportAddress}</div>)}
                                        </>
                                    )
                                }
                            </div>
                        </Form.Item>
                        <Form.Item>
                            <div className={'included_form_handler_button'}>
                                <div className={'included_form_handler_button_left'}>
                                    <div onClick={() => submitForm(2)}
                                         className={'included_form_handler_button_item included_form_handler_button_preview'}>{t('preview')}</div>
                                    <div onClick={() => submitForm(0)}
                                         className={'included_form_handler_button_item included_form_handler_button_SaveDraft'}>{t('SaveDraft')}</div>
                                </div>
                                <button htmlType="submit"
                                        className={'included_form_handler_button_item included_form_handler_button_SubmitInclusion'}>{t('SubmitInclusion')}</button>
                            </div>
                        </Form.Item>
                    </Form>

                </div>
            </div>
            <Modal open={remarkModel}>
                <div className='project_comment_dialog'>
                    <img onClick={() => setRemarkModel(false)} className={'project_comment_dialog_close'}
                         src={require('@/assets/image/home/dialog_close.png')} alt='close'/>
                    <div className='project_comment_dialog_title'>{t('remind')}</div>
                    <div className='project_comment_dialog_text' style={{color: '#FF2929'}}>{t('remind_text')}</div>
                    <div className='project_handle_btn'>
                        <div onClick={() => setRemarkModel(false)}>{t('close')}</div>
                        <div onClick={()=> {
                            setRemarkModel(false)
                            submitForm(1)
                        }} className='project_handle_btn_confirm'>{t('SubmitInclusion')}</div>
                    </div>
                </div>
            </Modal>
            <Spin spinning={spinning} fullscreen size="large"/>
        </>
    )
}

export default Tool;
