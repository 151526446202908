import './detailHandle_components.scss'
import useSyncCallback from "../../utils/useSyncCallback";
import commonApi from "../../api/common";
import message from "../../utils/message";
import {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";
import Modal from "../Modal";
import {Popover,Input} from "antd";
import {LinkOutlined,CopyOutlined} from "@ant-design/icons"
function DetailHandle({mainId,id,inclusionType,name,lang}) {
    const link = window.location.href
    const navigate = useNavigate()
    const {t} = useTranslation()
    const color = useSelector(state=>state.color.value)
    const userInfo = useSelector(state => state.user.value)
    const [collectionStatus,setCollectionStatus] = useState('')
    const [updateModalOpen,setUpdateModalOpen] = useState(false)
    const [likeStatusObj, setLikeStatusObj] = useState({
        likeStatus: 1,
        noLikeStatus: 1,
        likeCount: 0,
        noLikeCount: 0
    })

    const getLikeStatus = useSyncCallback(async ()=>{
        const result = await Promise.all([
            commonApi.queryLikeStatus({inclusionMainId: mainId,createBy: userInfo.userId}),
            commonApi.queryNoLikeStatus({inclusionMainId: mainId,createBy: userInfo.userId}),
            commonApi.queryLikeCount({inclusionMainId: mainId,inclusionType})
        ])
        console.log(result)
        setLikeStatusObj(()=>{
            return {
                likeStatus:  result[0].data,
                noLikeStatus: result[1].data,
                likeCount: result[2].data.likeCount,
                noLikeCount: result[2].data.nolikeCount
            }
        })
    })

    const getCollectionStatus = useSyncCallback(()=>{
        commonApi.queryCollectionStatus({
            inclusionMainId: mainId,
            createBy: userInfo.userId
        }).then(res=>{
            setCollectionStatus(res.data)
        })
    })

    const collectionAdd = useSyncCallback(()=>{
        const data = {
            inclusionMainId: mainId,
            inclusionType,
            mainId: mainId,
            name: name,
            lang: lang
        }
        commonApi.addCollectionData(data).then(res=>{
            message.success(t('alreadyConllect'))
            getCollectionStatus()
        })
    })

    const collectionCancel = useSyncCallback(()=>{
        commonApi.cancelCollectionData({
            inclusionMainId: mainId
        }).then(res=>{
            message.success(t('cancleConllect'))
            getCollectionStatus()
        })
    })

    const submitLike = useSyncCallback((type)=>{
        if (type === 1){
            if(likeStatusObj.noLikeStatus === 0) submitNoLike(0)
            commonApi.submitLike({
                inclusionMainId: mainId,
                inclusionType,
                name: name,
                status: 0,
                lang
            }).then(()=>{
                getLikeStatus()
            })
        }else {
            commonApi.submitNoLike({
                inclusionMainId: mainId,
                inclusionType,
                status: 1
            }).then(()=>{
                getLikeStatus()
            })
        }
    })

    const submitNoLike = useSyncCallback((type)=>{
        if(type === 1){
            if(likeStatusObj.likeStatus === 0) submitLike(0)
            commonApi.submitUnLike({
                inclusionMainId: mainId,
                inclusionType,
                status: 0
            }).then(()=>{
                getLikeStatus()
            })
        }else {
            commonApi.submitNoUnLike({
                inclusionMainId: mainId,
                inclusionType,
                status: 1
            }).then(()=>{
                getLikeStatus()
            })
        }
    })

    function toUpdatePage() {
        switch (inclusionType) {
            case "0":
                navigate(`/project/update/${id}`)
                break;
            case "1":
                navigate(`/investors/update/${id}`)
                break;
            case "2":
                navigate(`/people/update/${id}`)
                break;
            case "3":
                navigate(`/meeting/update/${id}`)
                break;
            case "4":
                navigate(`/kol/update/${id}`)
                break;
            case "6":
                navigate(`/more/tool/update/${id}`)
                break;
            case "7":
                navigate(`/more/hackathon/update/${id}`)
                break;
        }
    }

    function toUpdateLanguage() {
        switch (inclusionType) {
            case "0":
                navigate(`/project/update/${id}?type=lang`)
                break;
            case "1":
                navigate(`/investors/update/${id}?type=lang`)
                break;
            case "2":
                navigate(`/people/update/${id}?type=lang`)
                break;
            case "3":
                navigate(`/meeting/update/${id}?type=lang`)
                break;
            case "4":
                navigate(`/kol/update/${id}?type=lang`)
                break;
            case "6":
                navigate(`/more/tool/update/${id}?type=lang`)
                break;
            case "7":
                navigate(`/more/hackathon/update/${id}?type=lang`)
                break;
        }
    }

    function copyLink() {
        try {
            navigator.clipboard.writeText(link)
            message.success(t('copySuccess'))
        }catch (err) {
            message.success(t('copyFail'))
        }
    }

    useEffect(() => {
        getLikeStatus()
        getCollectionStatus()
    }, []);

    const content = ()=>{
        return (
            <div>
                <Input style={{width: 400}} readOnly addonBefore={<LinkOutlined />} addonAfter={(<div style={{cursor: "pointer"}} onClick={copyLink}>
                    <CopyOutlined/><span> 复制链接</span>
                </div>)} defaultValue={link}/>
            </div>
        )
    }

    return (
        <>
            <div className={'detailHandle_detail_left_handle'}>
                <div className='detailHandle_detail_left_handle_btn' onClick={() => {
                    if (collectionStatus === 1) collectionAdd()
                    else collectionCancel()
                }}>
                    {collectionStatus === 0 ? (<img
                            src={require('@/assets/image/project/review_like.png')}
                            alt='like'/>
                    ):(<img
                        src={color === 'white' ? require('@/assets/image/project/like.png') : require('@/assets/image/project/like_dark.png')}
                        alt='like'/>)}
            </div>
                <Popover placement="bottomLeft" content={content}>
                    <div className={'detailHandle_detail_left_handle_btn'}>
                        <img
                            src={color === 'white' ? require('@/assets/image/project/share.png') : require('@/assets/image/project/share_dark.png')}
                            alt='like'/>
                    </div>
                </Popover>
                <div className={'detailHandle_detail_left_handle_btn'} style={{marginRight: 22}}>
                    <img onClick={() => setUpdateModalOpen(true)}
                         src={color === 'white' ? require('@/assets/image/project/reload.png') : require('@/assets/image/project/reload_dark.png')}
                         alt='like'/>
                </div>
                <div className={'detailHandle_detail_line'}></div>
                <div className={'detailHandle_detail_good'}>
                    {likeStatusObj.likeStatus === 0 ? (<img onClick={() => submitLike(0)}
                                                            src={require('@/assets/image/project/good_color.png')}
                                                            alt='good'/>): (<img onClick={() => submitLike(1)}
                                                                                 src={color === 'white' ? require('@/assets/image/project/good.png') : require('@/assets/image/project/good_dark.png')}
                                                                                 alt='good'/>)}
                    {likeStatusObj.likeCount}
                </div>
                <div className={'detailHandle_detail_good'}>
                    {
                        likeStatusObj.noLikeStatus === 0 ? (<img onClick={() => submitNoLike(0)}
                                                                 src={require('@/assets/image/project/ungood_color.png')}
                                                                 alt='low'/>) : (<img onClick={() => submitNoLike(1)}
                                                                                      src={color === 'white' ? require('@/assets/image/project/low.png') : require('@/assets/image/project/low_dark.png')}
                                                                                      alt='low'/>)
                    }
                    {likeStatusObj.noLikeCount}
                </div>
            </div>
            <Modal open={updateModalOpen}>
                <div className={'detailHandle_comment_dialog'}>
                    <img onClick={() => setUpdateModalOpen(false)} className={'detailHandle_comment_dialog_close'}
                         src={require('@/assets/image/home/dialog_close.png')} alt='close'/>
                    <div className={'detailHandle_comment_dialog_title'}>{t('update')}</div>
                    <div className={'detailHandle_detail_update_block'}>
                        <div className={'detailHandle_detail_update_small_title'}>{t('updateSmallTitle')}</div>
                        <div className={'detailHandle_detail_update_btn_list'}>
                            <div onClick={toUpdatePage}>{t('updateContent')}</div>
                            <div onClick={toUpdateLanguage}>{t('updateLanguage')}</div>
                            <div>{t('InitiateVoting')}</div>
                        </div>
                        <div className={'detailHandle_detail_update_text'}>{t('updateText')}</div>
                    </div>
                </div>
            </Modal>
        </>
    )
}

export default DetailHandle;
