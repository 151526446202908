import ReactDOM from 'react-dom/client'
import App from './App.js'
import store from './app/store'
import { Provider } from 'react-redux'
import './index.css'
import './assets/css/white.module.css';
import './assets/css/dark.module.css';
import {BrowserRouter} from "react-router-dom";
import {AliveScope} from "react-activation";

ReactDOM.createRoot(document.getElementById('root')).render(
    <BrowserRouter>
        <Provider store={store}>
            <AliveScope>
                <App />
            </AliveScope>
        </Provider>
    </BrowserRouter>
)
