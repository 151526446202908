import PageHeader from "@/components/PageHeader";
import {useTranslation} from "react-i18next";
import PageFooter from "@/components/PageFooter";
import './project_list_module.scss'
import {Collapse, Input} from "antd";
import {useEffect, useState} from "react";
import {SearchOutlined} from "@ant-design/icons";
import Pagination from "@/components/Pagination";
import {useSelector} from "react-redux";
import {Link, useNavigate, useSearchParams} from 'react-router-dom'
import useSyncCallback from "@/utils/useSyncCallback";
import projectApi from '@/api/projects'
import commonApi from "../../../api/common";
import {getTranslation, getTranslationBatch, getTranslationSync} from "../../../utils/middle";


function List() {
    const navigate = useNavigate()
    const [searchParams, setSearchParams] = useSearchParams()
    const baseUrl = process.env.REACT_APP_BASE_URL
    const {t} = useTranslation()
    const color = useSelector(state => state.color.value)
    const lang = useSelector(state => state.language.value)
    const [tagObj,setTagObj] = useState({
        tag: false,
        coinStatus: false,
        financingStatus: false,
        Region: false
    })
    const [pageNum, setPageNum] = useState(1)
    const [tableList,setTableList] = useState([])
    const [total,setTotal] = useState()
    const [requestType,setRequestType] = useState()
    const [requestSort,setRequestSort] = useState(0)
    const [tagCheckList,setTagCheckList] = useState([])
    const [coinCheckList,setCoinCheckList] = useState([])
    const [finCheckList,setFinCheckList] = useState([])
    const [regionCheckList,setRegionCheckList] = useState([])
    const [tagList,setTagList] = useState([])
    const [coinStatusList,setCoinStatusList] = useState([])
    const [financingStatusList,setFinancingStatusList] = useState([])
    const [regionStatusList,setRegionStatusList] = useState([])
    const [issuance, setIssuance] = useState([])
    const [financing, setFinancing] = useState([])
    const [region, setRegion] = useState([])
    function changeTag(e) {
        const data = {
            tag: false,
            coinStatus: false,
            financingStatus: false,
            Region: false
        }
        for(const item of e){
            switch (item) {
                case '1':
                    data.tag = true
                    break
                case '2':
                    data.coinStatus = true
                    break
                case '3':
                    data.financingStatus = true
                    break
                case '4':
                    data.Region = true
                    break
            }
        }
        setTagObj(()=>data)
    }

    function changePage(page) {
        console.log(page)
        setPageNum(page)
        getProjectList()
    }

    const getProjectList = useSyncCallback(()=>{
        const labelList = tagCheckList.map(item=>{
            for (const data of tagList) {
                if (data.dictValue === item){
                    return data.dictLabel
                }
            }
        })
        projectApi.queryProjectsList({
            pageNum,
            pageSize: 12,
            lang: lang.value,
            sort: requestSort === void 0?0:requestSort,
            type: requestType === void 0?0:requestType,
            location: regionCheckList.toString() || void 0,
            label: labelList.toString() || void 0,
            coinIssuanceSatus: coinCheckList.toString() || void 0,
            financingStatus: finCheckList.toString() || void 0
        }).then(res=>{
            console.log(res)
            setTableList([...res.rows])
            setTotal(res.total)
        })
    })

    const checkHottest = useSyncCallback((type)=>{
        setRequestSort(()=>void 0)
        setPageNum(1)
        setRequestType(type || 0)
        if(requestSort !== void 0) {
            if (requestSort ===  1){
                setRequestSort(0)
            }else {
                setRequestSort(1)
            }
        }else {
            setRequestSort(0)
        }
        getProjectList()
    })

    const checkRecently = useSyncCallback((type)=>{
        setRequestSort(()=>void 0)
        setPageNum(1)
        setRequestType(type || 1)
        if(requestSort !== void 0) {
            if (requestSort ===  1){
                setRequestSort(0)
            }else {
                setRequestSort(1)
            }
        }else {
            setRequestSort(0)
        }
        getProjectList()
    })

    const getTagList = useSyncCallback((text)=>{
        commonApi.queryDictData({
            dictType: 'project_lable',
            dictLabel: text
        }).then(res=>{
            setTagList(res.rows)

            if (searchParams.get("tag")) {
                checkTag(void 0,searchParams.get("tag"))
            }
        })
    })

    const getCoinList = useSyncCallback((text)=>{
        if (text) {
            setCoinStatusList(()=>{
                return issuance.filter(item=>{
                    return item.dictLabel.includes(text)
                })
            })
        }else {
            commonApi.queryDictData({
                dictType: 'issuance_status'
            }).then(res=>{
                setCoinStatusList(()=>{
                    const isList = res.rows.map(item=>{
                        return {
                            ...item,
                            dictLabel: t(item.dictLabel)
                        }
                    })
                    setIssuance(isList)
                    return isList
                })
            })
        }
    })

    const getFinancing = useSyncCallback((text)=>{
        commonApi.queryDictData({
            dictType: 'financing_status',
            dictName: text
        }).then(res=>{
            setFinancingStatusList(()=>{
                const finList = res.rows.map(item=>{
                    return {
                        ...item,
                        dictLabel: item.dictLabel
                    }
                })
                setFinancing(finList)
                return finList
            })
        })
    })

    const getRegion = useSyncCallback((text)=>{
        if (text) {
            setRegionStatusList(()=>{
                return region.filter(item=>{
                    return item.dictLabel.includes(text)
                })
            })
        }else {
            commonApi.queryDictData({
                dictType: 'area'
            }).then(res=>{
                if (lang.value === 0) {
                    const arrList = res.rows.map(item=>item.dictLabel)
                    getTranslationBatch(arrList.toString(),"zh","en",(item)=>{
                        console.log(res)
                        const listText = item.data.TargetTextList.map((result,index)=>{
                            return {
                                ...res.rows[index],
                                dictLabel: result
                            }
                        })
                        setRegionStatusList(listText)
                        setRegion(listText)

                    })
                }else {
                    setRegionStatusList(()=>{
                        const finList = res.rows.map(item=>{
                            return {
                                ...item,
                                dictLabel: item.dictLabel
                            }
                        })
                        setRegion(finList)
                        return finList
                    })
                }
            })
        }
    })

    const checkTag = useSyncCallback((event,item)=>{
        console.log(item)
        event?.stopPropagation()
        const tagKey = tagList.find(res=>res.dictLabel === item)
        setTagCheckList((e)=>{
            const list = [...e]
            const key = list.indexOf(tagKey.dictValue)
            if (key !== -1) {
                list.splice(key,1)
                return list
            }
            list.push(tagKey.dictValue)
            return list
        })
    })

    useEffect(()=>{
        getTagList(void 0)
        getCoinList()
        getFinancing()
        getRegion()
        if (searchParams.get("location")) {
            setRegionCheckList((e)=>{
                const list = [...e]
                const key = list.indexOf(searchParams.get("location"))
                if (key !== -1) {
                    list.splice(key,1)
                    return list
                }
                list.push(searchParams.get("location"))
                return list
            })
        }
    },[])

    useEffect(()=>{
        getProjectList()
    },[tagCheckList,coinCheckList,finCheckList,regionCheckList])
    return (
        <div className={'main_back'}>
            <PageHeader grayBorder={true} other={true}></PageHeader>
            <div className={'project_list_main_view'}>
                <div className={'project_list_left_menu'}>
                    <div className={'project_list_left_menu_top_grid'}>
                        <div className={'project_list_left_menu_top_grid_item'} onClick={()=>checkHottest(0)}>
                            {requestType !== 0 && (<img src={require('@/assets/image/project/sort_icon.png')} alt='sort'/>)}
                            {(requestType === 0 && requestSort === 1) && (<img src={require('@/assets/image/project/sort_icon_up.png')} alt='sort'/>)}
                            {(requestType === 0 && requestSort === 0) && (<img src={require('@/assets/image/project/sort_icon_down.png')} alt='sort'/>)}
                            {t('Hottest')}
                        </div>
                        <div className={'project_list_left_menu_top_grid_item'} onClick={()=>checkRecently(1)}>
                            {requestType !== 1 && (<img src={require('@/assets/image/project/sort_icon.png')} alt='sort'/>)}
                            {(requestType === 1 && requestSort === 1) && (<img src={require('@/assets/image/project/sort_icon_up.png')} alt='sort'/>)}
                            {(requestType === 1 && requestSort === 0) && (<img src={require('@/assets/image/project/sort_icon_down.png')} alt='sort'/>)}
                            {t('Recently')}
                        </div>{/*
                        <div className={'project_list_left_menu_top_grid_item'}>
                            <img src={require('@/assets/image/project/sort_icon.png')} alt='sort'/>
                            {t('Like')}
                        </div>
                        <div className={'project_list_left_menu_top_grid_item'}>
                            <img src={require('@/assets/image/project/sort_icon.png')} alt='sort'/>
                            {t('collect')}
                        </div>*/}
                    </div>
                    <Collapse items={[
                        {
                            key: '1',
                            label: (
                                <div className={'project_list_tag'}>
                                    <span>{t('tag')}</span>
                                    <img
                                        src={tagObj.tag?require('@/assets/image/project/jian.png'):require('@/assets/image/project/jia.png')}
                                        alt='icon'/>
                                </div>
                            ),
                            children: (
                                <div className={'included_radio_check_project'}>
                                    <Input prefix={<SearchOutlined />} onChange={e=>getTagList(e.target.value)} className={"included_form_input project_form_input"} placeholder={t('Search')}/>
                                    {
                                        tagList.map((item,index)=> {
                                            return (
                                                <div key={index} onClick={()=>{
                                                    setTagCheckList((e)=>{
                                                        const list = [...e]
                                                        const key = list.indexOf(item.dictValue)
                                                        if (key !== -1) {
                                                            list.splice(key,1)
                                                            return list
                                                        }
                                                        list.push(item.dictValue)
                                                        return list
                                                    })
                                                }} className={'project_list_radio'} style={index === 0?{marginTop: 20}:{}}>
                                                    {
                                                        tagCheckList.indexOf(item.dictValue) !== -1 &&
                                                        (<img src={require('@/assets/image/Radio/radio_check_icon.png')} alt='check'/>)
                                                    }
                                                    {
                                                        tagCheckList.indexOf(item.dictValue) === -1 &&
                                                        (<div className={'radio_no_check'}></div>)
                                                    }
                                                    <span>{item.dictLabel}</span>
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                            ),
                            showArrow: false
                        },
                        {
                            key: '2',
                            label: (
                                <div className={'project_list_tag'}>
                                    <span>{t('CoinIssuanceStatus')}</span>
                                    <img
                                        src={tagObj.coinStatus ? require('@/assets/image/project/jian.png') : require('@/assets/image/project/jia.png')}
                                        alt='icon'/>
                                </div>
                            ),
                            children: (
                                <div className={'included_radio_check_project'}>
                                    <Input prefix={<SearchOutlined />} onChange={e=>getCoinList(e.target.value)} className={"included_form_input project_form_input"} placeholder={t('Search')}/>
                                    {
                                        coinStatusList.map((item,index)=> {
                                            return (
                                                <div key={index} onClick={()=>{
                                                    setCoinCheckList((e)=>{
                                                        const list = [...e]
                                                        const key = list.indexOf(item.dictValue)
                                                        if (key !== -1) {
                                                            list.splice(key,1)
                                                            return list
                                                        }
                                                        list.push(item.dictValue)
                                                        return list
                                                    })
                                                }} className={'project_list_radio'} style={index === 0?{marginTop: 20}:{}}>
                                                    {
                                                        coinCheckList.indexOf(item.dictValue) !== -1 &&
                                                        (<img src={require('@/assets/image/Radio/radio_check_icon.png')} alt='check'/>)
                                                    }
                                                    {
                                                        coinCheckList.indexOf(item.dictValue) === -1 &&
                                                        (<div className={'radio_no_check'}></div>)
                                                    }
                                                    <span>{item.dictLabel}</span>
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                            ),
                            showArrow: false
                        },
                        {
                            key: '3',
                            label: (
                                <div className={'project_list_tag'}>
                                    <span>{t('FinancingStatus')}</span>
                                    <img
                                        src={tagObj.financingStatus ? require('@/assets/image/project/jian.png') : require('@/assets/image/project/jia.png')}
                                        alt='icon'/>
                                </div>
                            ),
                            children: (
                                <div className={'included_radio_check_project'}>
                                    <Input prefix={<SearchOutlined />} onChange={e=>getFinancing(e.target.value)} className={"included_form_input project_form_input"} placeholder={t('Search')}/>
                                    {
                                        financingStatusList.map((item,index)=> {
                                            return (
                                                <div key={index} onClick={()=>{
                                                    setFinCheckList((e)=>{
                                                        const list = [...e]
                                                        const key = list.indexOf(item.dictValue)
                                                        if (key !== -1) {
                                                            list.splice(key,1)
                                                            return list
                                                        }
                                                        list.push(item.dictValue)
                                                        return list
                                                    })
                                                }} className={'project_list_radio'} style={index === 0?{marginTop: 20}:{}}>
                                                    {
                                                        finCheckList.indexOf(item.dictValue) !== -1 &&
                                                        (<img src={require('@/assets/image/Radio/radio_check_icon.png')} alt='check'/>)
                                                    }
                                                    {
                                                        finCheckList.indexOf(item.dictValue) === -1 &&
                                                        (<div className={'radio_no_check'}></div>)
                                                    }
                                                    <span>{item.dictLabel}</span>
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                            ),
                            showArrow: false
                        },
                        {
                            key: '4',
                            label: (
                                <div className={'project_list_tag'}>
                                    <span>{t('Region')}</span>
                                    <img
                                        src={tagObj.Region ? require('@/assets/image/project/jian.png') : require('@/assets/image/project/jia.png')}
                                        alt='icon'/>
                                </div>
                            ),
                            children: (
                                <div className={'included_radio_check_project'}>
                                    <Input prefix={<SearchOutlined />} onChange={e=>{
                                        if (!e.target.value) {
                                            setRegionStatusList([])
                                            setRegion([])
                                        }
                                        getRegion(e.target.value)
                                    }} className={"included_form_input project_form_input"} placeholder={t('Search')}/>
                                    {
                                        regionStatusList.map((item,index)=> {
                                            return (
                                                <div key={index} onClick={()=>{
                                                    setRegionCheckList((e)=>{
                                                        const list = [...e]
                                                        const key = list.indexOf(item.dictLabel)
                                                        if (key !== -1) {
                                                            list.splice(key,1)
                                                            return list
                                                        }
                                                        list.push(item.dictLabel)
                                                        return list
                                                    })
                                                }} className={'project_list_radio'} style={index === 0?{marginTop: 20}:{}}>
                                                    {
                                                        regionCheckList.indexOf(item.dictLabel) !== -1 &&
                                                        (<img src={require('@/assets/image/Radio/radio_check_icon.png')} alt='check'/>)
                                                    }
                                                    {
                                                        regionCheckList.indexOf(item.dictLabel) === -1 &&
                                                        (<div className={'radio_no_check'}></div>)
                                                    }
                                                    <span>{item.dictLabel}</span>
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                            ),
                            showArrow: false
                        },
                    ]} bordered={false} onChange={changeTag}/>
                </div>
                <div className={'project_list_right_table'}>
                    {
                        (tagCheckList.length > 0 || coinCheckList.length > 0 || finCheckList.length > 0 || regionCheckList.length > 0) && (
                            <div className={'project_tag_list'}>
                                <div onClick={()=>{
                                    setTagCheckList([])
                                    setCoinCheckList([])
                                    setFinCheckList([])
                                }} className={'project_tag_list_clear_btn'}>{t('clearAll')}</div>
                                {
                                    tagCheckList.map((item,index)=>{
                                        let labelName;
                                        for (const data of tagList) {
                                            if (data.dictValue === item) {
                                                labelName = data.dictLabel
                                                break;
                                            }
                                        }
                                        return (
                                            <div key={index} className={'project_tag_list_clear_btn_tag'}>
                                                {labelName}
                                                <img onClick={()=>{
                                                    setTagCheckList(e=>{
                                                        return e.filter((data,key)=>key !== index)
                                                    })
                                                }} src={color === 'white'?require('@/assets/image/project/tag_close.png'):require('@/assets/image/project/tag_close_dark.png')} alt='close'/>
                                            </div>
                                        )
                                    })
                                }
                                {
                                    coinCheckList.map((item,index)=>{
                                        let labelName;
                                        for (const data of coinStatusList) {
                                            if (data.dictValue === item) {
                                                labelName = data.dictLabel
                                                break;
                                            }
                                        }
                                        return (
                                            <div key={index} className={'project_tag_list_clear_btn_tag'}>
                                                {labelName}
                                                <img onClick={()=>{
                                                    setCoinCheckList(e=>{
                                                        return e.filter((data,key)=>key !== index)
                                                    })
                                                }} src={color === 'white'?require('@/assets/image/project/tag_close.png'):require('@/assets/image/project/tag_close_dark.png')} alt='close'/>
                                            </div>
                                        )
                                    })
                                }
                                {
                                    finCheckList.map((item,index)=>{
                                        let labelName;
                                        for (const data of financingStatusList) {
                                            if (data.dictValue === item) {
                                                labelName = data.dictLabel
                                                break;
                                            }
                                        }
                                        return (
                                            <div key={index} className={'project_tag_list_clear_btn_tag'}>
                                                {labelName}
                                                <img onClick={()=>{
                                                    setFinCheckList(e=>{
                                                        return e.filter((data,key)=>key !== index)
                                                    })
                                                }} src={color === 'white'?require('@/assets/image/project/tag_close.png'):require('@/assets/image/project/tag_close_dark.png')} alt='close'/>
                                            </div>
                                        )
                                    })
                                }
                                {
                                    regionCheckList.map((item,index)=>{
                                        return (
                                            <div key={index} className={'project_tag_list_clear_btn_tag'}>
                                                {item}
                                                <img onClick={()=>{
                                                    setRegionCheckList(e=>{
                                                        return e.filter((data,key)=>key !== index)
                                                    })
                                                }} src={color === 'white'?require('@/assets/image/project/tag_close.png'):require('@/assets/image/project/tag_close_dark.png')} alt='close'/>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        )
                    }
                    <div className={'project_list_right_table_block'} style={tagCheckList.length === 0?{paddingTop: 57}:{paddingTop: 7}}>
                        {
                            tableList.map((item,index)=>{
                                return (
                                    <div key={index} onClick={()=>{
                                        navigate(`/project/detail/${item.id}`)
                                    }}>
                                        <div className={'project_table_item'}>
                                            <div className={'project_table_item_top'}>
                                                <img src={baseUrl + item.logoUrl} alt='logo'/>
                                                <div className={'project_table_item_text'}>
                                                    <div className={'project_table_item_title'}>{item.name}</div>
                                                    <div className={'project_table_item_content'}>{item.content}</div>
                                                </div>
                                            </div>
                                            <div className={'project_table_item_bottom'}>
                                                {
                                                    item.label.split(',').slice(0,3).map(item=>{
                                                        return (<div onClick={(event)=>checkTag(event,item)}
                                                            className={'project_table_item_bottom_tool'}>{item}</div>)
                                                    })
                                                }
                                            </div>
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>
                    <div className={'project_table_page'}>
                        <Pagination total={total} size={12} page={pageNum} onChange={changePage}></Pagination>
                    </div>
                </div>
            </div>
            <PageFooter></PageFooter>
        </div>
    )
}

export default List;
