import { createSlice } from '@reduxjs/toolkit'
import store from "../../app/store";
export const walletSlice = createSlice({
    name: 'wallet',
    initialState: {
        value: false
    },
    reducers: {
        walletEqualsStatus: (state, action)=> {
            state.value = action.payload
        }
    }
})

export const { walletEqualsStatus } = walletSlice.actions

export default walletSlice.reducer
