import { createSlice } from '@reduxjs/toolkit'

export const mediaSlice = createSlice({
    name: 'media',
    initialState: {
        value: [
            {value: 'Discord',label: 'Discord'},
            {value: 'Twitter',label: 'Twitter'},
            {value: 'Telegram',label: 'Telegram'},
            {value: 'Medium',label: 'Medium'},
            {value: 'Snapshot',label: 'Snapshot'},
            {value: 'Github',label: 'Github'},
            {value: 'Blog',label: 'Blog'},
            {value: 'Forum',label: 'Forum'},
            {value: 'Linkedin',label: 'Linkedin'},
            {value: 'Instagram',label: 'Instagram'},
        ],
    }
})

export default mediaSlice.reducer
