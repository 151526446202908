import '../../Project/Detail/project_detail_module.scss'
import {useParams, Link, useSearchParams, useNavigate, useLocation} from 'react-router-dom'
import PageHeader from "@/components/PageHeader";
import PageFooter from "@/components/PageFooter";
import RouterLine from "@/components/RouterLine";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";
import {DatePicker, Input, Tooltip} from 'antd'
import TimeLine from "@/components/TimeLine";
import {useEffect, useState} from "react";
import Modal from '@/components/Modal'
import useSyncCallback from "../../../utils/useSyncCallback";
import moreApi from "../../../api/more";
import dayjs from "dayjs";
import Comment from "../../../components/Comment";
import DetailHandle from "../../../components/DetailHandle";
import {projectTypeObj} from "../../../enum/projectType";
import CreateInfo from "../../../components/CreateInfo";
import commonApi from "../../../api/common";
import {getMediaIcon} from "../../../enum/mediaType";
import {auditStatusObj} from "../../../enum/auditStatus";

function Detail() {
    const baseUrl = process.env.REACT_APP_BASE_URL
    const route = useLocation()
    const navigate = useNavigate()
    const {id} = useParams()
    const [searchParams, setSearchParams] = useSearchParams()
    const { t } = useTranslation()
    const color = useSelector(state => state.color.value)
    const userInfo = useSelector(state => state.user.value)
    const [isModalOpen,setIsModalOpen] = useState(false)
    const [updateModalOpen,setUpdateModalOpen] = useState(false)
    const [detailInfo,setDetailInfo] = useState({})
    const [createInfo, setCreateInfo] = useState({})
    const [otherList, setOtherList] = useState([])
    const [noticeModal, setNoticeModal] = useState(false)
    const [remarkModel, setRemarkModel] = useState(false)
    function setComment() {
        setIsModalOpen(false)
    }

    const getDetail = useSyncCallback(()=>{
        moreApi.queryToolsDetail({id}).then(res=>{
            setDetailInfo(res.data)
            getCreateUserInfo(res.data)
            getOtherTools()
        })
    })

    const getCreateUserInfo = useSyncCallback(()=>{
        commonApi.queryCreateUserInfo({createBy: detailInfo.createBy,projectId: detailInfo.id}).then(res=>{
            console.log('12334',res)
            setCreateInfo({...res.data})
        })
    })

    const getAuditDetail = useSyncCallback(()=>{
        moreApi.queryToolsDetailFromDB({id}).then(res=>{
            setDetailInfo(res.data)
            getCreateUserInfo(res.data)
            getOtherTools()
            if (searchParams.get("status") === "2") {
                console.log("userInfo.userId",userInfo.userId)
                console.log("userInfo.userId....res",res)
                if (userInfo.userId == res.data.updateBy || userInfo.userId == res.data.createBy){
                    setRemarkModel(true)
                }
            }
        })
    })

    const getOtherTools = useSyncCallback(()=>{
        moreApi.queryOtherTools({lang: detailInfo.lang, label: detailInfo.label, name: detailInfo.name}).then(res=>{
            setOtherList(res.rows)
        })
    })

    useEffect(() => {
        if (searchParams.get("type") || (searchParams.get("status") === "2" || searchParams.get("status") === "0")) {
            getAuditDetail()
        }else {
            getDetail()
        }
    }, [route.pathname]);
    return (
        <div className={'main_back main_back_white'}>
            <PageHeader other={true}></PageHeader>
            <div className={'project_detail_main_block'}>
                {
                    searchParams.get("type") === 'audit'?
                        (<div className={'router_line_block'}>
                            <img src={require('@/assets/image/project/router_arrow.png')} className='rotateY-180'
                                 alt='arrow'/>
                            <Link to={-1}>
                                <span className={'router_line_block_color'}>{t('back')}</span>
                            </Link>
                        </div>):(<RouterLine name={detailInfo.name}/>)
                }
                <div className={'project_detail_main'}>
                    <div className={'project_detail_main_left'}>
                        <div className={'project_detail_left_info'}>
                            <div className={'project_detail_left_info_content'}>
                                <img className={'project_detail_info_image'}
                                     src={baseUrl+detailInfo.logoUrl} alt='Empty'/>
                                <div className={'project_detail_info_text'}>
                                    <div className={'project_detail_info_name'}>{detailInfo.name}</div>
                                    <div className={'project_detail_info_context'}>{detailInfo.synopsis}</div>
                                    <div className={'project_detail_info_tag'}>
                                        {
                                            detailInfo.label && detailInfo.label.split(',').map(item => {
                                                return (
                                                    <div className={'project_detail_info_tag_item'}  onClick={()=>navigate(`/more/tool?tag=${item}`)}>
                                                        <img
                                                            src={color === 'white' ? require('@/assets/image/project/tool.png') : require('@/assets/image/project/tool_dark.png')}
                                                            alt='tool'/>
                                                        {item}
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                </div>
                            </div>

                            {(detailInfo.mainId && !searchParams.get("type")) && <DetailHandle mainId={detailInfo.mainId} name={detailInfo.name}
                                                                inclusionType={projectTypeObj.tool.type} lang={detailInfo.lang}
                                                                id={id}></DetailHandle>}
                        </div>
                        <div className={'project_detail_info_link_list'}>
                            <div className={'project_detail_info_link'} onClick={()=>{
                                if (detailInfo.walletStatus === '0' && detailInfo.wauditStatus === '0') {
                                    setNoticeModal(true)
                                }else {
                                    let url = detailInfo.officialWebsite
                                    if (url.split("://")[0] === "http" || url.split("://")[0] === "https"){
                                        window.open(detailInfo.officialWebsite)
                                    }else {
                                        window.open("http://"+url.split("://")[0])
                                    }
                                }
                            }}>
                                <img
                                    src={getMediaIcon("Website",color)}/>
                                Website
                            </div>
                            {
                                detailInfo.reportAddress && (
                                    <div className={'project_detail_info_link'} onClick={()=>{window.open(baseUrl+detailInfo.reportAddress)}}>
                                        <img src={require('@/assets/image/project/audit1.png')} alt='audit1'/>
                                        Audit Report
                                    </div>
                                )
                            }
                        </div>
                        <div className={'project_detail_text_info'}>
                            {/*<div><span>{t('founded')}: </span>{dayjs(detailInfo.createTime).format('YYYY')}</div>*/}
                            {detailInfo.ecology && <div><span>{t('ecosystem')}:</span> {detailInfo.ecology}</div>}
                        </div>
                        <div className={'project_detail_details'}>
                            <div className={'project_detail_details_title'}>{t('details')}</div>
                            <div className={'project_detail_details_content'}>{detailInfo.content}</div>
                        </div>

                        {!searchParams.get("type") && <Comment id={id}></Comment>}
                    </div>
                    <div className={'project_detail_main_right'}>
                        <CreateInfo createInfo={createInfo} createBy={detailInfo.createBy} createTime={detailInfo.createTime}></CreateInfo>
                        <div className={'project_detail_contributor'} style={{marginTop: 20}}>
                            <div className={'project_detail_top'} style={{marginBottom: 10}}>
                                <span>{t('Cooperations')}</span>
                            </div>
                            {
                                otherList.map(item=>{
                                    return (
                                        <div style={{cursor: 'pointer'}} onClick={()=>{
                                            navigate(`/more/tool/detail/${item.id}`)
                                            getDetail()
                                        }}>
                                            <div className={'project_contributor_project'}>
                                                <img src={baseUrl + item.logoUrl}
                                                     alt='logo'/>
                                                <div>
                                                    <div className={'project_contributor_project_title'}>{item.name}</div>
                                                    <div className={'project_contributor_project_text'}>{item.content}</div>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>
                </div>
            </div>
            <PageFooter></PageFooter>
            <Modal open={isModalOpen}>
                <div className={'project_comment_dialog'}>
                    <img onClick={() => setIsModalOpen(false)} className={'project_comment_dialog_close'}
                         src={require('../../../assets/image/home/dialog_close.png')} alt='close'/>
                    <div className={'project_comment_dialog_title'}>{t('comment')}</div>
                    <Input.TextArea placeholder={t('dialogPlaceholder')} className={'project_textarea'} autoSize={{ minRows: 6, maxRows: 6 }}></Input.TextArea>
                    <div className={'project_handle_btn'}>
                        <div onClick={()=>setIsModalOpen(false)}>{t('cancle')}</div>
                        <div onClick={setComment} className={'project_handle_btn_confirm'}>{t('confirm')}</div>
                    </div>
                </div>
            </Modal>
            <Modal open={updateModalOpen}>
                <div className={'project_comment_dialog'}>
                    <img onClick={() => setUpdateModalOpen(false)} className={'project_comment_dialog_close'}
                         src={require('../../../assets/image/home/dialog_close.png')} alt='close'/>
                    <div className={'project_comment_dialog_title'}>{t('update')}</div>
                    <div className={'project_detail_update_block'}>
                        <div className={'project_detail_update_small_title'}>{t('updateSmallTitle')}</div>
                        <div className={'project_detail_update_btn_list'}>
                            <Link to={`/more/tool/update/${id}`}>
                                <div>{t('updateContent')}</div>
                            </Link>
                            <div>{t('updateLanguage')}</div>
                            <div>{t('InitiateVoting')}</div>
                        </div>
                        <div className={'project_detail_update_text'}>{t('updateText')}</div>
                    </div>
                </div>
            </Modal>
            <Modal open={noticeModal}>
                <div className='project_comment_dialog'>
                    <img onClick={() => setNoticeModal(false)} className={'project_comment_dialog_close'}
                         src={require('@/assets/image/home/dialog_close.png')} alt='close'/>
                    <div className='project_comment_dialog_title'>{t('noticeLink')}</div>
                    <div className='project_comment_dialog_text' style={{color: '#FF2929'}}>{t('noticeText')}</div>
                    <div className='project_handle_btn'>
                        <div onClick={() => setNoticeModal(false)}>{t('cancle')}</div>
                        <div onClick={() => {
                            setNoticeModal(false)
                            window.open(detailInfo.officialWebsite)
                        }}
                             className='project_handle_btn_confirm'>{t('goOnTo')}</div>
                    </div>
                </div>
            </Modal>
            <Modal open={remarkModel}>
                <div className='project_comment_dialog'>
                    <img onClick={() => setRemarkModel(false)} className={'project_comment_dialog_close'}
                         src={require('@/assets/image/home/dialog_close.png')} alt='close'/>
                    <div className='project_comment_dialog_title'>{t('ReviewOpinions')}</div>
                    <div className='project_comment_dialog_text' style={{color: '#FF2929'}}>{detailInfo.examineReport}</div>
                    <div className='project_handle_btn'>
                        <div onClick={() => setRemarkModel(false)}>{t('close')}</div>
                        <Link to={`/more/tool/update/${id}?type=audit&update=1`}>
                            <div className='project_handle_btn_confirm'>{t('toUpdate')}</div>
                        </Link>
                    </div>
                </div>
            </Modal>
        </div>
    )
}

export default Detail;
