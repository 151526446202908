import { configureStore } from '@reduxjs/toolkit'
import langReducer from '../features/language/langSlice'
import colorReducer from '../features/color/colorSlice'
import mediaReducer from "../features/media/mediaSlice"
import authReducer from "../features/auth/authSlice"
import userReducer from '../features/user/userSlice'
import walletReducer from "../features/wallet/walletSlice"
import walletAddressReducer from "../features/walletAddress/walletAddressSlice"

export default configureStore({
    reducer: {
        language: langReducer,
        color: colorReducer,
        media: mediaReducer,
        auth: authReducer,
        user: userReducer,
        wallet: walletReducer,
        walletAddress: walletAddressReducer
    }
})
