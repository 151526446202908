import { createSlice } from '@reduxjs/toolkit'
import store from "../../app/store";
export const walletAddressSlice = createSlice({
    name: 'wallet',
    initialState: {
        value: null
    },
    reducers: {
        setWalletAddress: (state,action)=>{
            console.log(action.payload)
            state.value = action.payload
        }
    }
})

export const { setWalletAddress } = walletAddressSlice.actions

export default walletAddressSlice.reducer
