import '../../Project/Detail/project_detail_module.scss'
import {useParams, Link, useSearchParams, useNavigate} from 'react-router-dom'
import PageHeader from "@/components/PageHeader";
import PageFooter from "@/components/PageFooter";
import RouterLine from "@/components/RouterLine";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";
import {DatePicker, Input, Tooltip} from 'antd'
import TimeLine from "@/components/TimeLine";
import {useEffect, useState} from "react";
import Modal from '@/components/Modal'
import moreApi from '@/api/more'
import useSyncCallback from "../../../utils/useSyncCallback";
import Comment from '../../../components/Comment'
import DetailHandle from "../../../components/DetailHandle";
import {projectTypeObj} from "../../../enum/projectType";
import commonApi from "../../../api/common";
import CreateInfo from "../../../components/CreateInfo";
import dayjs from "dayjs";

function Detail() {
    const {id} = useParams()
    const navigate = useNavigate()
    const [searchParams, setSearchParams] = useSearchParams()
    const { t } = useTranslation()
    const baseUrl = process.env.REACT_APP_BASE_URL
    const color = useSelector(state => state.color.value)
    const userInfo = useSelector(state => state.user.value)
    const [detailInfo,setDetailInfo] = useState({
        winnersList: []
    })
    const [createInfo, setCreateInfo] = useState({})
    const [remarkModel, setRemarkModel] = useState(false)

    const getDetail = useSyncCallback(()=>{
        moreApi.queryHackDetail({id}).then(res=>{
            setDetailInfo(res.data)
            getCreateUserInfo()
        })
    })

    const getAuditDetail = useSyncCallback(()=>{
        moreApi.queryHackDetailFromDB({id}).then(res=>{
            setDetailInfo(res.data)
            getCreateUserInfo()
            if (searchParams.get("status") === "2") {
                if (userInfo.userId == res.data.updateBy || userInfo.userId == res.data.createBy){
                    setRemarkModel(true)
                }
            }
        })
    })

    const getCreateUserInfo = useSyncCallback(()=>{
        commonApi.queryCreateUserInfo({createBy: detailInfo.createBy,projectId: detailInfo.id}).then(res=>{
            console.log('12334',res)
            setCreateInfo({...res.data})
        })
    })

    useEffect(() => {
        if (searchParams.get("type") || (searchParams.get("status") === "2" || searchParams.get("status") === "0")) {
            getAuditDetail()
        }else {
            getDetail()
        }
    }, []);

    return (
        <div className={'main_back main_back_white'}>
            <PageHeader other={true}></PageHeader>
            <div className={'project_detail_main_block'}>
                {
                    searchParams.get("type") === 'audit'?
                        (<div className={'router_line_block'}>
                            <img src={require('@/assets/image/project/router_arrow.png')} className='rotateY-180'
                                 alt='arrow'/>
                            <Link to={-1}>
                                <span className={'router_line_block_color'}>{t('back')}</span>
                            </Link>
                        </div>):(<RouterLine name={detailInfo.name}/>)
                }
                <div className={'project_detail_main'}>
                    <div className={'project_detail_main_left'}>
                        <div className={'project_detail_left_info'}>
                            <div className={'project_detail_left_info_content'}>
                                <img className={'project_detail_info_image'}
                                     src={baseUrl+detailInfo.logoUrl} alt='project'/>
                                <div className={'project_detail_info_text'}>
                                    <div className={'project_detail_info_name'}>{detailInfo.name}</div>
                                    <div onClick={()=>{
                                        let url = detailInfo.link
                                        console.log()
                                        if (url.split("://")[0] === "http" || url.split("://")[0] === "https"){
                                            window.open(detailInfo.link)
                                        }else {
                                            window.open("http://"+url.split("://")[0])
                                        }
                                    }} className={'more_hackathon_info_context'}>Link
                                        <img src={color === 'white'?require('../../../assets/image/more/more_link.png'):require('../../../assets/image/more/more_link_dark.png')} alt='link'/>
                                    </div>
                                </div>
                            </div>

                            {(detailInfo.mainId && !searchParams.get("type")) && <DetailHandle mainId={detailInfo.mainId} name={detailInfo.name}
                                                                inclusionType={projectTypeObj.hackathon.type} lang={detailInfo.lang}
                                                                id={id}></DetailHandle>}
                        </div>
                        <div className={'project_detail_details'}>
                            <div className={'project_detail_details_title'}>{t('details')}</div>
                            <div className={'project_detail_details_content'}>{detailInfo.content}</div>
                            <div className={'more_hackathon_detail_time'}>Time: <span>{detailInfo.time?dayjs(detailInfo.time).format('YYYY-MM-DD'):'--'}</span></div>
                        </div>
                        {detailInfo.winnersList.length > 0 && <div className={'project_detail_details'}>
                            <div className={'project_detail_details_title'}>{t('AwardList')}</div>
                            {
                                detailInfo.winnersList && detailInfo.winnersList.map(item => {
                                    return (
                                        <div className={'more_award_list_item'}>
                                            <div className={'more_award_list_item_title'}>{item.name}</div>
                                            <div className={'more_award_list_item_text'}>{item.detail}</div>
                                        </div>
                                    )
                                })
                            }
                        </div>}

                        {!searchParams.get("type") && <Comment id={id}></Comment>}
                    </div>
                    <div className={'project_detail_main_right'}>
                        <CreateInfo createInfo={createInfo} createBy={detailInfo.createBy} createTime={detailInfo.createTime}></CreateInfo>
                        <div className={'project_detail_contributor'} style={{marginTop: 20}}>
                            <div className={'project_detail_top'} style={{marginBottom: 10}}>
                                <span>{t('Cooperations')}</span>
                            </div>
                            {/*<div className={'project_contributor_project'}>
                                <img src={require('../../../assets/image/project/project1.png')} alt='logo'/>
                                <div>
                                    <div className={'project_contributor_project_title'}>Newscrypto</div>
                                    <div className={'project_contributor_project_text'}>Multi in one encryption tool
                                    </div>
                                </div>
                            </div>*/}
                        </div>
                    </div>
                </div>
            </div>
            <PageFooter></PageFooter>
            <Modal open={remarkModel}>
                <div className='project_comment_dialog'>
                    <img onClick={() => setRemarkModel(false)} className={'project_comment_dialog_close'}
                         src={require('@/assets/image/home/dialog_close.png')} alt='close'/>
                    <div className='project_comment_dialog_title'>{t('ReviewOpinions')}</div>
                    <div className='project_comment_dialog_text' style={{color: '#FF2929'}}>{detailInfo.examineReport}</div>
                    <div className='project_handle_btn'>
                        <div onClick={() => setRemarkModel(false)}>{t('close')}</div>
                        <Link to={`/more/hackathon/update/${id}?type=audit&update=1`}>
                            <div className='project_handle_btn_confirm'>{t('toUpdate')}</div>
                        </Link>
                    </div>
                </div>
            </Modal>
        </div>
    )
}

export default Detail;
