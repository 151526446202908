import './timeLine_components.scss'
import {useSelector} from "react-redux";

function TimeLine(props) {
    const list = props.list || []
    const color = useSelector(state => state.color.value)
    return (
        <div className={'timeLine_block'}>
            <div className={'timeLine_line'}>
                <div className={'timeLine_line_item'}></div>
                <div className={'timeLine_line_item'}></div>
                <div className={'timeLine_line_item'}></div>
                <div className={'timeLine_line_item_long'}></div>
                {
                    list.map(()=> {
                        return (
                            <>
                                <div className={'timeLine_line_item'}></div>
                                <div className={'timeLine_line_item'}></div>
                                <div className={'timeLine_line_item'}></div>
                                <div className={'timeLine_line_item'}></div>
                                <div className={'timeLine_line_item'}></div>
                                <div className={'timeLine_line_item'}></div>
                                <div className={'timeLine_line_item'}></div>
                                <div className={'timeLine_line_item'}></div>
                                <div className={'timeLine_line_item'}></div>
                                <div className={'timeLine_line_item'}></div>
                                <div className={'timeLine_line_item_long'}></div>
                            </>
                        )
                    })
                }
                <div className={'timeLine_line_item'}></div>
                <div className={'timeLine_line_item'}></div>
                <div className={'timeLine_line_item'}></div>
            </div>
            <div className={'timeLine_main'}>
                {
                    list && list.map((item,index)=>{
                        return (
                            <div key={index} className={'timeLine_main_flex'}
                                 style={index % 2 === 0?{justifyContent: 'flex-end'}:{justifyContent: 'flex-start',textAlign: 'right'}}>
                                <div className={'timeLine_main_block'}>
                                    <div className={'timeLine_main_block_time'} style={index % 2 === 0?{paddingLeft: 22}:{paddingRight: 22}}>{item.time}</div>
                                    <div onClick={()=>{
                                        let url = item.link
                                        if (url.split("://")[0] === "http" || url.split("://")[0] === "https") {
                                            window.open(item.link)
                                        } else {
                                            window.open("http://" + url.split("://")[0])
                                        }
                                    }} className={'timeLine_main_block_text'} style={index % 2 === 0?{paddingLeft: 22}:{paddingRight: 22}}>
                                        <img src={color === 'white'?require('@/assets/image/timeLine/icon_link.png'):require('@/assets/image/timeLine/icon_link_dark.png')}/>
                                        {item.event}
                                    </div>
                                </div>
                            </div>
                        )
                    })
                }
            </div>
        </div>
    )
}

export default TimeLine;
