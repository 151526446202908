import dayjs from "dayjs";
import {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";
import useSyncCallback from "../../utils/useSyncCallback";
import projectsApi from "../../api/projects";
import {Input} from "antd";
import Modal from "../Modal";
import message from "../../utils/message";
import commonApi from "../../api/common";

function Comment({id}) {
    const baseUrl = process.env.REACT_APP_BASE_URL
    const {t} = useTranslation()
    const color = useSelector(state => state.color.value)
    const userInfo = useSelector(state => state.user.value)
    const [commentData,setCommentData] = useState({
        total: 0,
        list: []
    })
    const [pageNum,setPageNum] = useState(1)
    const [isModalOpen,setIsModalOpen] = useState()
    const [commentText,setCommentText] = useState()

    const getCommentList = useSyncCallback((type)=>{
        projectsApi.queryProjectsComment({
            inclusionId: id,
            pageNum,
            pageSize: 10
        }).then(async (res)=>{
            let list = []
            for (const item of res.rows) {
                const result = await Promise.all([
                    commonApi.queryCommentStatus({commentId: item.id,createBy: userInfo.userId,type: 0}),
                    commonApi.queryCommentStatus({commentId: item.id,createBy: userInfo.userId,type: 1})
                ])
                item['likeStatus'] = result[0].data
                item['unLikeStatus'] = result[1].data
                list.push(item)
            }
            console.log(list)
            if (type === "like") {
                setCommentData(()=>{
                    return {
                        total: res.total,
                        list
                    }
                })
            }else {
                const data = {
                    total: res.total,
                    list: [...commentData.list,...list]
                }
                setCommentData({...data})
            }
        })
    })

    const setComment = useSyncCallback(()=> {
        setIsModalOpen(false)
        projectsApi.submitComment({
            inclusionId: id,
            content: commentText,
            createTimeString: dayjs(Date.now()).format("YYYY-MM-DD HH:mm:ss")
        }).then(_=>{
            message.success(t('saveSuccess'))
            setCommentText("")
            setPageNum(1)
            getCommentList("like")
        })
    })

    const commentLike = useSyncCallback((val,commentId, unLikeType = void 0)=>{
        if(unLikeType === 0) {
            unCommentLike(unLikeType,commentId)
        }
        commonApi.likeComment({
            id: commentId,
            type: val === 1? 0 : 1
        }).then(_=>{
            setPageNum(1)
            getCommentList("like")
        })
    })

    const unCommentLike = useSyncCallback((val,commentId,likeType = void 0)=>{
        if(likeType === 0) {
            commentLike(likeType,commentId)
        }
        commonApi.unLikeComment({
            id: commentId,
            type: val === 1? 0 : 1
        }).then(_=>{
            setPageNum(1)
            getCommentList("like")
        })
    })

    useEffect(()=>{
        getCommentList()
    },[])

    return (
        <>
            <div className='kol_detail_details' style={{paddingBottom: 40}}>
                <div className='kol_detail_details_btn' onClick={() => {
                    setIsModalOpen(true)
                }}>
                    <img
                        src={color === 'white' ? require('@/assets/image/project/commit_icon.png') : require('@/assets/image/project/commit_icon_dark.png')}
                        alt='comment'/>
                    {t('comment')}
                </div>
                <div className='kol_detail_details_title'>{t('Reviews')} ({commentData?.total})
                </div>
                {
                    commentData.list.map(item => {
                        console.log("commentData",item)
                        return (
                            <div className='kol_review_item' style={{border: 'none'}}>
                                <div className='kol_review_item_top'>
                                    <div className='kol_review_item_top_left'>
                                        {!item.headSculpture && (
                                            <div className='kol_review_item_avatar'>{item?.nickName[0]}</div>)}
                                        {item.headSculpture && (<img src={baseUrl + item.headSculpture} alt='avatar'
                                                                     className='kol_review_item_avatar'/>)}
                                        <div>
                                            <div className='kol_review_username'>{item.nickName}</div>
                                            <div
                                                className='kol_review_date'>{dayjs(item.createTime).format('DD/MM/YYYY')}</div>

                                            <div className='kol_review_text'>{item.content}</div>
                                        </div>
                                    </div>
                                    <div className='kol_review_item_top_right'>
                                        <div className='kol_review_item_like' onClick={()=>commentLike(item.likeStatus,item.id,item.unLikeStatus)}>
                                            <img
                                                src={item.likeStatus === 1?require('@/assets/image/project/like.png'):require('@/assets/image/project/review_like.png')}
                                                alt='like'/>
                                            <span>{item.likeCount}</span>
                                        </div>
                                        <div className='kol_review_item_like' onClick={()=>unCommentLike(item.unLikeStatus,item.id,item.likeStatus)}>
                                            <img
                                                src={item.unLikeStatus === 1?require('@/assets/image/project/review_unlike_gray.png'):require('@/assets/image/project/review_unlike.png')}
                                                alt='like'/>
                                            <span>{item.nolikeCount}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )
                    })
                }
                {
                    (commentData.total > 4 && commentData.total > commentData.list.length) && (
                        <div onClick={() => {
                            setPageNum(e => e += 1)
                            getCommentList()
                        }} className='kol_review_load_btn'>{t('LoadMoreReviews')}</div>)
                }
            </div>
            <Modal open={isModalOpen}>
                <div className='kol_comment_dialog'>
                    <img onClick={()=>setIsModalOpen(false)} className='kol_comment_dialog_close' src={require('@/assets/image/home/dialog_close.png')} alt='close'/>
                    <div className='kol_comment_dialog_title'>{t('comment')}</div>
                    <Input.TextArea value={commentText} onChange={e=>setCommentText(e.target.value)} placeholder={t('dialogPlaceholder')} className='kol_textarea' autoSize={{ minRows: 6, maxRows: 6 }}></Input.TextArea>
                    <div className='kol_handle_btn'>
                        <div onClick={()=>setIsModalOpen(false)}>{t('cancle')}</div>
                        <div onClick={setComment} className='kol_handle_btn_confirm'>{t('confirm')}</div>
                    </div>
                </div>
            </Modal>
        </>
    )
}

export default Comment;
