import request from '../utils/request'

class Api {
    subscribeInfo(data) {
        return request.post('/subInfo/add',data)
    }

    queryTextConfig() {
        return request.get('/homePageSet/list')
    }
}

export default new Api();
