import '../user_module.scss'
import {Input} from "antd";
import {useTranslation} from "react-i18next";
import {useState} from "react";
import userApi from '@/api/user'
import { useDispatch } from "react-redux";
import {Logout} from "../../../features/auth/authSlice";
import { useNavigate } from "react-router-dom";
import message from "../../../utils/message";

function Password() {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const {t} = useTranslation()
    const [oldPassword, setOldPassword] = useState()
    const [newPassword, setNewPassword] = useState()

    function savePassword() {
        const data = {
            oldPassword,newPassword
        }
        userApi.updatePassword(data).then(()=>{
            message.success(t('saveSuccess'))
            dispatch(Logout())
            navigate('/login')
        })
    }
    return (
        <div className={'profile_main_block'}>
            <div className={'profile_main_block_title'}>Password Management</div>
            <div className={'profile_main_nickname'} style={{marginTop: 60}}>
                <div className={'profile_main_nickname_title'}>{t('OldPassword')}<span>*</span></div>
                <Input value={oldPassword} onChange={e=>setOldPassword(e.target.value)}
                    className={'profile_main_nickname_input included_form_input'}/>
            </div>
            <div className={'profile_main_nickname'} style={{marginTop: 60}}>
                <div className={'profile_main_nickname_title'}>{t('Password')}<span>*</span></div>
                <Input value={newPassword} onChange={e=>setNewPassword(e.target.value)}
                    className={'profile_main_nickname_input included_form_input'}/>
            </div>
            <div className={'password_main_save'} onClick={savePassword}>{t('save')}</div>
        </div>
    )
}

export default Password;
