import '../user_module.scss'
import Pagination from "../../../components/Pagination";
import {Link} from "react-router-dom";

function Project(props) {
    const baseUrl = process.env.REACT_APP_BASE_URL
    const list = props.list || []
    return (
        <div className={'collection_list_block'}>
            <div className={'collection_list_grid_3_small'}>
                {
                    list.map((item, index) => {
                        return (
                            <Link to={`/project/detail/${item.id}?status=${item.status}`}>
                                <div className={'project_table_item collection_project_table'}>
                                    { item.status === "0" && <img className={'submission_audit_image'} src={require('../../../assets/image/user/audit.png')} alt='audit'/>}
                                    { item.status === "1" && <img className={'submission_audit_image'} src={require('../../../assets/image/user/collecteo.png')} alt='audit'/>}
                                    { item.status === "2" && <img className={'submission_audit_image'} src={require('../../../assets/image/user/peview.png')} alt='audit'/>}
                                    <div className={'project_table_item_top'}>
                                        <img src={baseUrl+item.logoUrl} alt='logo'/>
                                        <div className={'project_table_item_text'}>
                                            <div className={'project_table_item_title'}>{item.name}</div>
                                            <div className={'project_table_item_content'}>{item.introduction}</div>
                                        </div>
                                    </div>
                                </div>
                            </Link>
                        )
                    })
                }
            </div>
        </div>
    )
}

export default Project;
