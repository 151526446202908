import '../../../Project/Detail/project_detail_module.scss'
import {useParams, Link, useSearchParams} from 'react-router-dom'
import PageHeader from "@/components/PageHeader";
import PageFooter from "@/components/PageFooter";
import RouterLine from "@/components/RouterLine";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";
import {DatePicker, Input, Tooltip} from 'antd'
import TimeLine from "@/components/TimeLine";
import {useEffect, useState} from "react";
import Modal from '@/components/Modal'
import moreApi from '@/api/more'
import useSyncCallback from "@/utils/useSyncCallback";
import Comment from '@/components/Comment'
import DetailHandle from "@/components/DetailHandle";
import {projectTypeObj} from "@/enum/projectType";
import commonApi from "@/api/common";
import CreateInfo from "@/components/CreateInfo";

function Detail({id,newDetail}) {
    const { t } = useTranslation()
    const baseUrl = process.env.REACT_APP_BASE_URL
    const color = useSelector(state => state.color.value)
    const [detailInfo,setDetailInfo] = useState({})
    const [createInfo, setCreateInfo] = useState({})

    const getDetail = useSyncCallback(()=>{
        moreApi.queryHackDetailFromDB({id}).then(res=>{
            setDetailInfo(res.data)
            getCreateUserInfo()
        })
    })

    const getAuditDetail = useSyncCallback(()=>{
        moreApi.queryHackDetailFromDB({id}).then(res=>{
            setDetailInfo(res.data)
            getCreateUserInfo()
        })
    })

    const getCreateUserInfo = useSyncCallback(()=>{
        commonApi.queryCreateUserInfo({createBy: detailInfo.createBy,projectId: detailInfo.id}).then(res=>{
            console.log('12334',res)
            setCreateInfo({...res.data})
        })
    })

    useEffect(() => {
        if (newDetail) {
            getAuditDetail()
        }else {
            getDetail()
        }
    }, []);

    return (
        <div className={'main_back main_back_white'}>
            <PageHeader other={true}></PageHeader>
            <div className={'project_detail_main_block'}>
                <RouterLine name={detailInfo.name}/>
                <div className={'project_detail_main'}>
                    <div className={'project_detail_main_left'}>
                        <div className={'project_detail_left_info'}>
                            <div className={'project_detail_left_info_content'}>
                                <img className={'project_detail_info_image'}
                                     src={baseUrl+detailInfo.logoUrl} alt='project'/>
                                <div className={'project_detail_info_text'}>
                                    <div className={'project_detail_info_name'}>{detailInfo.name}</div>
                                    <div onClick={()=>{
                                        window.open(detailInfo.link)
                                    }} className={'more_hackathon_info_context'}>Link
                                        <img src={color === 'white'?require('@/assets/image/more/more_link.png'):require('@/assets/image/more/more_link_dark.png')} alt='link'/>
                                    </div>
                                </div>
                            </div>

                            {detailInfo.mainId && <DetailHandle mainId={detailInfo.mainId} name={detailInfo.name}
                                                                inclusionType={projectTypeObj.hackathon.type} lang={detailInfo.lang}
                                                                id={id}></DetailHandle>}
                        </div>
                        <div className={'project_detail_details'}>
                            <div className={'project_detail_details_title'}>{t('details')}</div>
                            <div className={'project_detail_details_content'}>{detailInfo.content}</div>
                            <div className={'more_hackathon_detail_time'}>Time: <span>{detailInfo.time}</span></div>
                        </div>
                        <div className={'project_detail_details'}>
                            <div className={'project_detail_details_title'}>{t('AwardList')}</div>
                            {
                                detailInfo.winnersList && detailInfo.winnersList.map(item=>{
                                    return (
                                        <div className={'more_award_list_item'}>
                                            <div className={'more_award_list_item_title'}>{item.name}</div>
                                            <div className={'more_award_list_item_text'}>{item.detail}</div>
                                        </div>
                                    )
                                })
                            }
                        </div>
                        <Comment id={id}></Comment>
                    </div>
                    <div className={'project_detail_main_right'}>
                        <CreateInfo createInfo={createInfo} createBy={detailInfo.createBy} createTime={detailInfo.createTime}></CreateInfo>
                        <div className={'project_detail_contributor'} style={{marginTop: 20}}>
                            <div className={'project_detail_top'} style={{marginBottom: 10}}>
                                <span>{t('Cooperations')}</span>
                            </div>
                            <div className={'project_contributor_project'}>
                                <img src={require('@/assets/image/project/project1.png')} alt='logo'/>
                                <div>
                                    <div className={'project_contributor_project_title'}>Newscrypto</div>
                                    <div className={'project_contributor_project_text'}>Multi in one encryption tool
                                    </div>
                                </div>
                            </div>
                            <div className={'project_contributor_project'}>
                                <img src={require('@/assets/image/project/project1.png')} alt='logo'/>
                                <div>
                                    <div className={'project_contributor_project_title'}>Newscrypto</div>
                                    <div className={'project_contributor_project_text'}>Multi in one encryption tool
                                    </div>
                                </div>
                            </div>
                            <div className={'project_contributor_project'}>
                                <img src={require('@/assets/image/project/project1.png')} alt='logo'/>
                                <div>
                                    <div className={'project_contributor_project_title'}>Newscrypto</div>
                                    <div className={'project_contributor_project_text'}>Multi in one encryption tool
                                    </div>
                                </div>
                            </div>
                            <div className={'project_contributor_project'}>
                                <img src={require('@/assets/image/project/project1.png')} alt='logo'/>
                                <div>
                                    <div className={'project_contributor_project_title'}>Newscrypto</div>
                                    <div className={'project_contributor_project_text'}>Multi in one encryption tool
                                    </div>
                                </div>
                            </div>
                            <div className={'project_contributor_project'}>
                                <img src={require('@/assets/image/project/project1.png')} alt='logo'/>
                                <div>
                                    <div className={'project_contributor_project_title'}>Newscrypto</div>
                                    <div className={'project_contributor_project_text'}>Multi in one encryption tool
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <PageFooter></PageFooter>
        </div>
    )
}

export default Detail;
