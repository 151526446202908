import {Dropdown} from "antd";
import {useTranslation} from "react-i18next";
import {Link, useSearchParams} from "react-router-dom";
import Pagination from "../../../components/Pagination";
import '../more_module.scss'
import useSyncCallback from "../../../utils/useSyncCallback";
import {useEffect, useState} from "react";
import moreApi from "../../../api/more";
import {useSelector} from "react-redux";

function Tool() {
    const [searchParams, setSearchParams] = useSearchParams()
    const items = [
        {
            key: '1',
            label: (
                <span className={'Dropdown_tag_text'}>All</span>
            ),
        },{
            key: '2',
            label: (
                <span className={'Dropdown_tag_text'}>with token</span>
            ),
        },{
            key: '3',
            label: (
                <span className={'Dropdown_tag_text'}>No token</span>
            ),
        }
    ]
    const baseUrl = process.env.REACT_APP_BASE_URL
    const [tableData, setTableData] = useState({
        list: [],
        total: 0
    })
    const [requestType,setRequestType] = useState()
    const [requestSort,setRequestSort] = useState(0)
    const {t} = useTranslation()
    const [pageNum,setPageNum] = useState(1)
    const lang = useSelector(state => state.language.value)

    function changePage(e) {
        console.log(e)

    }

    const getList = useSyncCallback(()=>{
        moreApi.queryToolsList({
            pageNum,
            pageSize: 20,
            lang: lang.code === 'en_US'?0:1,
            sort: requestSort === void 0?0:requestSort,
            type: requestType === void 0?0:requestType,
            label: searchParams.get("tag")
        }).then(res=>{
            setTableData(()=>{
                return {
                    total: res.total,
                    list: res.rows
                }
            })
        })
    })

    const checkHottest = useSyncCallback((type)=>{
        setRequestSort(()=>void 0)
        setPageNum(1)
        setRequestType(type || 0)
        if(requestSort !== void 0) {
            if (requestSort ===  1){
                setRequestSort(0)
            }else {
                setRequestSort(1)
            }
        }else {
            setRequestSort(0)
        }
        getList()
    })

    const checkRecently = useSyncCallback((type)=>{
        setRequestSort(()=>void 0)
        setPageNum(1)
        setRequestType(type || 1)
        if(requestSort !== void 0) {
            if (requestSort ===  1){
                setRequestSort(0)
            }else {
                setRequestSort(1)
            }
        }else {
            setRequestSort(0)
        }
        getList()
    })

    useEffect(() => {
        console.log(123456)
        getList()
    }, []);
    return (
        <div className={'more_child_block'}>
            <div className={'more_child_sort'}>
                {/*<Dropdown menu={{items}} trigger={['click']}>
                    <div className={'more_tag_check'}>
                        All
                        <img src={require('../../../assets/image/investors/check_icon.png')}
                             alt='check'/>
                    </div>
                </Dropdown>*/}
                <div className={'more_child_sort_btn'}>
                    <div className={'project_list_left_menu_top_grid_item'} onClick={() => checkHottest(0)}>
                        {requestType !== 0 && (
                            <img src={require('@/assets/image/project/sort_icon.png')} alt='sort'/>)}
                        {(requestType === 0 && requestSort === 1) && (
                            <img src={require('@/assets/image/project/sort_icon_up.png')} alt='sort'/>)}
                        {(requestType === 0 && requestSort === 0) && (
                            <img src={require('@/assets/image/project/sort_icon_down.png')} alt='sort'/>)}
                        {t('Hottest')}
                    </div>
                    <div className={'project_list_left_menu_top_grid_item'}
                         onClick={() => checkRecently(1)}>
                        {requestType !== 1 && (
                            <img src={require('@/assets/image/project/sort_icon.png')} alt='sort'/>)}
                        {(requestType === 1 && requestSort === 1) && (
                            <img src={require('@/assets/image/project/sort_icon_up.png')} alt='sort'/>)}
                        {(requestType === 1 && requestSort === 0) && (
                            <img src={require('@/assets/image/project/sort_icon_down.png')} alt='sort'/>)}
                        {t('Recently')}
                    </div>
                </div>
            </div>
            <div className={'more_child_right_table_block'}>
                {
                    tableData.list.map((item, index) => {
                        return (
                            <Link to={`/more/tool/detail/${item.id}`}>
                                <div className={'project_table_item'}>
                                    <div className={'project_table_item_top'}>
                                        <img src={baseUrl + item.logoUrl} alt='logo'/>
                                        <div className={'project_table_item_text'}>
                                            <div className={'project_table_item_title'}>{item.name}</div>
                                            <div className={'project_table_item_content'}>{item.content}</div>
                                        </div>
                                    </div>
                                    <div className={'project_table_item_bottom'}>
                                        {
                                            item.label && item.label.split(',').map(item => <div
                                                className={'project_table_item_bottom_tool'}>{item}</div>)
                                        }
                                    </div>
                                </div>
                            </Link>
                        )
                    })
                }
            </div>
            <div className={'more_page_block'}>
                <Pagination total={tableData.total} size={20} page={pageNum} onChange={changePage}></Pagination>
            </div>
        </div>
    )
}

export default Tool;
