import './meeting_detail_module.scss'
import {useParams, Link, useSearchParams} from 'react-router-dom'
import PageHeader from "@/components/PageHeader";
import PageFooter from "@/components/PageFooter";
import RouterLine from "@/components/RouterLine";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";
import {DatePicker, Input, Tooltip} from 'antd'
import TimeLine from "@/components/TimeLine";
import {useEffect, useState} from "react";
import Modal from '@/components/Modal'
import useSyncCallback from "../../../utils/useSyncCallback";
import meetingApi from "../../../api/meeting";
import dayjs from 'dayjs'
import Comment from "../../../components/Comment";
import DetailHandle from "../../../components/DetailHandle";
import {projectTypeObj} from "../../../enum/projectType";
import {getMediaIcon} from "../../../enum/mediaType";
import {getReview} from "../../../utils/middle";


function Detail() {
    const {id} = useParams()
    const [searchParams, setSearchParams] = useSearchParams()
    const { t } = useTranslation()
    const color = useSelector(state => state.color.value)
    const baseUrl = process.env.REACT_APP_BASE_URL
    const keyEventList = [
        {time: 'Dec 03,2024',text: 'Arkham testing network cable'},
        {time: 'Dec 03,2024',text: 'Arkham testing network cable'},
        {time: 'Dec 03,2024',text: 'Arkham testing network cable'},
        {time: 'Dec 03,2024',text: 'Arkham testing network cable'}
    ]
    const [isModalOpen,setIsModalOpen] = useState(false)
    const [updateModalOpen,setUpdateModalOpen] = useState(false)
    const [detailInfo,setDetailInfo] = useState({})

    const [investmentList,setInvestmentList] = useState([
        {
            name: 'Transpose',
            image: require('@/assets/image/project/project1.png'),
            link: '',
            round: 'Series A',
            amount: "180M",
            valuation: "180M",
            date: 'Oct 30, 2023',
            leadinvestor: "Yes"
        },{
            name: 'Transpose',
            image: require('@/assets/image/project/project1.png'),
            link: '',
            round: 'Series A',
            amount: "180M",
            valuation: "180M",
            date: 'Oct 30, 2023',
            leadinvestor: "No"
        }
    ])

    function setComment() {
        setIsModalOpen(false)
    }

    const getDetail = useSyncCallback(()=>{
        getReview(searchParams.get("id"),(res)=>{
            setDetailInfo(res.data)
        })
    })

    useEffect(() => {
        getDetail()
    }, []);

    return (
        <div className={'main_back main_back_white'}>
            <PageHeader other={true}></PageHeader>
            <div className={'meeting_detail_main_block'} style={{paddingBottom: 50}}>
                <div className={'router_line_block'}>
                    <img src={require('@/assets/image/project/router_arrow.png')} className='rotateY-180' alt='arrow'/>
                    <Link to={-1}>
                        <span className={'router_line_block_color'}>{t('back')}</span>
                    </Link>
                </div>
                <div className={'meeting_detail_main'}>
                    <div className={'meeting_detail_main_left'}>
                        <div className={'meeting_detail_left_info'}>
                            <div className={'meeting_detail_left_info_content'}>
                                <img className={'meeting_detail_info_image'} style={{borderRadius: 20}}
                                     src={baseUrl + detailInfo.meetingUrl} alt='Empty'/>
                            </div>
                        </div>
                        <div className={'meeting_detail_left_text'}>{detailInfo.theme}</div>
                        <div className={'meeting_detail_info_link_list'}>
                            <div className={'meeting_detail_info_link_time'}>
                                <img src={require('@/assets/image/meeting/meeting_time_icon.png')} alt='time'/>
                                {dayjs(detailInfo.startTime).format('YYYY-MM-DD HH:mm')}~{dayjs(detailInfo.endTime).format('YYYY-MM-DD HH:mm')}
                            </div>
                            {detailInfo.website&&<div className={'meeting_detail_info_link'} onClick={() => {
                                let url = detailInfo.website
                                if (url.split("://")[0] === "http" || url.split("://")[0] === "https") {
                                    window.open(detailInfo.website)
                                } else {
                                    window.open("http://" + url.split("://")[0])
                                }
                            }}>
                                <img
                                    src={getMediaIcon("Website", color)}/>
                                website
                            </div>}
                        </div>

                        <div className={'meeting_detail_details_content'}>{detailInfo.content}</div>
                        <div className={'meeting_detail_details'}>
                            <div className={'meeting_detail_details_title'}>{t('Participants')}</div>
                            <div className={'meeting_form_team_list'}>
                                {
                                    JSON.parse(detailInfo.participants || '[]').map(item => {
                                        return (
                                            <div className={'meeting_form_team_list_item'}>
                                                <img src={baseUrl + item.headImgUrl}
                                                     style={{width: 60, height: 60, borderRadius: '50%'}}
                                                     alt='logo'/>
                                                <div style={{width: '100%'}}>
                                                    <div
                                                        className={'meeting_form_team_list_item_name'}>{item.name}</div>
                                                    <div
                                                        className={'meeting_form_team_list_item_content'}>{item.content}</div>
                                                </div>
                                                <img className={'meeting_form_team_list_item_media'}
                                                     src={require('../../../assets/image/included/media_logo.png')}
                                                     alt='logo'/>
                                            </div>
                                        )
                                    })
                                }
                            </div>

                        </div>
                    </div>
                    <div className={'meeting_detail_main_right'}>
                    </div>
                </div>
            </div>
            <PageFooter></PageFooter>
            <Modal open={isModalOpen}>
                <div className={'meeting_comment_dialog'}>
                    <img onClick={() => setIsModalOpen(false)} className={'meeting_comment_dialog_close'}
                         src={require('../../../assets/image/home/dialog_close.png')} alt='close'/>
                    <div className={'meeting_comment_dialog_title'}>{t('comment')}</div>
                    <Input.TextArea placeholder={t('dialogPlaceholder')} className={'meeting_textarea'}
                                    autoSize={{minRows: 6, maxRows: 6}}></Input.TextArea>
                    <div className={'meeting_handle_btn'}>
                    <div onClick={()=>setIsModalOpen(false)}>{t('cancle')}</div>
                        <div onClick={setComment} className={'meeting_handle_btn_confirm'}>{t('confirm')}</div>
                    </div>
                </div>
            </Modal>
            <Modal open={updateModalOpen}>
                <div className={'meeting_comment_dialog'}>
                    <img onClick={() => setUpdateModalOpen(false)} className={'meeting_comment_dialog_close'}
                         src={require('../../../assets/image/home/dialog_close.png')} alt='close'/>
                    <div className={'meeting_comment_dialog_title'}>{t('update')}</div>
                    <div className={'meeting_detail_update_block'}>
                        <div className={'meeting_detail_update_small_title'}>{t('updateSmallTitle')}</div>
                        <div className={'meeting_detail_update_btn_list'}>
                            <Link to={`/meeting/update/${id}`}>
                                <div>{t('updateContent')}</div>
                            </Link>
                            <div>{t('updateLanguage')}</div>
                            <div>{t('InitiateVoting')}</div>
                        </div>
                        <div className={'meeting_detail_update_text'}>{t('updateText')}</div>
                    </div>
                </div>
            </Modal>
        </div>
    )
}

export default Detail;
