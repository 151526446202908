import {useTranslation} from "react-i18next";
import {Input,Upload} from 'antd'
import '../user_module.scss'
import {useSelector} from "react-redux";
import {useEffect, useState} from "react";
import {setUserInfo} from "../../../features/user/userSlice";
import userApi from '@/api/user'
import { useDispatch } from "react-redux";
import message from "../../../utils/message";
import {Logout} from "../../../features/auth/authSlice";
import {useNavigate} from "react-router-dom";
import {web3} from "../../../utils/web3Utils";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc"
import tz from "dayjs/plugin/timezone"
import ImgCrop from "antd-img-crop";
dayjs.extend(utc);
dayjs.extend(tz);

function Profile() {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const userInfo = useSelector(state => state.user.value)
    const token = useSelector(state => state.auth.value)
    const baseUrl = process.env.REACT_APP_BASE_URL
    const {t} = useTranslation()
    const { TextArea } = Input;
    const [nickName,setNickName] = useState(userInfo.nickName)
    const [remark,setRemark] = useState(userInfo.remark)

    function avatarUpload(e) {
        getUserInfo()
    }

    function getUserInfo() {
        userApi.queryUserInfo().then(res=>{
            console.log(res)
            dispatch(setUserInfo(res.data))
            setNickName(res.data.nickName)
        })
    }

    function uploadUserInfo() {
        const data = {
            userId: userInfo.userId,
            avatar: userInfo.avatar,
            nickName,
            remark
        }
        userApi.updateUserInfo(data).then(res=> {
            message.success(t('saveSuccess'))
            getUserInfo()
        })
    }


    return (
        <div className='profile_main_block'>
            <div className='profile_main_block_title'>{t('Profile')}</div>
            <div className='profile_main_avatar'>
                {
                    !userInfo.avatar && <div className='profile_main_avatar_block'>
                        <img src={require('../../../assets/image/user/user_avatar.png')} alt='avatar'/>
                    </div>}

                {
                    userInfo.avatar && (
                        <img src={baseUrl + userInfo.avatar} className='profile_main_avatar_image' alt='avatar'/>
                    )
                }
                <ImgCrop quality={1} showReset rotationSlider modalOk="应用" modalCancel="取消">
                    <Upload maxCount={1} action="/api/system/user/avatar" accept='.jpg,.jpeg,.png' headers={{
                        Authorization: 'Bearer ' + token
                    }} name='avatarfile' onChange={avatarUpload}>
                        <div className='profile_main_upload_avatar'>{t('UploadAvatar')}</div>
                    </Upload>
                </ImgCrop>
            </div>
            <div className='profile_main_nickname'>
                <div className='profile_main_nickname_title'>{t('timezone')}</div>
                <div>{dayjs.tz.guess()}</div>
            </div>
            <div className='profile_main_nickname'>
                <div className='profile_main_nickname_title'>{t('nickname')}<span>*</span></div>
                <Input value={nickName} onChange={e => setNickName(e.target.value)}
                       className='profile_main_nickname_input included_form_input'/>
            </div>
            <div className='profile_main_nickname'>
                <div className='profile_main_nickname_title'>{t('ShortBio')}</div>
                <TextArea value={remark} onChange={e => setRemark(e.target.value)}
                          placeholder={t('ShortBioPlaceholder')} className='profile_main_bio_input included_form_input'
                          maxLength={400} showCount/>
            </div>
            <div className='profile_main_save'>
                <div onClick={uploadUserInfo}>{t('save')}</div>
            </div>
        </div>
    );
}

export default Profile;
